import React from 'react';
import styled from 'styled-components';
import { heading02 } from '@carbon/type';
import SVGNotFound from '../../assets/shared/NoFoundPage.js';

import './index.scss';

const Title = styled.h1(heading02);

const Empty = (props) => {
  const { 
    children, description,
    title = __('Not results found'), image, component = SVGNotFound, className = ''
  } = props;
  return (
    <div id='empty' className={className}>
      <div className="bpp-flx-clmn bpp-flx-c">
        <Title>{title}</Title>
        {
          !!image &&
          <img className="not-empty bpp-mt-32" src={image} alt="not-empty-page" />
        }
        {
          !!component &&
            <SVGNotFound />
        }
        {
          !!children &&
          children
        }
      </div>
      {
        !!description &&
        <p className="bpp-txt-center bpp-mt-16">{description}</p>
      }
    </div>
  );
};

export default Empty;
