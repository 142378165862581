import React, { useEffect, useState } from 'react';
import { Loading, Pagination } from 'carbon-components-react';
import TeachersTable from './TeachersTable';
import './teachersPage.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { thunks as adminThunks } from '../../../redux/actions/thunks/admin';
import Error from '../../../shared/error';
import { parseError } from '../../../utils';


const TeachersPage = (props) => {

  const {
    teachersadmin, getAdminTeachersL,
    user
  } = props;

  const {
    total: teacheradminTotal,
    payload: teachersadminPayload,
    error: teacheradminError,
    loading: teacheradminLoading
  } = teachersadmin || {};
  const {
    token: apiToken,
    error: userError,
    loading: userLoading
  } = user || {};


  const [limit, setLimit] = useState(4);
  const [page, setPage] = useState(1);
  const [teachers, setTeachers] = useState([]);
  const [respaldo, setRespaldo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(null);
  useEffect(() => {
    getAdminTeachersL(
      {
        limit,
        page
      },
      {
        apiToken
      });
    setLoading(true);
    setLoading(false);
  }, [limit, page]);

  useEffect(() => {
    
    setRespaldo(teachersadminPayload);
    setTeachers(teachersadminPayload);
    setTotal(teacheradminTotal);
  }, [teachersadminPayload]);


  return (
    <>
      <div className='bpp-mt-16'>
        {
          !userLoading &&
          userError &&
          <Error errorMessage={__(parseError(userError))} />
        }
        {
          !teacheradminLoading &&
          teacheradminError &&
          <Error errorMessage={__(parseError(teacheradminError))} />
        }
        {
          loading &&
          <Loading active={loading} withOverlay={true} />
        }
        {
          !userLoading &&
          !userError &&
          !teacheradminLoading &&
          !teacheradminError &&
          !loading &&
          /*teachers?.length &&*/ (
            <>
              <TeachersTable elrespaldo={respaldo} seteoT={setTeachers} teachers={teachers.map(s => ({ ...s, FullName: `${s.FirstName} ${s.LastName}` }))} />
              <Pagination
                onChange={e => {setPage(e.page);setLimit(e.pageSize);}}
                backwardText={__('Previous page')}
                forwardText={__('Next page')}
                itemsPerPageText={__('Items per page:')}
                page={page}
                pageNumberText={__('Page Number')}
                pageSize={limit}
                pageSizes={[4,5,6]}
                totalItems={total}
              />
            </>
          )
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teachersadmin: state.admin.teachers,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAdminTeachersL: adminThunks.getAdminTeachersL
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TeachersPage);