
import { types } from '../actions/user';
const keyPersist = process.env.REACT_APP_KEY_PERSIST;

const {
  REGISTER_START,
  REGISTER_ERROR,
  REGISTER_COMPLETE,
  LOGIN_START,
  LOGIN_ERROR,
  LOGIN_COMPLETE,
  GET_START,
  GET_ERROR,
  GET_COMPLETE,
  USER_LOGOUT,
  UPDATE_BREADCRUMBS
} = types;

const initialState = {
  token: null,
  breadCrumbs: {
    courses: {
      href: '/courses/',
      name: 'courses',
      id: ''
    },
    course: {
      href: undefined,
      name: 'course',
      id: ''
    },
    student: {
      href: undefined,
      name: 'student',
      id: ''
    }
  }
};

const updateBreadCrumbs = (state, action) => {
  const { breadCrumb, href, id } = action.payload;
  let crumbs;
  if (breadCrumb == 'course') {
    crumbs = {
      ...state.breadCrumbs,
      course: {
        href,
        name: breadCrumb,
        id
      },
      student: {
        href: undefined,
        name: 'student',
        id: ''
      }
    };
  } else if (breadCrumb == 'student') {
    crumbs = {
      ...state.breadCrumbs,
      student: {
        href,
        name: breadCrumb,
        id
      }
    };
  } else if (breadCrumb == 'courses') {
    crumbs = {
      courses: {
        href: '/courses/',
        name: 'courses',
        id: ''
      },
      course: {
        href: undefined,
        name: 'course',
        id: ''
      },
      student: {
        href: undefined,
        name: 'student',
        id: ''
      }
    }; 
  }
  return {
    ...state,
    breadCrumbs: crumbs
  };
};

const getStart = (state) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const getError = (state, action) => {
  return {
    ...state,
    error: action.payload,
    loading: false
  };
};

const getComplete = (state, action) => {
  const { dataUser } = action.payload;
  return {
    ...state,
    ...dataUser,
    loading: false,
    error: null
  };
};

const startRegister = (state) => {
  return {
    ...state
  };
};

const errorRegister = (state) => {
  return {
    ...state
  };
};

const completeRegister = (state, action) => {
  const {
    dataUser: {
      user, token 
    }
  } = action.payload;
  return {
    ...state,
    ...user,
    token
  };
};

const startLogin = (state) => {
  return {
    ...state
  };
};

const errorLogin = (state) => {
  return {
    ...state
  };
};

const completeLogin = (state, action) => {
  const {
    dataUser: {
      user, token
    }
  } = action.payload;
  return {
    ...state,
    ...user,
    token
  };
};

const clearUserState = () => {
  localStorage.removeItem(`persist:${keyPersist}`);
  return initialState;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
  case REGISTER_START:
    return startRegister(state, action);
  case REGISTER_ERROR:
    return errorRegister(state, action);
  case REGISTER_COMPLETE:
    return completeRegister(state, action);
  case LOGIN_START:
    return startLogin(state, action);
  case LOGIN_ERROR:
    return errorLogin(state, action);
  case LOGIN_COMPLETE:
    return completeLogin(state, action);
  case GET_START:
    return getStart(state);
  case GET_ERROR:
    return getError(state, action);
  case GET_COMPLETE:
    return getComplete(state, action);
  case USER_LOGOUT:
    return clearUserState();
  case UPDATE_BREADCRUMBS:
    return updateBreadCrumbs(state, action);
  default:
    return state;
  }
}
