export default {
  'Course *COURSENAME*': 'Course *COURSENAME*',
  'Course name': 'Course name',
  'Description': 'Description',
  'Create course': 'Create course',
  'Overview': 'Overview',
  'Missions': 'Missions',
  'Game time (m)': 'Game time (m)',
  'Total students': 'Total students',
  'Popular level': 'Popular level',
  'Popular mission': 'Popular mission',
  'Details': 'Details',
  'Achievements': 'Achievements',
  'Missions (time)': 'Missions (time)',
  'Missions (group)': 'Missions (group)',
  'Completed': 'Completed',
  '*STUDENTS* completed': '*STUDENTS* completed',
  '*STUDENTS* no completed': '*STUDENTS* no completed',
  'No achievements were found!': 'No achievements were found!',
  'Students': 'Students',
  'Mission': 'Mission',
  'Missions completed by student gender': 'Missions completed by student gender',
  'No mission were found!': 'No mission were found!',
  'Time (minutes)': 'Time (minutes)',
  'Missions vs finish average time': 'Missions vs finish average time',
  'Open details': 'Open details',
  'Sign up free': 'Sign up free',
  'Log in': 'Log in',
  'Password': 'Password',
  'Email': 'Email',
  'Firstname': 'Firstname',
  'Lastname': 'Lastname',
  'Phone': 'Phone',
  'Confirm Password': 'Confirm Password',
  '8 character minimum': '8 character minimum',
  'One lowercase character': 'One lowercase character',
  'One upper character': 'One upper character',
  'One number minimum': 'One number minimum',
  'Institution': 'Institution',
  'Choose an institution': 'Choose an institution',
  'Create account': 'Create account',
  'Resume': 'Resume',
  'Course': 'Course',
  'Current mission': 'Current mission',
  'Start game date': 'Start game date',
  'Last game date': 'Last game date',
  'Student details': 'Student details',
  'Average': 'Average',
  'Student': 'Student',
  'Time': 'Time',
  'Missions time vs average student': 'Missions time vs average student',
  'Create a new course': 'Create a new course',
  'Previous page': 'Previous page',
  'Next page': 'Next page',
  'Items per page': 'Items per page',
  'Courses': 'Courses',
  'Not courses created yet': 'Not courses created yet',
  'This is very lonely around here...you could create a course with the button above': 'This is very lonely around here...you could create a course with the button above',
  'Sign In': 'Sign In',
  'Go to course': 'Go to course',
  'Delete course': 'Delete course',
  'copy to clipboard': 'copy to clipboard',
  'Not results found': 'Not results found',
  'No page avaliable': 'No page avaliable',
  'Sign out': 'Sign out',
  'Account': 'Account',
  'Dashboard': 'Dashboard',
  'Settings': 'Settings',
  'user or password invalid': 'user or password invalid',
  'User not found': 'User not found',
  'Class details': 'Class details',
  'successful process': 'successful process',
  'Course description': 'Course description',
  '*USERNAME*, your account it\'s ready to create courses': '*USERNAME*, your account it\'s ready to create courses',
  'Actions': 'Actions',
  'Current level': 'Current level',
  'Play time (minutes)': 'Play time (minutes)',
  'Grades': 'Grades',
  'Gender': 'Gender',
  'Age': 'Age',
  'Name': 'Name',
  'ID': 'ID',
  'Course options': 'Course options',
  'Student list': 'Student list',
  'Class code': 'Class code',
  'No students were found!': 'No students were found!',
  'Create an account': 'Create an account',
  'Don\'t have an account?': 'Don\'t have an account?',
  'Already have an account?': 'Already have an account?',
  'Student resume': 'Student resume',
  'Register': 'Register',
  '*MIN*–*MAX* items': '*MIN*–*MAX* items',
  'Maximum': 'Maximum',
  'Upper quartile': 'Upper quartile',
  'Median': 'Median',
  'Lower quartile': 'Lower quartile',
  'Minimum': 'Minimum',
  'Password invalid': 'Password invalid',
  'User not found!': 'User not found',
  'Acceso Denegado': 'Acceso Denegado',
  'Invalid token': 'Invalid token',
  'Email is already exists!': 'Email is already exists!',
  'Bad request': 'Bad request',
  'Unauthorized': 'Unauthorized',
  'Teacher not found!': 'Teacher not found!',
  'Not found!': 'Not found!',
  'Course or Teacher Not found': 'Course or Teacher Not found',
  'Not found': 'Not found',
  'Course does not exist': 'Course does not exist',
  'Course code must be 4 digits long.': 'Course code must be 4 digits long.',
  'Age value is required.': 'Age value is required.',
  'Age value out of range (must be between 0 and 100).': 'Age value out of range (must be between 0 and 100).',
  'Prize not found': 'Prize not found',
  'Mission not found': 'Mission not found',
  'Something went wrong, please try again': 'Something went wrong, please try again',
  'courses': 'courses',
  'course': 'course',
  'student': 'student',
  'Teachers': 'Teachers',
  'Forgotten password?': 'Forgotten password?',
  'Reset it': 'Reset it',
  'List of courses': 'List of courses',
  'Add new course': 'Add new course',
  'List of teachers': 'List of teachers',
  'Add new teacher': 'Add new teacher',
  'List of missions': 'List of missions',
  'Add new mission': 'Add new mission',
  'Game levels': 'Game levels',
  'List of game levels': 'List of game levels',
  'Add new game level': 'Add new game level',
  'Change password': 'Change password',
  'Edit mission': 'Edit mission',
  'Edit game level': 'Edit game level',
  'Code': 'Code', 
  'Date': 'Date',
  'Score': 'Score',
  'Full_Name': 'Full_Name',
  'Email_Teacher': 'Email_Teacher',
  'Teacher_Name': 'Teacher_Name',
  'Delete': 'Delete',
  'Cancel': 'Cancel',
  'Download': 'Download',
  'Page Number': 'Page Number',
  'Create new course': 'Create new course',
  'Email of teacher': 'Email of teacher',
  'Create new teacher': 'Create new teacher',
  'Do you want to enter a password?': 'Do you want to enter a password?',
  'Yes': 'Yes',
  'No': 'No',
  'Change of password': 'Change of password',
  'New password': 'New password',
  'Confirm new password': 'Confirm new password',
  'Create mission': 'Create mission',
  'Register mission': 'Register mission',
  'Update mission': 'Update mission',
  'Register game level': 'Register game level',
  'Create game level': 'Create game level',
  'Update game level': 'Update game level',
  'The email of teacher is required': 'The email of teacher is required',
  'Mission name is required': 'Mission name is required',
  'Score is required': 'Score is required',
  'GameId is required': 'GameId is required',
  'Game level name is required': 'Game level name is required'

};