import React ,{ useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Grid,
  Row,
  Column,
  Tile,
  ContentSwitcher,
  Switch,
  Loading
} from 'carbon-components-react';
import { heading02 } from '@carbon/type';
import styled from 'styled-components';
import Achievements from './achievements';
import MissionsByTime from './missions/byTime';
import MissionsByGroup from './missions/byGroups';
import {
  Education,
  PlayerFlow,
  Group,
  StairsUp
} from '@carbon/pictograms-react';
import { thunks as teacherThunks } from '../../../redux/actions/thunks/teacher';
import Error from '../../../shared/error';
import { parseError } from './../../../utils';
import './index.css';

const Title = styled.h1(heading02);

const Dashboard = (props) => {
  const {
    overview, getOverview, courseId,
    user
  } = props;
  const {
    payload: overviewPayload,
    loading: overviewLoading,
    error: overviewError
  } = overview || {};
  const {
    id: teacherId,
    token: apiToken
  } = user || {};

  const [dashContext, setDashContext] = useState(0);

  useEffect(() => {
    getOverview(
      {
        teacherId,
        courseId
      },
      {
        apiToken
      }
    );
  }, [getOverview]);

  return (
    <Grid>
      <Row>
        <Column sm={8} md={8} lg={12} xlg={12}>
          <Tile light={true} className="bpp-mt-16 bpp-class-overview">
            <Title>{__('Overview')}</Title>
            {
              !overviewLoading &&
              overviewError &&
              <Error errorMessage={__(parseError(overviewError))}/>
            }
            {
              overviewLoading &&
              !overviewError &&
              <div className="bpp-flx-c">
                <Loading
                  className="bpp-flx-c"
                  description="Resume is loading"
                  withOverlay={false}
                />
              </div>
            }
            {
              !overviewLoading &&
              overviewPayload &&
              !overviewError &&
              <div className='bpp-overview-container bpp-flx-vc bpp-flx-c bpp-mt-16'>
                <div className='bpp-txt-center bpp-overview-item'>
                  <Education aria-label="hills" className="bpp-overview-picto bpp-overview-canva" />
                  <p>{__('Total students')}</p>
                  <p className="bpp-overview-number">
                    {overviewPayload.totalStudents}
                  </p>
                </div>
                <div className='bpp-txt-center bpp-overview-item bpp-flx-c bpp-flx-clmn'>
                  <Group aria-label="group" className="bpp-overview-picto bpp-overview-canva" />
                  <div className="bpp-flx">
                    <div className="">
                      <p>{__('M')}</p>
                      <p className="bpp-overview-number">
                        {overviewPayload.boysStudents}
                      </p>
                    </div>
                    <div className="bpp-ml-16">
                      <p>{__('F')}</p>
                      <p className="bpp-overview-number">
                        {overviewPayload.girlsStudents}
                      </p>
                    </div>
                    <div className="bpp-ml-16">
                      <p>{__('NA')}</p>
                      <p className="bpp-overview-number">
                        {overviewPayload.NAStudents}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='bpp-txt-center bpp-overview-item'>
                  <StairsUp aria-label="hills" className="bpp-overview-picto bpp-overview-canva" />
                  <p>{__('Popular level')}</p>
                  <p className="bpp-overview-number">
                    {overviewPayload.popularLevel}
                  </p>
                </div>
                <div className='bpp-txt-center bpp-overview-item'>
                  <PlayerFlow aria-label="missions" className="bpp-overview-picto bpp-overview-canva" />
                  <p className='bpp-mt-16'>
                    {__('Popular mission')}
                  </p>
                  <p className="bpp-overview-number">
                    {overviewPayload.popularMission}
                  </p>
                </div>
              </div>

            }
          </Tile>
        </Column>
      </Row>
      <Row className="bpp-mt-16">
        <Column>
          <Tile light={true} className="bpp-mt-16 ">
            <div className='bpp-flx-btwn'>
              <Title>{__('Details')}</Title>
              <ContentSwitcher
                className="bpp-class-detail-context"
                selectedIndex={dashContext}
                onChange={(i) => { setDashContext(i.index); }}>
                <Switch name="achievements" text={__('Achievements')}/>
                <Switch name="missionsByTime" text={__('Missions (time)')} />
                <Switch name="missionsByGroup" text={__('Missions (group)')} />
              </ContentSwitcher>
            </div>
            {
              dashContext === 0 &&
              <Achievements courseId={courseId}/>
            }
            {
              dashContext === 1 &&
              <MissionsByTime courseId={courseId}/>
            }
            {
              dashContext === 2 &&
              <MissionsByGroup courseId={courseId}/>
            }
          </Tile>
        </Column>
      </Row>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    overview: state.teacher.overview,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOverview: teacherThunks.getOverview
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);