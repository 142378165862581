import { createAction } from 'redux-actions';
/* eslint-disable max-len */


export const types = {
  GET_ADMINCOURSES_START: 'ADMIN/GET_ADMINCOURSES_START',
  GET_ADMINCOURSES_ERROR: 'ADMIN/GET_ADMINCOURSES_ERROR',
  GET_ADMINCOURSES_COMPLETE: 'ADMIN/GET_ADMINCOURSES_COMPLETE',

  GET_ADMINCOURSESL_START: 'ADMIN/GET_ADMINCOURSEL_START',
  GET_ADMINCOURSESL_ERROR: 'ADMIN/GET_ADMINCOURSESL_ERROR',
  GET_ADMINCOURSESL_COMPLETE: 'ADMIN/GET_ADMINCOURSESL_COMPLETE',

  GET_ADMINTEACHERSL_START: 'ADMIN/GET_ADMINTEACHERSL_START',
  GET_ADMINTEACHERSL_ERROR: 'ADMIN/GET_ADMINTEACHERSL_ERROR',
  GET_ADMINTEACHERSL_COMPLETE: 'ADMIN/GET_ADMINTEACHERSL_COMPLETE',

  GET_ADMINMISSIONSL_START: 'ADMIN/GET_ADMINMISSIONSL_START',
  GET_ADMINMISSIONSL_ERROR: 'ADMIN/GET_ADMINMISSIONSL_ERROR',
  GET_ADMINMISSIONSL_COMPLETE: 'ADMIN/GET_ADMINMISSIONSL_COMPLETE',

  GET_ADMINGAMELEVELSL_START: 'ADMIN/GET_ADMINGAMELEVELSL_START',
  GET_ADMINGAMELEVELSL_ERROR: 'ADMIN/GET_ADMINGAMELEVELSL_ERROR',
  GET_ADMINGAMELEVELSL_COMPLETE: 'ADMIN/GET_ADMINGAMELEVELSL_COMPLETE',

  CREATE_ADMINCOURSES_START: 'ADMIN/CREATE_ADMINCOURSES_START',
  CREATE_ADMINCOURSES_ERROR: 'ADMIN/CREATE_ADMINCOURSES_ERROR',
  CREATE_ADMINCOURSES_COMPLETE: 'ADMIN/CREATE_ADMINCOURSES_COMPLETE',

  CREATE_ADMINTEACHERS_START: 'ADMIN/CREATE_ADMINTEACHERS_START',
  CREATE_ADMINTEACHERS_ERROR: 'ADMIN/CREATE_ADMINTEACHERS_ERROR',
  CREATE_ADMINTEACHERS_COMPLETE: 'ADMIN/CREATE_ADMINTEACHERS_COMPLETE',

  CREATE_ADMINMISSIONS_START: 'ADMIN/CREATE_ADMINMISSIONS_START',
  CREATE_ADMINMISSIONS_ERROR: 'ADMIN/CREATE_ADMINMISSIONS_ERROR',
  CREATE_ADMINMISSIONS_COMPLETE: 'ADMIN/CREATE_ADMINMISSIONS_COMPLETE',

  CREATE_ADMINGAMELEVELS_START: 'ADMIN/CREATE_ADMINGAMELEVELS_START',
  CREATE_ADMINGAMELEVELS_ERROR: 'ADMIN/CREATE_ADMINGAMELEVELS_ERROR',
  CREATE_ADMINGAMELEVELS_COMPLETE: 'ADMIN/CREATE_ADMINGAMELEVELS_COMPLETE',

  UPDATE_PASSWORDT_START: 'ADMIN/UPDATE_PASSWORDT_START',
  UPDATE_PASSWORDT_ERROR: 'ADMIN/UPDATE_PASSWORDT_ERROR',
  UPDATE_PASSWORDT_COMPLETE: 'ADMIN/UPDATE_PASSWORDT_COMPLETE',

  UPDATE_MISSION_START: 'ADMIN/UPDATE_MISSION_START',
  UPDATE_MISSION_ERROR: 'ADMIN/UPDATE_MISSION_ERROR',
  UPDATE_MISSION_COMPLETE: 'ADMIN/UPDATE_MISSION_COMPLETE',

  UPDATE_GAMELEVEL_START: 'ADMIN/UPDATE_GAMELEVEL_START',
  UPDATE_GAMELEVEL_ERROR: 'ADMIN/UPDATE_GAMELEVEL_ERROR',
  UPDATE_GAMELEVEL_COMPLETE: 'ADMIN/UPDATE_GAMELEVEL_COMPLETE',

  DELETE_TEACHERS_START: 'ADMIN/DELETE_TEACHERS_START',
  DELETE_TEACHERS_ERROR: 'ADMIN/DELETE_TEACHERS_ERROR',
  DELETE_TEACHERS_COMPLETE: 'ADMIN/DELETE_TEACHERS_COMPLETE',

  DELETE_COURSES_START: 'ADMIN/DELETE_COURSES_START',
  DELETE_COURSES_ERROR: 'ADMIN/DELETE_COURSES_ERROR',
  DELETE_COURSES_COMPLETE: 'ADMIN/DELETE_COURSES_COMPLETE',

  DELETE_MISSIONS_START: 'ADMIN/DELETE_MISSIONS_START',
  DELETE_MISSIONS_ERROR: 'ADMIN/DELETE_MISSIONS_ERROR',
  DELETE_MISSIONS_COMPLETE: 'ADMIN/DELETE_MISSIONS_COMPLETE'

};

export const actions = {
  getAdminCourses: {
    start: createAction(types.GET_ADMINCOURSES_START),
    error: createAction(types.GET_ADMINCOURSES_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_ADMINCOURSES_COMPLETE,
      payload => ({ payload })
    )
  },
  getAdminCoursesL: {
    start: createAction(types.GET_ADMINCOURSESL_START),
    error: createAction(types.GET_ADMINCOURSESL_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_ADMINCOURSESL_COMPLETE,
      payload => ({ payload })
    )
  },
  getAdminTeachersL: {
    start: createAction(types.GET_ADMINTEACHERSL_START),
    error: createAction(types.GET_ADMINTEACHERSL_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_ADMINTEACHERSL_COMPLETE,
      payload => ({ payload })
    )
  },
  getAdminMissionsL: {
    start: createAction(types.GET_ADMINMISSIONSL_START),
    error: createAction(types.GET_ADMINMISSIONSL_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_ADMINMISSIONSL_COMPLETE,
      payload => ({ payload })
    )
  },
  getAdminGameLevelsL: {
    start: createAction(types.GET_ADMINGAMELEVELSL_START),
    error: createAction(types.GET_ADMINGAMELEVELSL_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_ADMINGAMELEVELSL_COMPLETE,
      payload => ({ payload })
    )
  },
  createAdminCourses: {
    start: createAction(types.CREATE_ADMINCOURSES_START),
    error: createAction(types.CREATE_ADMINCOURSES_ERROR, error => ({ error })),
    complete: createAction(
      types.CREATE_ADMINCOURSES_COMPLETE,
      payload => ({ payload })
    )
  },
  createAdminTeachers: {
    start: createAction(types.CREATE_ADMINTEACHERS_START),
    error: createAction(types.CREATE_ADMINTEACHERS_ERROR, error => ({ error })),
    complete: createAction(
      types.CREATE_ADMINTEACHERS_COMPLETE,
      payload => ({ payload })
    )
  },
  createAdminMissions: {
    start: createAction(types.CREATE_ADMINMISSIONS_START),
    error: createAction(types.CREATE_ADMINMISSIONS_ERROR, error => ({ error })),
    complete: createAction(
      types.CREATE_ADMINMISSIONS_COMPLETE,
      payload => ({ payload })
    )
  },
  createAdminGameLevels: {
    start: createAction(types.CREATE_ADMINGAMELEVELS_START),
    error: createAction(types.CREATE_ADMINGAMELEVELS_ERROR, error => ({ error })),
    complete: createAction(
      types.CREATE_ADMINGAMELEVELS_COMPLETE,
      payload => ({ payload })
    )
  },
  updatePasswordT: {
    start: createAction(types.UPDATE_PASSWORDT_START),
    error: createAction(types.UPDATE_PASSWORDT_ERROR, error => ({ error })),
    complete: createAction(
      types.UPDATE_PASSWORDT_COMPLETE,
      message => ({ message })
    )
  },
  updateMission: {
    start: createAction(types.UPDATE_MISSION_START),
    error: createAction(types.UPDATE_MISSION_ERROR, error => ({ error })),
    complete: createAction(
      types.UPDATE_MISSION_COMPLETE,
      payload => ({ payload })
    )
  },
  updateGameLevel: {
    start: createAction(types.UPDATE_GAMELEVEL_START),
    error: createAction(types.UPDATE_GAMELEVEL_ERROR, error => ({ error })),
    complete: createAction(
      types.UPDATE_GAMELEVEL_COMPLETE,
      payload => ({ payload })
    )
  },
  deleteAdminCourses: {
    start: createAction(types.DELETE_COURSES_START),
    error: createAction(types.DELETE_COURSES_ERROR, error => ({ error })),
    complete: createAction(
      types.DELETE_COURSES_COMPLETE,
      message => ({ message })
    )
  },
  deleteAdminTeachers: {
    start: createAction(types.DELETE_TEACHERS_START),
    error: createAction(types.DELETE_TEACHERS_ERROR, error => ({ error })),
    complete: createAction(
      types.DELETE_TEACHERS_COMPLETE,
      message => ({ message })
    )
  },
  deleteAdminMissions: {
    start: createAction(types.DELETE_MISSIONS_START),
    error: createAction(types.DELETE_MISSIONS_ERROR, error => ({ error })),
    complete: createAction(
      types.DELETE_MISSIONS_COMPLETE,
      message => ({ message })
    )
  }

  
};