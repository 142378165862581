import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Table,
  Button,
  TableRow,
  TableHead,
  TableBody,
  DataTable,
  TableCell,
  TableHeader,
  TableToolbar,
  TableContainer,
  DataTableSkeleton,
  TableToolbarSearch,
  TableToolbarContent
} from 'carbon-components-react';
import { thunks as teacherThunks } from '../../../redux/actions/thunks/teacher';
import { studentsListHeader } from '../../../constants';
import Error from '../../../shared/error';
import { parseError } from './../../../utils';
import { DoNot_02 as DoNot02 } from '@carbon/pictograms-react';
import { Link } from 'react-router-dom';

const Students = (props) => {
  const {
    students, getStudents, courseId,
    user
  } = props;
  const {
    payload: studentsPayload,
    loading: studentsLoading,
    error: studentsError
  } = students || {};
  const {
    id: teacherId,
    token: apiToken
  } = user || {};

  useEffect(() => {
    getStudents(
      {
        courseId,
        teacherId
      },
      {
        apiToken
      });
  }, [getStudents]);

  return (
    <>
      {
        !studentsLoading &&
        studentsError &&
        <Error errorMessage={__(parseError(studentsError))} />
      }
      {
        studentsLoading &&
        !studentsError &&
        <DataTableSkeleton
          headers={studentsListHeader.map(s =>
            ({ ...s, header: __(s.header) }))} />
      }
      {
        !studentsLoading &&
        !studentsError &&
        !!studentsPayload.length &&
        <DataTable
          rows={studentsPayload.map(s => ({ ...s, id: String(s.id) }))}
          headers={studentsListHeader.map(s =>
            ({ ...s, header: __(s.header) }))}>
          {({
            rows,
            headers,
            onInputChange,
            getTableProps,
            getHeaderProps,
            getToolbarProps,
            getBatchActionProps,
            getTableContainerProps
          }) => (
            <TableContainer
              title={__('Student list')}
              {...getTableContainerProps()}>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    placeholder="Buscador"
                    persistent={true}
                    tabIndex={getBatchActionProps().shouldShowBatchActions ?
                      -1 : 0
                    }
                    onChange={onInputChange}
                  />
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header, idx) => (
                      <TableHeader key={idx} {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows && rows?.map((row) => (
                    <TableRow key={String(row.id)}>
                      {row.cells.map((cell) => (
                        <TableCell key={String(cell.id)}>
                          {
                            cell.id?.endsWith('actions')
                              ?
                              <Link
                                to={`/teacher/app/course/${courseId}/student/${row.id}`}
                              >
                                <Button
                                  className='bpp-m-16'
                                >
                                  {__('Open details')}
                                </Button>
                              </Link>
                              : cell.value
                          }
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      }
      {
        (
          !studentsLoading &&
          !studentsError &&
          !studentsPayload?.length
        ) &&
        <div className="bpp-flx-c bpp-flx-clmn">
          <DoNot02 />
          <p>{__('No students were found!')}</p>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    students: state.teacher.students,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getStudents: teacherThunks.getStudents
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Students);