import { 
  getCourses,
  createCourse,
  getStudents,
  getOverview,
  getAchievements,
  getMissions,
  updateCourse,
  getCourse
} from '../../../services/teacher';
import { actions as teacherActions } from '../teacher';


export const thunks = {
  createCourse(data, credentials) {
    return async (dispatch) => {
      dispatch(teacherActions.createCourse.start());
      try {
        const payload = await createCourse(data, credentials);
        dispatch(teacherActions.createCourse.complete(payload));
        return;
      } catch (error) {
        console.log('TEACHER ACTIONS', error);
        dispatch(teacherActions.createCourse.error(error));
        return;
      }
    };
  },
  getCourses(props, credentials) {
    return async (dispatch) => {
      dispatch(teacherActions.getCourses.start());
      try {
        const payload = await getCourses(props, credentials);
        dispatch(teacherActions.getCourses.complete(payload));
        return;
      } catch (error) {
        console.log('TEACHER ACTIONS', error);
        dispatch(teacherActions.getCourses.error(error));
        return;
      }
    };
  },
  getStudents(props, credentials) {
    return async (dispatch) => {
      dispatch(teacherActions.getStudents.start());
      try {
        const payload = await getStudents(props, credentials);
        dispatch(teacherActions.getStudents.complete(payload));
        return;
      } catch (error) {
        console.log('TEACHER ACTIONS', error);
        dispatch(teacherActions.getStudents.error(error));
        return;
      }
    };
  },
  getOverview(props, credentials) {
    return async (dispatch) => {
      dispatch(teacherActions.getOverview.start());
      try {
        const payload = await getOverview(props, credentials);
        dispatch(teacherActions.getOverview.complete(payload));
        return;
      } catch (error) {
        console.log('TEACHER ACTIONS', error);
        dispatch(teacherActions.getOverview.error(error));
        return;
      }
    };
  },
  getAchievements(props, credentials) {
    return async (dispatch) => {
      dispatch(teacherActions.getAchievements.start());
      try {
        const payload = await getAchievements(props, credentials);
        dispatch(teacherActions.getAchievements.complete(payload));
        return;
      } catch (error) {
        console.log('TEACHER ACTIONS', error);
        dispatch(teacherActions.getAchievements.error(error));
        return;
      }
    };
  },
  getMissionsG(props, credentials) {
    return async (dispatch) => {
      dispatch(teacherActions.getMissionsG.start());
      try {
        const payload = await getMissions(props, credentials);
        dispatch(teacherActions.getMissionsG.complete(payload));
        return;
      } catch (error) {
        console.log('TEACHER ACTIONS', error);
        dispatch(teacherActions.getMissionsG.error(error));
        return;
      }
    };
  },
  getMissionsT(props, credentials) {
    return async (dispatch) => {
      dispatch(teacherActions.getMissionsT.start());
      try {
        const payload = await getMissions(props, credentials);
        dispatch(teacherActions.getMissionsT.complete(payload));
        return;
      } catch (error) {
        console.log('TEACHER ACTIONS', error);
        dispatch(teacherActions.getMissionsT.error(error));
        return;
      }
    };
  },
  updateCourse(props, credentials) {
    return async (dispatch) => {
      dispatch(teacherActions.updateCourse.start());
      try {
        const payload = await updateCourse(props, credentials);
        dispatch(teacherActions.updateCourse.complete(payload));
        return;
      } catch (error) {
        console.log('TEACHER ACTIONS', error);
        dispatch(teacherActions.updateCourse.error(error));
        return;
      }
    };
  },
  getCourse(props, credentials) {
    return async (dispatch) => {
      dispatch(teacherActions.getCourse.start());
      try {
        const payload = await getCourse(props, credentials);
        dispatch(teacherActions.getCourse.complete(payload));
        return;
      } catch (error) {
        console.log('TEACHER ACTIONS', error);
        dispatch(teacherActions.getCourse.error(error));
        return;
      }
    };
  }
};