import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { 
  Form,
  Button,
  Loading,
  TextArea,
  TextInput
} from 'carbon-components-react';
import { createCourse as CreateCourseSchema } from '../../../schema';
import { thunks as teacherThunks } from '../../../redux/actions/thunks/teacher';


import './index.scss';

const CreateCourse = (props) => {
  const history = useHistory();
  const {
    user, loading, createCourse
  } = props;
  const {
    id: teacherId,
    token: apiToken
  } = user || {};
  const {
    formState: { errors },
    control, handleSubmit
  } = useForm({ resolver: yupResolver(CreateCourseSchema) });
  const onSubmit = (data) => {
    createCourse({
      ...data,
      teacherId
    }, {
      apiToken 
    })
      .then(() => {
        history.push('/teacher/app/courses');
      })
      .catch(error => {
        console.log('ERROR:', error);
      });
  };
  return (
    <div className='bpp-flx-end bpp-w-100 bpp-h-100'>
      <div className='bpp-w-50 bpp-form'>
        <div className='bpp-register-title bpp-mt-32'>
          <h3 className='bpp-register-divisor'>{__('Create a new course')}</h3>
        </div>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className='bpp-mt-32'>
            <Controller
              name='name'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextInput
                  id='name'
                  type='text'
                  labelText={__('Course name')}
                  placeholder=''
                  invalid={!!errors.name}
                  invalidText={__(errors.name?.message)}
                  helperText={__('* Required')}
                  {...field}
                />
              )}/>
          </div>
          <div className='bpp-mt-32'>
            <Controller
              name='description'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextArea
                  id='description'
                  type='text'
                  helperText={__('* Required')}
                  labelText={__('Description')}
                  placeholder=''
                  invalid={!!errors.description}
                  invalidText={__(errors.description?.message)}
                  {...field}
                />
              )}/>
          </div>
          <Button
            type='submit'
            className='bpp-mt-32'>
            {__('Create course')}
            {
              loading &&
              <Loading
                className='bpp-ml-64'
                description='Creating course' small withOverlay={false}/>
            }
          </Button>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    error: state.user.error,
    loading: state.user.loading
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  createCourse: teacherThunks.createCourse
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourse);
