import { 
  login,
  register,
  get,
  forgotPassword,
  resetPassword,
  validateExpiry
} from '../../../services/user';
import { actions as userActions } from '../user';

export const thunks = {
  register(data) {
    return async (dispatch) => {
      dispatch(userActions.register.start());
      try {
        const payload = await register(data);
        dispatch(userActions.register.complete(payload));
        return payload;
      } catch (error) {
        console.log('USER ACTIONS', error);
        dispatch(userActions.register.error(error));
        throw error;
      }
    };
  },
  login(data) {
    return async (dispatch) => {
      dispatch(userActions.login.start());
      try {
        const payload = await login(data);
        dispatch(userActions.login.complete(payload));
        return payload;
      } catch (error) {
        console.log('USER ACTIONS', error);
        dispatch(userActions.login.error(error));
        throw error;
      }
    };
  },
  get(data) {
    return async (dispatch) => {
      dispatch(userActions.get.start());
      try {
        const payload = await get(data);
        dispatch(userActions.get.complete(payload));
        return;
      } catch (error) {
        console.log('USER ACTIONS', error);
        dispatch(userActions.get.error(error));
        return;
      }
    };
  },
  forgotPassword(data) {
    return async () => {
      try {
        const payload = await forgotPassword(data);
        return payload;
      } catch (error) {
        console.log('USER ACTIONS', error);
        throw error;
      }
    };
  },
  resetPassword(data) {
    return async () => {
      try {
        const payload = await resetPassword(data);
        return payload;
      } catch (error) {
        console.log('USER ACTIONS', error);
        throw error;
      }
    };
  },
  validateExpiry(data) {
    return async () => {
      try {
        const payload = await validateExpiry(data);
        return payload;
      } catch (error) {
        console.log('USER ACTIONS', error);
        throw error;
      }
    };
  },
  logout() {
    return (dispatch) => {
      dispatch(userActions.logout());
      return;
    };
  },
  updateBreadCrumbs(params) {
    return (dispatch) => {
      dispatch(userActions.updateBreadCrumbs(params));
      return;
    };
  }
};