import React, { useEffect, useState } from 'react';
import { Loading, Pagination } from 'carbon-components-react';
import GameLevelsTable from './GameLevelsTable';
import './GameLevelsPage.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { thunks as adminThunks } from '../../../redux/actions/thunks/admin';
import Error from '../../../shared/error';
import { parseError } from '../../../utils';


const GameLevelPage = (props) => {

  const {
    gameLevelsadmin, getAdminGameLevelsL,
    user
  } = props;

  const {
    total: gameLeveladminTotal,
    payload: gameLeveladminPayload,
    error: gameLeveladminError,
    loading: gameLeveladminLoading
  } = gameLevelsadmin || {};
  const {
    token: apiToken,
    error: userError,
    loading: userLoading
  } = user || {};


  const [limit, setLimit] = useState(4);
  const [page, setPage] = useState(1);
  const [gameLevels, setGameLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(null);
  useEffect(() => {

    getAdminGameLevelsL(
      {
        limit,
        page
      },
      {
        apiToken
      });
    setLoading(true);
    setLoading(false);
  }, [limit, page]);

  useEffect(() => {
    
    setGameLevels(gameLeveladminPayload);
    setTotal(gameLeveladminTotal);
  }, [gameLeveladminPayload]);


  return (
    <>
      <div className='bpp-mt-16'>
        {
          !userLoading &&
          userError &&
          <Error errorMessage={__(parseError(userError))} />
        }
        {
          !gameLeveladminLoading &&
          gameLeveladminError &&
          <Error errorMessage={__(parseError(gameLeveladminError))} />
        }
        {
          loading &&
          <Loading active={loading} withOverlay={true} />
        }
        {
          !userLoading &&
          !userError &&
          !gameLeveladminLoading &&
          !gameLeveladminError &&
          !loading &&
          gameLevels?.length && (
            <>
              <GameLevelsTable gameLevels={gameLevels} />
              <Pagination
                onChange={e => {setPage(e.page);setLimit(e.pageSize);}}
                backwardText={__('Previous page')}
                forwardText={__('Next page')}
                itemsPerPageText={__('Items per page:')}
                page={page}
                pageNumberText={__('Page Number')}
                pageSize={limit}
                pageSizes={[4,5,6]}
                totalItems={total}
              />
            </>
          )
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    gameLevelsadmin: state.admin.gameLevels,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAdminGameLevelsL: adminThunks.getAdminGameLevelsL
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(GameLevelPage);
