import React from 'react';
import 'carbon-components/css/carbon-components.min.css';
import ModalMissionAdd from './ModalMissionAdd';
import ModalMissionEdit from './ModalMissionEdit';
import {
  DataTable,
  TableContainer,
  TableToolbar,
  TableBatchActions,
  TableBatchAction,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectAll,
  TableSelectRow,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell
} from 'carbon-components-react';

import {
  Delete16 as Delete
  //Save16 as Save,
  //Download16 as Download
} from '@carbon/icons-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { missionsListHeader } from '../../../constants';
import { thunks as adminThunks } from '../../../redux/actions/thunks/admin';

import Swal from 'sweetalert2';

const MissionsTable = (props) => {
  const { missions, user, deleteAdminMissions } = props;

  const {
    token: apiToken
  } = user || {};

  const batchActionClick = (e, action, data) => {
    e.preventDefault();

    Swal.fire({
      title: '¿Estas seguro?',
      text: '¡No seras capaz de revertir esta accion!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borralo(s)!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        for (let elemento of data) {
          var idMission = elemento.id;
          deleteAdminMissions(
            idMission ,{
              apiToken 
            })
            .then(() => {
              console.log('Eliminacion exitosa');
            })
            .catch(err => {
              console.log('ERROR:', err);
              Swal.fire(
                '¡Ha sucedido un error!',
                err,
                'error'
              );
            });
        }


        Swal.fire(
          '¡Operacion exitosa!',
          'Los elementos seleccionados han sido borrados.',
          'success'
        )
          .then(() => {
            window.location.reload(); 
          }
          );}
    });
  };
   

  return (
    <>
      <DataTable 
        rows={missions.map(s => ({ ...s, id: String(s.id) }))}
        headers={missionsListHeader.map(s =>
          ({ ...s, header: __(s.header) }))}
        isSortable>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          getBatchActionProps,
          onInputChange,
          selectedRows,
          getTableProps,
          getToolbarProps,
          getTableContainerProps
        }) => (
          <TableContainer
            {...getTableContainerProps()}
            title={__('Missions')}
            description={__('List of missions')}
          >
            <TableToolbar
              {...getToolbarProps()}
            >
              <TableBatchActions {...getBatchActionProps()}>
                <TableBatchAction
                  tabIndex={
                    getBatchActionProps().shouldShowBatchActions ? 0 : -1
                  }
                  renderIcon={Delete}
                  onClick={(e) => batchActionClick(e, 'delete', selectedRows)}>
                  {__('Delete')}
                </TableBatchAction>
              </TableBatchActions>
              <TableToolbarContent>
                <TableToolbarSearch
                  tabIndex={
                    getBatchActionProps().shouldShowBatchActions ? -1 : 0
                  }
                  onChange={onInputChange}
                />
                <ModalMissionAdd />
              </TableToolbarContent>
            </TableToolbar>
            <Table
              {...getTableProps()}
            >
              <TableHead>
                <TableRow>
                  <TableSelectAll {...getSelectionProps()} />
                  {headers.map((header, i) => (
                    <TableHeader key={i} {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={i} {...getRowProps({ row })}>
                    <TableSelectRow {...getSelectionProps({ row })} />
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        
                        {
                          cell.id?.endsWith('actions') &&
                           
                              
                           <ModalMissionEdit elid={row.id} />
                                             
                            
                        }
                        {
                          cell.value
                        }                        
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    error: state.user.error,
    loading: state.user.loading
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  deleteAdminMissions: adminThunks.deleteAdminMissions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MissionsTable);