import React, { useEffect, useState } from 'react';
import { Loading, Pagination } from 'carbon-components-react';
import MissionsTable from './MissionsTable';
import './missionsPage.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { thunks as adminThunks } from '../../../redux/actions/thunks/admin';
import Error from '../../../shared/error';
import { parseError } from '../../../utils';


const MissionsPage = (props) => {

  const {
    missionsadmin, getAdminMissionsL,
    user
  } = props;

  const {
    total: missionadminTotal,
    payload: missionsadminPayload,
    error: missionadminError,
    loading: missionadminLoading
  } = missionsadmin || {};
  const {
    token: apiToken,
    error: userError,
    loading: userLoading
  } = user || {};


  const [limit, setLimit] = useState(4);
  const [page, setPage] = useState(1);
  const [missions, setMissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(null);
  useEffect(() => {

    getAdminMissionsL(
      {
        limit,
        page
      },
      {
        apiToken
      });
    setLoading(true);
    setLoading(false);
  }, [limit, page]);

  useEffect(() => {
    
    setMissions(missionsadminPayload);
    setTotal(missionadminTotal);
  }, [missionsadminPayload]);


  return (
    <>
      <div className='bpp-mt-16'>
        {
          !userLoading &&
          userError &&
          <Error errorMessage={__(parseError(userError))} />
        }
        {
          !missionadminLoading &&
          missionadminError &&
          <Error errorMessage={__(parseError(missionadminError))} />
        }
        {
          loading &&
          <Loading active={loading} withOverlay={true} />
        }
        {
          !userLoading &&
          !userError &&
          !missionadminLoading &&
          !missionadminError &&
          !loading &&
          missions?.length && (
            <>
              <MissionsTable missions={missions} />
              <Pagination
                onChange={e => {setPage(e.page);setLimit(e.pageSize);}}
                backwardText={__('Previous page')}
                forwardText={__('Next page')}
                itemsPerPageText={__('Items per page:')}
                page={page}
                pageNumberText={__('Page Number')}
                pageSize={limit}
                pageSizes={[4,5,6]}
                totalItems={total}
              />
            </>
          )
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    missionsadmin: state.admin.missions,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAdminMissionsL: adminThunks.getAdminMissionsL
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(MissionsPage);
