import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Badge, DoNot_02 as DoNot02 } from '@carbon/pictograms-react';
import {
  Loading
} from 'carbon-components-react';
import Bar from 'react-meter-bar';

import { thunks as teacherThunks } from '../../../../redux/actions/thunks/teacher';
import { Group16, User16 } from '@carbon/icons-react';
import Error from '../../../../shared/error';
import Separator from '../../../../shared/separator';
import { parseError } from './../../../../utils';
import './index.css';


const Achievements = (props) => {
  const {
    achievements, getAchievements, courseId,
    user
  } = props;
  const {
    payload: achPayload,
    loading: achLoading,
    error: achError
  } = achievements || {};
  const {
    id: teacherId,
    token: apiToken
  } = user || {};

  useEffect(() => {
    getAchievements(
      {
        teacherId: teacherId,
        courseId: courseId
      },
      {
        apiToken
      }
    );
  }, [getAchievements]);

  const getBarColor = (value) => {
    if (value <= 50) {
      return '#ff0000';
    } 
    if (value <= 60) {
      return '#ffd700';
    }
    return '#008000';
  };

  return (
    <div className='bpp-class-achivements'>
      {
        !achLoading &&
        achError &&
        <Error errorMessage={__(parseError(achError))} />
      }
      {
        achLoading &&
        !achError &&
        <div className='bpp-flx-c'>
          <Loading
            description='Achievements are loading'
            withOverlay={false}
          />
        </div>
      }
      {
        !achError &&
        !achLoading &&
        achPayload?.length > 0 &&
        <ul
          className='bpp-flx-c bpp-flx-clmn'>
          {
            achPayload.map((a, i) => (
              <React.Fragment key={`${a.id}-${i}`}>
                <li
                  className={`bpp-flx-c bpp-w-100 ${i !== 0 ? 'bpp-mt-32' : ''}`}>
                  <div className='bpp-flx-clmn bpp-flx-c bpp-text-detail'>
                    {
                      a.srcPath &&
                      <img src={a.srcPath} alt='badge-img' />
                    }
                    <Badge />
                    <p className='bpp-txt-center'>{a.name}</p>
                    <p className='bpp-txt-center'>{a.description}</p>
                  </div>
                  <div className='bpp-flx-clmn bpp-flx-c'>
                    <p>{__('Completed')}</p>
                    <div className='bpp-flx-c'>
                      <p className='bpp-ml-8 bpp-flx-clmn bpp-flx-c'>
                        <User16 />
                        {`${a.detail.girlsCompleted} F`}
                      </p>
                      <p className='bpp-ml-8 bpp-flx-clmn bpp-flx-c'>
                        <User16 />
                        {`${a.detail.boysCompleted} M`}
                      </p>
                      <p className='bpp-ml-8 bpp-flx-clmn bpp-flx-c'>
                        <User16 />
                        {`${a.detail.NACompleted} NA`}
                      </p>
                    </div>
                  </div>
                  <div className='bpp-ml-16'>
                    <div className='bpp-flx-vc bpp-flx-c'>
                      <div className='bpp-flx-v'>
                        <p>
                          {
                            a.detail.totalStudents ? 
                              Math.trunc(
                                a.detail.studentsCompleted /
                              a.detail.totalStudents * 100, 2
                              )
                              : 0
                          }%
                        </p>
                        <Bar
                          labels={[]}
                          progress={
                            a.detail.totalStudents ?
                              Math.trunc(
                                a.detail.studentsCompleted /
                            a.detail.totalStudents * 100, 2
                              ): 0}
                          barColor={
                            getBarColor(
                              a.detail.totalStudents ?
                                Math.trunc(
                                  a.detail.studentsCompleted /
                            a.detail.totalStudents * 100, 2
                                ): 0)}/>
                      </div>
                      <div className='bpp-achv-students bpp-ml-64'>
                        <Group16 className='bpp-mr-8'/>
                        {__('*STUDENTS* completed')
                          .replace('*STUDENTS*', `${a.detail.studentsCompleted}/${a.detail.totalStudents}`)}
                      </div>
                    </div>
                  </div>
                </li>
                {
                  i != achPayload.length -1 && 
                  <Separator/>
                }
                
              </React.Fragment>
            ))
          }
        </ul>
      }
      {
        (!achLoading &&
          !achError &&
          (!achPayload ||
            !achPayload.length)) &&
        <div className='bpp-flx-c bpp-flx-clmn'>
          <DoNot02 />
          <p>{__('No achievements were found!')}</p>
        </div>
      }
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    achievements: state.teacher.achievements,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAchievements: teacherThunks.getAchievements
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Achievements);