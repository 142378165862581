import { types } from '../actions/admin';

const {
  
  GET_ADMINCOURSES_START,
  GET_ADMINCOURSES_ERROR,
  GET_ADMINCOURSES_COMPLETE,

  GET_ADMINCOURSESL_START,
  GET_ADMINCOURSESL_ERROR,
  GET_ADMINCOURSESL_COMPLETE,

  GET_ADMINTEACHERSL_START,
  GET_ADMINTEACHERSL_ERROR,
  GET_ADMINTEACHERSL_COMPLETE,

  GET_ADMINMISSIONSL_START,
  GET_ADMINMISSIONSL_ERROR,
  GET_ADMINMISSIONSL_COMPLETE,

  GET_ADMINGAMELEVELSL_START,
  GET_ADMINGAMELEVELSL_ERROR,
  GET_ADMINGAMELEVELSL_COMPLETE,


  CREATE_ADMINCOURSES_START,
  CREATE_ADMINCOURSES_ERROR,
  CREATE_ADMINCOURSES_COMPLETE,

  CREATE_ADMINTEACHERS_START,
  CREATE_ADMINTEACHERS_ERROR,
  CREATE_ADMINTEACHERS_COMPLETE,

  CREATE_ADMINMISSIONS_START,
  CREATE_ADMINMISSIONS_ERROR,
  CREATE_ADMINMISSIONS_COMPLETE,

  CREATE_ADMINGAMELEVELS_START,
  CREATE_ADMINGAMELEVELS_ERROR,
  CREATE_ADMINGAMELEVELS_COMPLETE,


  UPDATE_PASSWORDT_START,
  UPDATE_PASSWORDT_ERROR,
  UPDATE_PASSWORDT_COMPLETE,

  UPDATE_MISSION_START,
  UPDATE_MISSION_ERROR,
  UPDATE_MISSION_COMPLETE,

  UPDATE_GAMELEVEL_START,
  UPDATE_GAMELEVEL_ERROR,
  UPDATE_GAMELEVEL_COMPLETE,


  DELETE_COURSES_START,
  DELETE_COURSES_ERROR,
  DELETE_COURSES_COMPLETE,

  DELETE_TEACHERS_START,
  DELETE_TEACHERS_ERROR,
  DELETE_TEACHERS_COMPLETE,

  DELETE_MISSIONS_START,
  DELETE_MISSIONS_ERROR,
  DELETE_MISSIONS_COMPLETE

} = types;

const initialState = {
  courses: {
    total: 0,
    payload: [],
    error: null,
    loading: false
  },
  newCourse: {
    payload: {},
    error: null,
    loading: false
  },
  currentCourse: {
    message: null,
    error: null,
    loading: false
  },
  teachers: {
    total: 0,
    payload: [],
    error: null,
    loading: false
  },
  newTeacher: {
    payload: {},
    error: null,
    loading: false
  },
  currentTeacher: {
    message: null,
    error: null,
    loading: false
  },
  missions: {
    total: 0,
    payload: [],
    error: null,
    loading: false
  },
  newMission: {
    payload: {},
    error: null,
    loading: false
  },
  currentMission: {
    payload: {},
    error: null,
    loading: false
  },
  gameLevels: {
    total: 0,
    payload: [],
    error: null,
    loading: false
  },
  newGameLevel: {
    payload: {},
    error: null,
    loading: false
  },
  currentGameLevel: {
    payload: {},
    error: null,
    loading: false
  }
};

const startGetCourses = (state) => {
  return {
    ...state,
    courses: {
      ...state.courses,
      error: null,
      loading: true
    }
  };
};

const errorGetCourses = (state, action) => {
  return {
    ...state,
    courses: {
      ...state.courses,
      error: action.payload,
      loading: false
    }
  };
};

const completeGetCourses = (state, action) => {
  const { payload } = action.payload;
  return {
    ...state,
    courses: {
      ...state.courses,
      error: null,
      loading: false,
      payload: [
        ...payload.course
      ],
      total: payload.total
    }
  };
};


const startGetTeachers = (state) => {
  return {
    ...state,
    teachers: {
      ...state.teachers,
      error: null,
      loading: true
    }
  };
};

const errorGetTeachers = (state, action) => {
  return {
    ...state,
    teachers: {
      ...state.teachers,
      error: action.payload,
      loading: false
    }
  };
};

const completeGetTeachers = (state, action) => {
  const { payload } = action.payload;
  return {
    ...state,
    teachers: {
      ...state.teachers,
      error: null,
      loading: false,
      payload: [
        ...payload.teacher
      ],
      total: payload.total
    }
  };
};


const startGetMissions = (state) => {
  return {
    ...state,
    missions: {
      ...state.missions,
      error: null,
      loading: true
    }
  };
};

const errorGetMissions = (state, action) => {
  return {
    ...state,
    missions: {
      ...state.missions,
      error: action.payload,
      loading: false
    }
  };
};

const completeGetMissions = (state, action) => {
  const { payload } = action.payload;
  return {
    ...state,
    missions: {
      ...state.missions,
      error: null,
      loading: false,
      payload: [
        ...payload.missions
      ],
      total: payload.total
    }
  };
};

const startGetGameLevels = (state) => {
  return {
    ...state,
    gameLevels: {
      ...state.gameLevels,
      error: null,
      loading: true
    }
  };
};

const errorGetGameLevels = (state, action) => {
  return {
    ...state,
    gameLevels: {
      ...state.gameLevels,
      error: action.payload,
      loading: false
    }
  };
};

const completeGetGameLevels = (state, action) => {
  const { payload } = action.payload;
  return {
    ...state,
    gameLevels: {
      ...state.gameLevels,
      error: null,
      loading: false,
      payload: [
        ...payload.gameLevels
      ],
      total: payload.total
    }
  };
};

const startCreateCourse = (state) => {
  return {
    ...state,
    newCourse: {
      ...state.newCourse,
      error: null,
      loading: true
    }
  };
};

const errorCreateCourse = (state, action) => {
  return {
    ...state,
    newCourse: {
      ...state.newCourse,
      error: action.payload,
      loading: false
    }
  };
};

const completeCreateCourse = (state, action) => {
  const { dataClass } = action.payload;
  return {
    ...state,
    courses: {
      ...state.courses,
      payload: [
        ...state.courses.payload
        //,dataClass
      ],
      loading: false
    },
    newCourse: {
      ...state.newCourse,
      payload: dataClass,
      loading: false,
      error: null
    }
  };
};


const startCreateTeacher = (state) => {
  return {
    ...state,
    newTeacher: {
      ...state.newTeacher,
      error: null,
      loading: true
    }
  };
};

const errorCreateTeacher = (state, action) => {
  return {
    ...state,
    newTeacher: {
      ...state.newTeacher,
      error: action.payload,
      loading: false
    }
  };
};

const completeCreateTeacher = (state, action) => {
  const { dataClass } = action.payload;
  return {
    ...state,
    teachers: {
      ...state.teachers,
      payload: [
        ...state.teachers.payload
        //,dataClass
      ],
      loading: false
    },
    newTeacher: {
      ...state.newTeacher,
      payload: dataClass,
      loading: false,
      error: null
    }
  };
};

const startCreateMission = (state) => {
  return {
    ...state,
    newMission: {
      ...state.newMission,
      error: null,
      loading: true
    }
  };
};

const errorCreateMission = (state, action) => {
  return {
    ...state,
    newMission: {
      ...state.newMission,
      error: action.payload,
      loading: false
    }
  };
};

const completeCreateMission = (state, action) => {
  const { dataClass } = action.payload;
  return {
    ...state,
    missions: {
      ...state.missions,
      payload: [
        ...state.missions.payload
      ],
      loading: false
    },
    newMission: {
      ...state.newMission,
      payload: dataClass,
      loading: false,
      error: null
    }
  };
};

const startCreateGameLevel = (state) => {
  return {
    ...state,
    newGameLevel: {
      ...state.newGameLevel,
      error: null,
      loading: true
    }
  };
};

const errorCreateGameLevel = (state, action) => {
  return {
    ...state,
    newGameLevel: {
      ...state.newGameLevel,
      error: action.payload,
      loading: false
    }
  };
};

const completeCreateGameLevel = (state, action) => {
  const { dataClass } = action.payload;
  return {
    ...state,
    gameLevels: {
      ...state.gameLevels,
      payload: [
        ...state.gameLevels.payload
      ],
      loading: false
    },
    newGameLevel: {
      ...state.newGameLevel,
      payload: dataClass,
      loading: false,
      error: null
    }
  };
};

const OnGetPasswordTStart = (state) => {
  return {
    ...state,
    currentTeacher: {
      ...state.currentTeacher,
      error: null,
      loading: true
    }
  };
};

const OnGetPasswordTError = (state, action) => {
  return {
    ...state,
    currentTeacher: {
      ...state.currentTeacher,
      error: action.message,
      loading: false
    }
  };
};

const OnGetPasswordTComplete = (state, action) => {
  return {
    ...state,
    teachers: {
      ...state.teachers,
      payload: [
        ...state.teachers.payload
      ],
      loading: false
    },
    currentTeacher: {
      ...state.currentTeacher,
      message: action.message,
      loading: false,
      error: null
    }
  };
};

const OnGetMissionStart = (state) => {
  return {
    ...state,
    currentMission: {
      ...state.currentMission,
      error: null,
      loading: true
    }
  };
};

const OnGetMissionError = (state, action) => {
  return {
    ...state,
    currentMission: {
      ...state.currentMission,
      error: action.payload,
      loading: false
    }
  };
};

const OnGetMissionComplete = (state, action) => {
  const { dataClass } = action.payload;
  return {
    ...state,
    missions: {
      ...state.missions,
      payload: [
        ...state.missions.payload
      ],
      loading: false
    },
    currentMission: {
      ...state.currentMission,
      payload: dataClass,
      loading: false,
      error: null
    }
  };
};

const OnGetGameLevelStart = (state) => {
  return {
    ...state,
    currentGameLevel: {
      ...state.currentGameLevel,
      error: null,
      loading: true
    }
  };
};

const OnGetGameLevelError = (state, action) => {
  return {
    ...state,
    currentGameLevel: {
      ...state.currentGameLevel,
      error: action.payload,
      loading: false
    }
  };
};

const OnGetGameLevelComplete = (state, action) => {
  const { dataClass } = action.payload;
  return {
    ...state,
    gameLevels: {
      ...state.gameLevels,
      payload: [
        ...state.gameLevels.payload
      ],
      loading: false
    },
    currentGameLevel: {
      ...state.currentGameLevel,
      payload: dataClass,
      loading: false,
      error: null
    }
  };
};

const EliminatedCoursesStart = (state) => {
  return {
    ...state,
    currentCourse: {
      ...state.currentCourse,
      error: null,
      loading: true
    }
  };
};

const EliminatedCoursesError = (state, action) => {
  return {
    ...state,
    currentCourse: {
      ...state.currentCourse,
      error: action.message,
      loading: false
    }
  };
};

const EliminatedCoursesComplete = (state, action) => {
  return {
    ...state,
    courses: {
      ...state.courses,
      payload: [
        ...state.courses.payload
      ],
      loading: false
    },
    currentCourse: {
      ...state.currentCourse,
      message: action.message,
      loading: false,
      error: null
    }
  };
};

const EliminatedTeachersStart = (state) => {
  return {
    ...state,
    currentTeacher: {
      ...state.currentTeacher,
      error: null,
      loading: true
    }
  };
};

const EliminatedTeachersError = (state, action) => {
  return {
    ...state,
    currentTeacher: {
      ...state.currentTeacher,
      error: action.message,
      loading: false
    }
  };
};

const EliminatedTeachersComplete = (state, action) => {
  return {
    ...state,
    teachers: {
      ...state.teachers,
      payload: [
        ...state.teachers.payload
      ],
      loading: false
    },
    currentTeacher: {
      ...state.currentTeacher,
      message: action.message,
      loading: false,
      error: null
    }
  };
};

const EliminatedMissionsStart = (state) => {
  return {
    ...state,
    currentMission: {
      ...state.currentMission,
      error: null,
      loading: true
    }
  };
};

const EliminatedMissionsError = (state, action) => {
  return {
    ...state,
    currentMission: {
      ...state.currentMission,
      error: action.message,
      loading: false
    }
  };
};

const EliminatedMissionsComplete = (state, action) => {
  return {
    ...state,
    missions: {
      ...state.missions,
      payload: [
        ...state.missions.payload
      ],
      loading: false
    },
    currentMission: {
      ...state.currentMission,
      message: action.message,
      loading: false,
      error: null
    }
  };
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
  case GET_ADMINCOURSES_START:
    return startGetCourses(state, action);
  case GET_ADMINCOURSES_ERROR:
    return errorGetCourses(state, action);
  case GET_ADMINCOURSES_COMPLETE:
    return completeGetCourses(state, action);
    
  case GET_ADMINCOURSESL_START:
    return startGetCourses(state, action);
  case GET_ADMINCOURSESL_ERROR:
    return errorGetCourses(state, action);
  case GET_ADMINCOURSESL_COMPLETE:
    return completeGetCourses(state, action);

  case GET_ADMINTEACHERSL_START:
    return startGetTeachers(state, action);
  case GET_ADMINTEACHERSL_ERROR:
    return errorGetTeachers(state, action);
  case GET_ADMINTEACHERSL_COMPLETE:
    return completeGetTeachers(state, action);

  case GET_ADMINMISSIONSL_START:
    return startGetMissions(state, action);
  case GET_ADMINMISSIONSL_ERROR:
    return errorGetMissions(state, action);
  case GET_ADMINMISSIONSL_COMPLETE:
    return completeGetMissions(state, action);

  case GET_ADMINGAMELEVELSL_START:
    return startGetGameLevels(state, action);
  case GET_ADMINGAMELEVELSL_ERROR:
    return errorGetGameLevels(state, action);
  case GET_ADMINGAMELEVELSL_COMPLETE:
    return completeGetGameLevels(state, action);

  case CREATE_ADMINCOURSES_START:
    return startCreateCourse(state, action);
  case CREATE_ADMINCOURSES_ERROR:
    return errorCreateCourse(state, action);
  case CREATE_ADMINCOURSES_COMPLETE:
    return completeCreateCourse(state, action);

  case CREATE_ADMINTEACHERS_START:
    return startCreateTeacher(state, action);
  case CREATE_ADMINTEACHERS_ERROR:
    return errorCreateTeacher(state, action);
  case CREATE_ADMINTEACHERS_COMPLETE:
    return completeCreateTeacher(state, action);

  case CREATE_ADMINMISSIONS_START:
    return startCreateMission(state, action);
  case CREATE_ADMINMISSIONS_ERROR:
    return errorCreateMission(state, action);
  case CREATE_ADMINMISSIONS_COMPLETE:
    return completeCreateMission(state, action);

  case CREATE_ADMINGAMELEVELS_START:
    return startCreateGameLevel(state, action);
  case CREATE_ADMINGAMELEVELS_ERROR:
    return errorCreateGameLevel(state, action);
  case CREATE_ADMINGAMELEVELS_COMPLETE:
    return completeCreateGameLevel(state, action);

  case UPDATE_PASSWORDT_START:
    return OnGetPasswordTStart(state, action);
  case UPDATE_PASSWORDT_ERROR:
    return OnGetPasswordTError(state, action);
  case UPDATE_PASSWORDT_COMPLETE:
    return OnGetPasswordTComplete(state, action);

  case UPDATE_MISSION_START:
    return OnGetMissionStart(state, action);
  case UPDATE_MISSION_ERROR:
    return OnGetMissionError(state, action);
  case UPDATE_MISSION_COMPLETE:
    return OnGetMissionComplete(state, action);

  case UPDATE_GAMELEVEL_START:
    return OnGetGameLevelStart(state, action);
  case UPDATE_GAMELEVEL_ERROR:
    return OnGetGameLevelError(state, action);
  case UPDATE_GAMELEVEL_COMPLETE:
    return OnGetGameLevelComplete(state, action);

  case DELETE_COURSES_START:
    return EliminatedCoursesStart(state, action);
  case DELETE_COURSES_ERROR:
    return EliminatedCoursesError(state, action);
  case DELETE_COURSES_COMPLETE:
    return EliminatedCoursesComplete(state, action);

  case DELETE_TEACHERS_START:
    return EliminatedTeachersStart(state,action);
  case DELETE_TEACHERS_ERROR:
    return EliminatedTeachersError(state,action);
  case DELETE_TEACHERS_COMPLETE:
    return EliminatedTeachersComplete(state,action);

  case DELETE_MISSIONS_START:
    return EliminatedMissionsStart(state,action);
  case DELETE_MISSIONS_ERROR:
    return EliminatedMissionsError(state,action);
  case DELETE_MISSIONS_COMPLETE:
    return EliminatedMissionsComplete(state,action);


  default:
    return state;
  }
}