import React from 'react';
import { CheckmarkOutline32 } from '@carbon/icons-react';
import { caption01 } from '@carbon/type';
import styled from 'styled-components';
import './index.css';

const Caption = styled.h1(caption01);

const Success = ({ successMessage }) => {
  return (
    <div
      className='bpp-success bpp-mt-32 bpp-flx  bpp-flx-vc'>
      <CheckmarkOutline32 />
      <Caption className='bpp-ml-8'>{successMessage}</Caption>
    </div>
  );
};
export default Success;