import thunkMiddleware from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
require('dotenv').config({ path: '../../.env' });

const keyPersist = process.env.REACT_APP_KEY_PERSIST;

const persistConfig = {
  storage,
  key: keyPersist,
  whitelist: ['user']
};

const configureStore = () => {

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(persistedReducer, composeWithDevTools(
    applyMiddleware(thunkMiddleware)
  ));

  const persistor = persistStore(store, null, () => {
    console.log('restoredState', store.getState());
  });

  return {
    store,
    persistor
  };
};

export default configureStore;
