/* eslint-disable max-len */
import React , {  useState } from 'react';
import 'carbon-components/css/carbon-components.min.css';
import ReactDOM from 'react-dom';

import {
  Button,
  ComposedModal,
  ModalHeader,
  ModalBody
} from 'carbon-components-react';


import TeacherAdd from './TeacherAdd';


const ModalTeacherAdd = () => {
 
  const ModalStateManager = ({
    renderLauncher: LauncherContent,
    children: ModalContent
  }) => {
    const [open, setOpen] = useState(false);
    
    return (
      <>
        {!ModalContent || typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <ModalContent open={open} setOpen={setOpen} />,
            document.body
          )}
        {LauncherContent && <LauncherContent open={open} setOpen={setOpen} />}
      </>
    );
  };
  
  return (
    
    <ModalStateManager
      renderLauncher={({ setOpen }) => (
        <Button 
          onClick={() => {
            setOpen(true);   
          }}
          size="small"
          kind="primary"
        >
          {__('Add new teacher')}
        </Button>
      )}>
      {({ open, setOpen }) => (
        <ComposedModal
          
          open={open}
          onClose={() => 
          {
            setOpen(false);
                        
          }
          }>
          <ModalHeader>
            Registrar profesor
          </ModalHeader>
          <ModalBody>
            <TeacherAdd/> 
          </ModalBody>
            
     
        </ComposedModal>
      )}
    </ModalStateManager>
    
  );
};


export default ModalTeacherAdd;