import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DoNot_02 as DoNot02 } from '@carbon/pictograms-react';
import {
  Loading
} from 'carbon-components-react';
import { parseError } from './../../../../../utils';
import { thunks as teacherThunks } from '../../../../../redux/actions/thunks/teacher';
import Error from '../../../../../shared/error';
import CommonDataTable from '../shared/CommonDataTable';

import moment from 'moment';
import './index.css';

const convertSecsToHrsMinsSecs = (secs) => {
  moment().startOf('day').seconds(secs).format('H:mm:ss');
};

const headersA = [
  {
    key: 'name',
    header: 'Nombre'
  },
  {
    key: 'minimun',
    header: 'Tiempo mínimo'
  },
  {
    key: 'mean',
    header: 'Tiempo promedio'
  },
  {
    key: 'maximum',
    header: 'Tiempo máximo'
  }
];

const parseData = (data) => {
  const newData = data.map((mission) => {
    return {
      name: mission.name,
      minimun: convertSecsToHrsMinsSecs(mission.minTime),
      mean: convertSecsToHrsMinsSecs(mission.averageTime),
      maximum: convertSecsToHrsMinsSecs(mission.maxTime)
    };
  });

  return newData;
};


const MissionsByTime = (props) => {
  const {
    missionsT, getMissionsT, courseId,
    user
  } = props;
  const {
    payload: missionsPayload,
    loading: missionsLoading,
    error: missionsError
  } = missionsT || {};
  const {
    id: teacherId,
    token: apiToken
  } = user || {};
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    getMissionsT(
      {
        teacherId,
        courseId,
        by: 'finishTime'
      },
      {
        apiToken
      }
    );
  }, [getMissionsT]);

  useEffect(() => {
    if (missionsPayload) {
      setHeaders(headersA);
      setRows(parseData(missionsPayload));
    }
  }, [missionsPayload]);

  return (
    <div className="bpp-student-mission bpp-flx-c bpp-flx-clmn">
      {
        !missionsLoading &&
        missionsError &&
        <Error errorMessage={__(parseError(missionsError))} />
      }
      {
        missionsLoading &&
        !missionsError &&
        <div className="bpp-flx-c">
          <Loading
            description="Missions are loading"
            withOverlay={false}
          />
        </div>
      }
      {
        !missionsLoading &&
        !missionsError &&
        missionsPayload?.length > 0 &&
        headers?.length > 0 &&
        rows?.length > 0 &&
        <div className='bpp-w-100'>
          <CommonDataTable
            title='Misiones por tiempo de finalización'
            h={headers}
            r={rows}
          />
        </div>
      }
      {
        (
          !missionsLoading &&
          !missionsError &&
          (!missionsPayload || !missionsPayload.length)
        ) &&
        <div className="bpp-flx-c bpp-flx-clmn">
          <DoNot02 />
          <p>{__('No mission were found!')}</p>
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    missionsT: state.teacher.missionsByFinishTime,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMissionsT: teacherThunks.getMissionsT
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(MissionsByTime);