import React, { useState } from 'react';
import Select from 'react-select/creatable';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  Grid,
  Row,
  Column,
  Form,
  Button,
  Loading,
  TextInput,
  ListItem as Item,
  UnorderedList as UList
} from 'carbon-components-react';

import { parseError } from '../../utils';
import { helperText01, label01 } from '@carbon/type';
import { register as registerSchema } from '../../schema';
import { thunks as userThunks } from '../../redux/actions/thunks/user';
import Separator from '../../shared/separator';
import RegisterImg from '../../assets/img/register.png';

import './index.scss';

const options = [
  {
    id: 1,
    name: 'Escuela Superior Politécnica del Litoral'
  },
  {
    id: 2,
    name: 'Massachusetts Institute of Technology'
  },
  {
    id: 3,
    name: 'Universidad San Francisco de Quito'
  }
];

const Register = (props) => {
  let history = useHistory();
  const { register, getUser } = props;
  const {
    formState: { errors },
    control, handleSubmit
  } = useForm({ resolver: yupResolver(registerSchema) });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const onSubmit = data => {
    setError();
    setLoading(true);
    return register(data)
      .then((res) => {
        getUser({ apiToken: res.token })
          .then(() => {
            setLoading(false);
            return history.push('/teacher/app/courses');
          })
          .catch((err) => {
            setLoading(false);
            setError(err);
            console.log('ERROR:', err);
          });
      })
      .catch(err => {
        setLoading(false);
        setError(err);
        console.log('ERROR:', err);
      });
  };
  const capitalize = (sentence) => {
    return sentence.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  };
  return (
    <Grid className='shy-register'>
      <Row>
        <Column sm={0} md={0} lg={6} xlg={6}>
          <img className='bpp-register-img' src={RegisterImg} />
        </Column>
        <Column sm={12} md={12} lg={6} xlg={6}>
          <div className='bpp-register-title bpp-mt-32'>
            <h3 className='bpp-register-divisor'>{__('Register')}</h3>
          </div>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            {
              error &&
              <div className='bpp-form-error bpp-mv-16'>
                {__(parseError(error))}
              </div>
            }
            <div className='bpp-mt-16'>
              <Controller
                name='email'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextInput
                    id='email'
                    type='text'
                    labelText={__('Email')}
                    placeholder='example@email.com'
                    helperText={__('* Required')}
                    invalid={!!errors.email}
                    invalidText={__(errors.email?.message)}
                    {...field}
                  />
                )} />
            </div>
            <div className='bpp-flx bpp-mt-16'>
              <Controller
                name='firstname'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <div className='bpp-w-100'>
                    <TextInput
                      id='firstname'
                      type='text'
                      labelText={__('Firstname')}
                      placeholder=''
                      invalid={!!errors.firstname}
                      helperText={__('* Required')}
                      invalidText={__(errors.firstname?.message)}
                      {...field}
                    />
                  </div>
                )} />
              <Controller
                name='lastname'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <div className='bpp-ml-16 bpp-w-100'>
                    <TextInput
                      id='lastname'
                      type='text'
                      labelText={__('Lastname')}
                      placeholder=''
                      invalid={!!errors.lastname}
                      helperText={__('* Required')}
                      invalidText={__(errors.lastname?.message)}
                      {...field}
                    />
                  </div>
                )} />
            </div>
            <div className='bpp-mt-16'>
              <Controller
                name='phone'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextInput
                    id='phone'
                    type='tel'
                    labelText={__('Phone')}
                    placeholder=''
                    invalid={!!errors.phone}
                    helperText={__('* Required')}
                    invalidText={__(errors.phone?.message)}
                    {...field}
                  />
                )} />
            </div>
            <div className='bpp-mt-16'>
              <Controller
                name='password'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextInput
                    id='password'
                    type='password'
                    labelText={__('Password')}
                    placeholder=''
                    invalid={!!errors.password}
                    helperText={__('* Required')}
                    invalidText={__(errors.password?.message)}
                    {...field}
                  />
                )} />
            </div>
            <div className='bpp-flx bpp-mt-8'>
              <UList className='bpp-pl-16'>
                <Item style={helperText01}>
                  {
                    __('8 character minimum')
                  }
                </Item>
                <Item style={helperText01}>
                  {
                    __('One lowercase character')
                  }
                </Item>
              </UList>
              <UList className='bpp-pl-32'>
                <Item style={helperText01}>
                  {
                    __('One upper character')
                  }
                </Item>
                <Item style={helperText01}>
                  {
                    __('One number minimum')
                  }
                </Item>
              </UList>
            </div>
            <div className='bpp-mt-16'>
              <Controller
                name='confirmPassword'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextInput
                    id='confirmPassword'
                    type='password'
                    labelText={__('Confirm Password')}
                    placeholder=''
                    invalid={!!errors.confirmPassword}
                    helperText={__('* Required')}
                    invalidText={__(errors.confirmPassword?.message)}
                    {...field}
                  />
                )} />
            </div>
            <div className='bpp-mt-16'>
              <div className='bpp-label bpp-mb-8' style={label01}>
                {
                  __('Institution')
                }
              </div>
              <Controller
                name='institution'
                control={control}
                defaultValue=''
                render={
                  ({ field }) => {
                    const {
                      onChange, value, name, ref
                    } = field;
                    return <Select
                      name={name}
                      inputRef={ref}
                      isClearable
                      options={options}
                      classNamePrefix={
                        errors.institution ?
                          'bpp-select-error' : 'bpp-select'
                      }
                      getNewOptionData={(inputValue, optionLabel) => ({
                        id: optionLabel,
                        name: capitalize(inputValue),
                        __isNew__: true
                      })}
                      placeholder={__('Choose an institution')}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.name}
                      onChange={val => onChange(val?.name)}
                      value={options.find(c => c?.name === value)} />;
                  }
                }
              />
              <span style={{ ...helperText01, color: '#525252' }}>
                {__('* Required')}
              </span>
              {
                !!errors.institution &&
                <div className='form-error-text'>
                  {__(errors.institution?.message)}
                </div>
              }
            </div>
            <div className='bpp-mt-16'>
              <Controller
                name='captchaToken'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <ReCAPTCHA
                    id='captchaToken'
                    sitekey="6LcwzF0eAAAAAGNa3gXuFiCl3_kzR07Yd81K02e5"
                    {...field}
                  /> 
                )}
              />
            </div>
            <Button
              type='submit'
              className='bpp-mt-16'>
              {
                __('Create account')
              }
              {
                loading &&
                <Loading
                  className='bpp-ml-64'
                  description="Sending email" small withOverlay={false} />
              }
            </Button>
            <Separator className='bpp-login-sep' />
            <div className='bpp-flx-vc bpp-mt-16 '>
              <p className='bpp-register-label'>{__('Already have an account?')}</p>
              <Link className="bpp-register-button bpp-ml-8 bpp-register-label" to='/teacher/login'>{__('Log in')}</Link>
            </div>
          </Form>
        </Column>
      </Row>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  register: userThunks.register,
  getUser: userThunks.get
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Register);
