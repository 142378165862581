import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import {
  Grid,
  Row,
  Column,
  Form,
  Button,
  Loading,
  TextInput,
  ListItem as Item,
  UnorderedList as UList
} from 'carbon-components-react';

import { parseError } from '../../utils';
import { helperText01 } from '@carbon/type';
import { resetPassword as resetPasswordSchema } from '../../schema';
import { thunks as userThunks } from '../../redux/actions/thunks/user';
import { CheckmarkOutline32 } from '@carbon/icons-react';


const ResetPassword = (props) => {
  const { resetPassword, validateExpiry } = props;
  const history = useHistory();
  const { userId, resetString } = useParams();
  const {
    formState: { errors },
    control, handleSubmit
  } = useForm({ resolver: yupResolver(resetPasswordSchema) });
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [succeded, setSucceded] = useState(false);
  const [valid, setValid] = useState(false);
  const onSubmit = data => {
    setError();
    setLoading(true);
    setLoading(false);
    resetPassword({
      userId: userId,
      resetString: resetString,
      password: data.password
    })
      .then(() => {
        setLoading(false);
        setSucceded(true);
      })
      .catch(err => {
        console.log('ERROR:', err);
        setLoading(false);
        setError(parseError(err));
        setValid(false);
      });
  };

  useEffect(() => {
    // Check if the expiry is valid
    setLoadingPage(true);
    validateExpiry({
      userId,
      resetString
    }).then(() => {
      console.log('validateExpiry', 'valid');
      setValid(true);
      setLoadingPage(false);
    }).catch(err => {
      setValid(false);
      console.log('validateExpiry', err);
      setError(err);
      setLoadingPage(false);
    });
  }, []);

  return (
    <Grid className='shy-register'>
      <Row>
        {
          loadingPage ?
            <div>Loading</div>
            :
            <>
              {
                valid ?
                  <Column sm={12} md={12} lg={12} xlg={12}>
                    <div className='bpp-register-title bpp-mt-32'>
                      <h3 className='bpp-register-divisor'>{__('Change password')}</h3>
                    </div>
                    {
                      succeded ? (
                        <div className='bx--form bpp-mt-32'>
                          <div className="bpp-flx-vc">
                            <h6 className='bpp-flx-vc'>
                              <CheckmarkOutline32 className='bpp-mr-8' />
                              {
                                __('Password successfully changed')
                              }
                            </h6>
                          </div>
                          <Button type='button' className='bpp-mt-32' onClick={() => history.push('/teacher/login')}>
                            {__('Go back to Log in')}
                          </Button>
                        </div>
                      ) : (
                        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                          {
                            error &&
                            <div className='bpp-form-error bpp-mv-16'>
                              {__(parseError(error))}
                            </div>
                          }
                          <div className='bpp-mt-16'>
                            <Controller
                              name='password'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextInput
                                  id='password'
                                  type='password'
                                  labelText={__('Password')}
                                  placeholder=''
                                  invalid={!!errors.password}
                                  helperText={__('* Required')}
                                  invalidText={__(errors.password?.message)}
                                  {...field}
                                />
                              )} />
                          </div>
                          <div className='bpp-flx bpp-mt-8'>
                            <UList className='bpp-pl-16'>
                              <Item style={helperText01}>
                                {
                                  __('8 character minimum')
                                }
                              </Item>
                              <Item style={helperText01}>
                                {
                                  __('One lowercase character')
                                }
                              </Item>
                            </UList>
                            <UList className='bpp-pl-32'>
                              <Item style={helperText01}>
                                {
                                  __('One upper character')
                                }
                              </Item>
                              <Item style={helperText01}>
                                {
                                  __('One number minimum')
                                }
                              </Item>
                            </UList>
                          </div>
                          <div className='bpp-mt-16'>
                            <Controller
                              name='confirmPassword'
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextInput
                                  id='confirmPassword'
                                  type='password'
                                  labelText={__('Confirm Password')}
                                  placeholder=''
                                  invalid={!!errors.confirmPassword}
                                  helperText={__('* Required')}
                                  invalidText={
                                    __(errors.confirmPassword?.message)
                                  }
                                  {...field}
                                />
                              )} />
                          </div>
                          <Button
                            type='submit'
                            className='bpp-mt-16'>
                            {
                              __('Change password')
                            }
                            {
                              loading &&
                              <Loading
                                className='bpp-ml-64'
                                description="Sending email" small withOverlay={false} />
                            }
                          </Button>
                        </Form>
                      )
                    }
                  </Column>
                  :
                  <div>
                    Link has already expired
                  </div>
              }
            </>
        }
      </Row>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  resetPassword: userThunks.resetPassword,
  validateExpiry: userThunks.validateExpiry
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
