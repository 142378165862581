import React from 'react';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  Content
} from 'carbon-components-react/lib/components/UIShell';
import miBosquelogo from './../../assets/landing/logo192px.png';
import './index.scss';
import { Link } from 'react-router-dom';

const HomeHeader = (props) => {
  
  return <>
    <Header aria-label="MI BOSQUE 3D">
      <HeaderName element={Link} to={props.where === 'teacher'? '/teacher': '/admin'} prefix=''>
        <div className='bpp-flx bpp-flx-vc'>
          <img className="logo" src={miBosquelogo} alt="logo" />
          {
            'MI BOSQUE 3D'
          }
        </div>
      </HeaderName>
      <HeaderNavigation aria-label="MI BOSQUE 3D">
        <HeaderMenuItem href="https://www.bosqueprotector.espol.edu.ec/videojuego-mi-bosque/" target={'_blank'}>{'Conoce el videojuego'}</HeaderMenuItem>
      </HeaderNavigation>
    </Header>
    <Content id={props.name}>{props.children}</Content>
  </>;
};
export default HomeHeader;
