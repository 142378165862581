import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { forgot as forgotPasswordSchema } from '../../schema';
import { thunks as userThunks } from '../../redux/actions/thunks/user';
import { parseError } from '../../utils';
import Error from '../../shared/error';
import {
  Grid,
  Row,
  Column,
  Form,
  Button,
  Loading,
  TextInput,
  Tooltip
} from 'carbon-components-react';
import { CheckmarkOutline32 } from '@carbon/icons-react';

const ForgotPassword = (props) => {
  const { forgotPassword } = props;
  const {
    formState: { errors },
    control, handleSubmit
  } = useForm({ resolver: yupResolver(forgotPasswordSchema) });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState();

  const history = useHistory();
  const onSubmit = (data) => {
    setError();
    setLoading(true);
    forgotPassword({
      email: data.email
    })
      .then(() => {
        setLoading(false);
        setEmail(data.email);
        setSubmitted(true);
      })
      .catch(err => {
        console.log('ERROR:', err);
        setLoading(false);
        setError(parseError(err));
      });
  };
  return (
    <Grid className="bpp-login">
      <Row>
        <Column sm={12} md={12} lg={12} xlg={12}>
          <>
            <div className='bpp-mt-32 bx--form'>
              <h3 className='bpp-login-divisor'>
                {
                  __('Forgot password')
                }
              </h3>
            </div>
            {
              submitted && email ?
                <div className='bx--form bpp-mt-32'>
                  <div className="bpp-flx-vc">
                    <h6 className='bpp-flx-vc'>
                      <CheckmarkOutline32 className='bpp-mr-8' />
                      {
                        __('We just sent you a link to reset your password to the following email ') + email
                      }
                    </h6>
                  </div>
                  <Button type='button' className='bpp-mt-32' onClick={() => history.push('/teacher/login')}>
                    {__('Go back to Log in')}
                  </Button>
                </div>
                :
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                  {
                    error &&
                    <Error errorMessage={__(parseError(error))} />
                  }
                  <div className='bpp-mt-32'>
                    <Tooltip direction='bottom' tabIndex={0} triggerText={__('Email')}>
                      <p>
                        {
                          __('Email')
                        }
                      </p>
                    </Tooltip>
                    <Controller
                      name='email'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <TextInput
                          className='bpp-mt-8'
                          id='email'
                          labelText=''
                          type='text'
                          placeholder='ejemplo@email.com'
                          invalid={!!errors.email}
                          helperText={__('* Required')}
                          invalidText={__(errors.email?.message)}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <Button type='submit' className='bpp-mt-32'>
                    {__('Send link to reset password')}
                    {loading && (
                      <Loading
                        className='bpp-ml-64'
                        description='Sending auth'
                        small
                        withOverlay={false}
                      />
                    )}
                  </Button>
                </Form>
            }
          </>
        </Column>
      </Row>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPassword: userThunks.forgotPassword
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
