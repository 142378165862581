const URL_SERVER = process.env.REACT_APP_URL_SERVER;
const keyPersist = process.env.REACT_APP_KEY_PERSIST;
export async function getCourses(props, { apiToken }) {
  const { teacherId, page = 1, limit = 8 } = props;
  const url = new URL(URL_SERVER);

  const searchParams = new URLSearchParams();
  searchParams.append('page', page);
  searchParams.append('limit', limit);

  url.search = searchParams.toString();
  url.pathname = `/api/v1/teacher/${teacherId}/course`;
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function createCourse(props, { apiToken }) {
  const url = new URL(URL_SERVER);
  const {
    teacherId
  } = props;

  url.pathname = `/api/v1/teacher/${teacherId}/course`;
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    },
    body: JSON.stringify(props)
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function getStudents(props, { apiToken }) {
  const { teacherId, courseId } = props;
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/teacher/${teacherId}/course/${courseId}/students`;
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function getOverview(props, { apiToken }) {
  const { teacherId, courseId } = props;
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/teacher/${teacherId}/course/${courseId}/overview`;
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function getAchievements(props, { apiToken }) {
  const { teacherId, courseId } = props;
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/teacher/${teacherId}/course/${courseId}/achievements`;

  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function getMissions(props, { apiToken }) {
  const { teacherId, courseId, by } = props;
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/teacher/${teacherId}/course/${courseId}/missions`;
  url.searchParams.append('by', by);

  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function updateCourse(props, { apiToken }) {
  const { teacherId, courseId, courseName, courseDescription } = props;
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/teacher/${teacherId}/course/${courseId}/`;

  let response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ name: courseName, description: courseDescription })
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}
export async function getCourse(props, { apiToken }) {
  const { teacherId, courseId } = props;
  const url = new URL(URL_SERVER);

  url.pathname = `/api/v1/teacher/${teacherId}/course/${courseId}`;
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  if (payload?.[0]?.length > 0) {
    return payload[0];
  }
  return payload;
}