export default {
  'Course *COURSENAME*': 'Curso *COURSENAME*',
  'Course name': 'Nombre de curso',
  'Description': 'Descripción',
  'Create course': 'Crear curso',
  'Overview': 'Vista general',
  'Missions': 'Misiones',
  'Game time (m)': 'Tiempo de juego (m)',
  'Total students': 'Total de estudiantes',
  'Popular level': 'Nivel popular',
  'Popular mission': 'Misión popular',
  'Details': 'Detalles',
  'Achievements': 'Premios',
  'Missions (time)': 'Misiones (tiempo)',
  'Missions (group)': 'Misiones (grupo)',
  'Completed': 'Completado',
  '*STUDENTS* completed': '*STUDENTS* completado',
  '*STUDENTS* no completed': '*STUDENTS* no completado',
  'No achievements were found!': 'No se encontraron premios!',
  'Students': 'Estudiantes',
  'Mission': 'Misiones',
  'Missions completed by student gender': 'Misiones completadas por género de estudiante',
  'No mission were found!': 'No se encontraron misiones!',
  'Time (minutes)': 'Tiempo (minutos)',
  'Missions vs finish average time': 'Misiones vs tiempo de finalización promedio',
  'Open details': 'Abrir detalles',
  'Sign up free': 'Registrate gratis',
  'Log in': 'Iniciar sesión',
  'Password': 'Contraseña',
  'Email': 'Correo',
  'Firstname': 'Nombres',
  'Lastname': 'Apellidos',
  'Phone': 'Teléfono',
  'Confirm Password': 'Confirmar contraseña',
  '8 character minimum': 'Mínimo 8 caracteres',
  'One lowercase character': 'Al menos 1 caracter en minúsculas',
  'One upper character': 'Al menos 1 caracter en mayúsculas',
  'One number minimum': 'Al menos 1 número',
  'Institution': 'Institución',
  'Choose an institution': 'Elegir una institución',
  'Create account': 'Crear cuenta',
  'Resume': 'Resumen',
  'Course ': 'Curso',
  'Current mission': 'Misión actual',
  'Start game date': 'Fecha de inicio de juego',
  'Last game date': 'Fecha de última sesión',
  'Student details': 'Detalles de estudiante',
  'Average': 'Promedio',
  'Student': 'Estudiante',
  'Time': 'Tiempo',
  'Missions time vs average student': 'Tiempo de misión vs tiempo promedio de estudiantes',
  'Create a new course': 'Crear curso nuevo',
  'Previous page': 'Página anterior',
  'Next page': 'Siguiente página',
  'Items per page': 'Elementos por página',
  'Courses': 'Cursos',
  'Not courses created yet': 'No tiene cursos registrados',
  'This is very lonely around here...you could create a course with the button above': 'Está muy solo por aquí... da clic en el botón de abajo para crear un curso',
  'Sign In': 'Iniciar sesión',
  'Go to course': 'Ir al curso',
  'Delete course': 'Borrar curso',
  'copy to clipboard': 'copiar al portapapeles',
  'Not results found': 'No se encontraron resultados',
  'No page avaliable': 'No se encontró página',
  'Sign out': 'Cerrar sesión',
  'Account': 'Cuenta',
  'Dashboard': 'Tablero',
  'Settings': 'Configuraciones',
  'user or password invalid': 'Usuario o contraseña inválida',
  'User not found': 'Usuario no encontrado',
  'Class details': 'Detalles de curso',
  'successful process': 'proceso exitoso',
  'Course description': 'Descripción de curso',
  '*USERNAME*, your account it\'s ready to create courses': '*USERNAME*, tu cuenta está lista para crear cursos',
  'Actions': 'Acciones',
  'Current level': 'Nivel actual',
  'Play time (minutes)': 'Tiempo de juego (minutos)',
  'Grades': 'Calificación',
  'Gender': 'Género',
  'Age': 'Edad',
  'Name': 'Nombre',
  'ID': 'ID',
  'Course options': 'Opciones de curso',
  'Student list': 'Lista de estudiantes',
  'Class code': 'Código de curso',
  'No students were found!': 'No se encontraron estudiantes!',
  'Create an account': 'Crear cuenta',
  'Don\'t have an account?': '¿No tienes una cuenta?',
  'Already have an account?': '¿Ya tienes una cuenta?',
  'Student resume': 'Resumen de estudiante',
  'Invalid email': 'Correo inválido',
  'The email is required': 'El correo es requerido',
  'The firstname must be a text': 'El nombre debe ser un texto',
  'Your first only must include characters': 'Tu nombre solo debe contener caracteres',
  'The firstname is required': 'El nombre es requerido',
  'The lastname must be a text': 'El apellido debe ser un texto',
  'The lastname is required': 'El apellido es requerido',
  'Invalid phone number': 'Número de teléfono inválido',
  'The phone number is required': 'El número de teléfono es requerido',
  'Must include at least one number': 'Debe incluir al menos un número',
  'Must include at least upper letter': 'Debe incluir al menos una letra en mayúscula',
  'Must include at least lower letter': 'Debe incluir al menos una letra en minúscula',
  'Password is too short, should be 8 chars minimum': 'La contraseña es demasiado corta, debe tener 8 caracteres mínimo',
  'The password is required': 'La contraseña es requerida',
  'Password must match': 'Las contraseñas deben coincidir',
  'The institution is required': 'La institución es requerida',
  'Course name is required': 'El nombre del curso es requerido',
  'Description is required': 'La descripción es requerida',
  '* Required': '* Requerido',
  'Register': 'Registro',
  '*MIN*–*MAX* items': '*MIN*–*MAX* elementos',
  'Maximum': 'Máximo',
  'Upper quartile': 'Tercer cuartil',
  'Median': 'Mediana',
  'Lower quartile': 'Primer cuartil',
  'Minimum': 'Mínimo',
  'Password invalid': 'Contraseña incorrecta',
  'User not found!': 'Usuario no encontrado',
  'Acceso Denegado': 'Acceso denegado',
  'Invalid token': 'Token inválido',
  'Email is already exists!': 'Correo ya registrado!',
  'Bad request': 'Error de servidor',
  'Unauthorized': 'No autorizado',
  'Teacher not found!': 'Profesor no encontrado',
  'Not found!': 'Recurso no encontrado',
  'Course or Teacher Not found': 'Curso o profesor no encontrado',
  'Not found': 'Recurso no encontrado',
  'Course does not exist': 'Curso no existente',
  'Course code must be 4 digits long.': 'Código de curso debe tener 4 dígitos',
  'Age value is required.': 'Edad es un campo requerido',
  'Age value out of range (must be between 0 and 100).': 'Edad fuera de rango',
  'Prize not found': 'Premio no encontrado',
  'Mission not found': 'Misión no encontrada',
  'Something went wrong, please try again': 'Algo salió mal, inténtelo de nuevo.',
  'courses': 'cursos',
  'course': 'curso',
  'student': 'estudiante',
  'Teachers': 'Profesores',
  'Forgotten password?': '¿Olvidaste tu contraseña?',
  'Reset it': 'Restablecerla',
  'List of courses': 'Lista de cursos',
  'Add new course': 'Agregar nuevo curso',
  'List of teachers': 'Lista de profesores',
  'Add new teacher': 'Agregar nuevo profesor',
  'List of missions': 'Lista de misiones',
  'Add new mission': 'Agregar nueva mision',
  'Game levels': 'Niveles de juego',
  'List of game levels': 'Lista de niveles de juego',
  'Add new game level': 'Agregar nuevo nivel de juego',
  'Change password': 'Cambiar contraseña',
  'Edit mission': 'Editar mision',
  'Edit game level': 'Editar nivel de juego',
  'Code': 'Codigo',
  'Date': 'Fecha',
  'Score': 'Puntaje',
  'Full_Name': 'Nombre_Completo',
  'Email_Teacher': 'Correo_Profesor',
  'Teacher_Name': 'Nombre_Profesor',
  'Delete': 'Eliminar',
  'Cancel': 'Cancelar',
  'Download': 'Descargar',
  'Page Number': 'Numero de pagina',
  'Create new course': 'Crear nuevo curso',
  'Email of teacher': 'Correo del profesor',
  'Create new teacher': 'Crear nuevo profesor',
  'Do you want to enter a password?': '¿Desea usted ingresar una contraseña?',
  'Yes': 'Si',
  'No': 'No',
  'Change of password': 'Cambio de contraseña',
  'New password': 'Nueva contraseña',
  'Confirm new password': 'Confirmar nueva contraseña',
  'Create mission': 'Crear mision',
  'Register mission': 'Registrar mision',
  'Update mission': 'Actualizar mision',
  'Register game level': 'Registrar nivel de juego',
  'Create game level': 'Crear nivel de juego',
  'Update game level': 'Actualizar nivel de juego',
  'The email of teacher is required': 'El correo del profesor es requerido',
  'Mission name is required': 'El nombre de la mision es requerido',
  'Score is required': 'El puntaje es requerido',
  'GameId is required': 'GameId es requerido',
  'Game level name is required': 'El nombre del nivel de juego es requerido'
};