import React, { useEffect, useState } from 'react';
import SVGNoCourses from '../../assets/user/teacher/NoCourses.js';
import {
  Button,
  Loading,
  Pagination
} from 'carbon-components-react';
import Courses from './courses';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { thunks as teacherThunks } from '../../redux/actions/thunks/teacher';
import { useHistory } from 'react-router-dom';

import Error from '../../shared/error';
import { parseError } from './../../utils';
import './index.scss';

const TeacherCourses = (props) => {
  const {
    user,
    courses, getCourses
  } = props;
  const {
    firstname: userName,
    token: userToken,
    id: userId,
    error: userError,
    loading: userLoading
  } = user || {};
  const {
    payload: coursePayload,
    loading: courseLoading,
    error: coursesError,
    total: coursesTotal
  } = courses || {};
  const [limit] = useState(8);
  const [page, setPage] = useState(1);
  const history = useHistory();

  useEffect(() => {
    getCourses(
      {
        teacherId: userId,
        limit,
        page
      },
      {
        apiToken: userToken
      }
    ).catch((error) => {
      console.log('ERROR:', error);
    });
  }, [page]);

  return (
    <>
      <div className='bpp-mt-16 bpp-teacher-body'>
        {
          !userLoading &&
          userError &&
          <Error errorMessage={__(parseError(userError))} />
        }
        {
          !courseLoading &&
          coursesError &&
          <Error errorMessage={__(parseError(coursesError))} />
        }
        {
          (courseLoading ||
            userLoading) &&
          !userError &&
          !coursesError &&
          <Loading
            active={courseLoading || userLoading}
            withOverlay={true} />
        }
        {
          !userLoading &&
          !userError &&
          !courseLoading &&
          !coursesError &&
          coursePayload?.length > 0 &&
          <Courses courses={coursePayload} />
        }
        {
          !userLoading &&
          !userError &&
          !courseLoading &&
          !coursesError &&
          coursePayload?.length == 0 &&
          <>
            <h3 className='bpp-txt-center bpp-mt-32'>
              {__('*USERNAME*, your account it\'s ready to create courses').replace('*USERNAME*', userName)}
            </h3>
            <div className='bpp-flx-c bpp-flx-clmn bpp-mt-32'>
              <SVGNoCourses />
              <Button 
                className='bpp-mt-32'
                onClick={ () => {
                  history.push('/teacher/app/course/create');
                }}>
                {__('Create a new course')}
              </Button>
            </div>
          </>
        }
      </div>
      {
        !userLoading &&
        !userError &&
        !courseLoading &&
        !coursesError &&
        coursePayload?.length > 0 &&
        coursesTotal &&
        <Pagination
          className='bpp-pagination'
          onChange={e => setPage(e.page)}
          backwardText={__('Previous page')}
          forwardText={__('Next page')}
          itemsPerPageText={__('Items per page')}
          page={page}
          pageNumberText={__('Page Number')}
          pageSize={limit}
          pageSizes={[8]}
          totalItems={coursesTotal}
          itemText={(min, max) => __('MIN–MAX items')
            .replace('MIN', min).replace('MAX', max)}
          pageText={pag => {return `sss ${ pag }`;}}            
        />
      }
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    courses: state.teacher.courses
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCourses: teacherThunks.getCourses
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TeacherCourses);
