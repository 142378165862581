import * as React from 'react';

const SVGNoCourses = (props) => (
  <svg
    xmlns='https://www.w3.org/2000/svg'
    data-name='Layer 1'
    width={500}
    height={488.063}
    viewBox='0 0 500 488.063'
    {...props}
  >
    <path
      d='M317.421 109.764h-134.728a11.594 11.594 0 0 0 -11.581 11.581v299.435l-1.544 0.471 -33.051 10.121a6.182 6.182 0 0 1 -7.713 -4.1l-98.311 -321.129a6.179 6.179 0 0 1 4.1 -7.713l50.931 -15.595 147.652 -45.195 50.931 -15.595a6.169 6.169 0 0 1 7.713 4.092l25.13 82.083Z'
      fill='#f2f2f2'
    />
    <path
      d='m346.82 108.22 -30.287 -98.929a13.12 13.12 0 0 0 -16.39 -8.709l-71.607 21.918 -147.645 45.203 -71.607 21.926a13.136 13.136 0 0 0 -8.709 16.39l103.515 338.099a13.146 13.146 0 0 0 12.553 9.288 12.963 12.963 0 0 0 3.837 -0.579l49.086 -15.024 1.544 -0.479v-1.614l-1.544 0.471 -49.542 15.171a11.592 11.592 0 0 1 -14.46 -7.682L2.058 105.564a11.565 11.565 0 0 1 7.682 -14.46l71.607 -21.926 147.645 -45.195 71.607 -21.926a11.701 11.701 0 0 1 3.405 -0.51 11.592 11.592 0 0 1 11.056 8.191l30.148 98.481 0.479 1.544h1.606Z'
      fill='#3f3d56'
    />
    <path
      d='M94.714 98.683a6.961 6.961 0 0 1 -6.649 -4.916l-9.944 -32.481a6.948 6.948 0 0 1 4.61 -8.678L218.564 11.023a6.955 6.955 0 0 1 8.678 4.61l9.944 32.481a6.956 6.956 0 0 1 -4.609 8.678l-135.832 41.585a6.93 6.93 0 0 1 -2.03 0.305Z'
      fill='#1e824c'
    />
    <path
      cx={190.154}
      cy={24.955}
      r={20}
      fill='#1e824c'
      d='M162.247 19.266A15.441 15.441 0 0 1 146.806 34.707A15.441 15.441 0 0 1 131.365 19.266A15.441 15.441 0 0 1 162.247 19.266z'
    />
    <path
      cx={190.154}
      cy={24.955}
      r={12.665}
      fill='#fff'
      d='M156.584 19.266A9.778 9.778 0 0 1 146.806 29.044A9.778 9.778 0 0 1 137.028 19.266A9.778 9.778 0 0 1 156.584 19.266z'
    />
    <path
      d='M465.258 449.461h-260.949a6.57 6.57 0 0 1 -6.562 -6.562v-312.676a6.57 6.57 0 0 1 6.562 -6.562h260.949a6.57 6.57 0 0 1 6.562 6.562v312.676a6.57 6.57 0 0 1 -6.562 6.562Z'
      fill='#e6e6e6'
    />
    <path
      d='M345.206 108.22h-162.514a13.143 13.143 0 0 0 -13.125 13.125v314.837l1.544 -0.471v-314.366a11.594 11.594 0 0 1 11.581 -11.581h162.993Zm141.669 0h-304.183a13.143 13.143 0 0 0 -13.125 13.125v353.594a13.143 13.143 0 0 0 13.125 13.125h304.183a13.143 13.143 0 0 0 13.125 -13.125v-353.594a13.143 13.143 0 0 0 -13.125 -13.125Zm11.581 366.718a11.594 11.594 0 0 1 -11.581 11.581h-304.183a11.594 11.594 0 0 1 -11.581 -11.581v-353.594a11.594 11.594 0 0 1 11.581 -11.581h304.183a11.594 11.594 0 0 1 11.581 11.581Z'
      fill='#3f3d56'
    />
    <path
      d='M405.811 142.189h-142.055a6.956 6.956 0 0 1 -6.948 -6.948v-33.97a6.956 6.956 0 0 1 6.948 -6.948h142.055a6.956 6.956 0 0 1 6.948 6.948v33.97a6.956 6.956 0 0 1 -6.948 6.948Z'
      fill='#1e824c'
    />
    <path
      cx={433.636}
      cy={105.174}
      r={20}
      fill='#1e824c'
      d='M350.225 81.198A15.441 15.441 0 0 1 334.784 96.639A15.441 15.441 0 0 1 319.343 81.198A15.441 15.441 0 0 1 350.225 81.198z'
    />
    <path
      cx={433.636}
      cy={105.174}
      r={12.182}
      fill='#fff'
      d='M344.189 81.198A9.405 9.405 0 0 1 334.784 90.603A9.405 9.405 0 0 1 325.379 81.198A9.405 9.405 0 0 1 344.189 81.198z'
    />
  </svg>
);

export default SVGNoCourses;