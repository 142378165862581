import React, { useState } from 'react';
import { Switcher24, Close24 } from '@carbon/icons-react';
import {
  Header,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
  Content
} from 'carbon-components-react/lib/components/UIShell';
import {
  Breadcrumb,
  BreadcrumbItem
} from 'carbon-components-react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import miBosquelogo from './../../assets/landing/logo192px.png';
import './appLayout.scss';
import AdminSwitcher from './AdminSwitcher';
import TeacherSwitcher from './TeacherSwitcher';

const AppLayout = (props) => {
  const [openedPanel, setPanel] = useState('');
  const location = useLocation();
  const { breadCrumbs, role } = useSelector(state => state.user);
  
  return <>
    <Header aria-label="MI BOSQUE 3D">
      <HeaderName
        element={Link}
        to= {location.pathname.includes('/admin/app') ?'/admin/app/courses': '/teacher/app/courses'}
        prefix=''
      >
        <div className='bpp-flx bpp-flx-vc'>
          <img className="logo" src={miBosquelogo} alt="logo" />
          {
            'MI BOSQUE 3D'
          }
        </div>
      </HeaderName>
      <HeaderGlobalBar>
        <HeaderGlobalAction
          aria-label="Account"
          isActive
          tooltipAlignment="end"
          onClick={() => {
            if (openedPanel === 'Account Panel') {
              setPanel('');
            } else {
              setPanel('Account Panel');
            }
          }}>
          {
            openedPanel === 'Account Panel' ?
              <Close24 onClick={() => { setPanel(''); }} /> :
              <Switcher24 />
          }
        </HeaderGlobalAction>
        <HeaderPanel aria-label="Account Panel" expanded={openedPanel === 'Account Panel'}>
          {
            location.pathname.includes('/admin/app/') ?
              <AdminSwitcher />
              :
              <TeacherSwitcher />
          }
        </HeaderPanel>
      </HeaderGlobalBar>
    </Header>
    <Content className="bpp-full-height">
      { 
        breadCrumbs && role === 'Teacher' &&  !location.pathname.includes('/admin/app/') &&
          <Breadcrumb className="bpp-mb-32">
            {
              Object.keys(breadCrumbs)
                .filter(k => breadCrumbs[k].href)
                .map(k => {
                  const val = breadCrumbs[k];
                  return (
                    <BreadcrumbItem key={val.name}>
                      {
                        val.name == 'courses' ?
                          <Link to={`/teacher/app${val.href}`}>{`${__(val.name)} `}</Link> :
                          <Link to={`/teacher/app${val.href}`}>{`${__(val.name)}: 
                          ${val.id}`}</Link>
                      }

                    </BreadcrumbItem>
                  );
                })
            }
          </Breadcrumb>
      }
      {props.children}
    </Content>
  </>;
};
export default AppLayout;
