import React from 'react';
import SVGNotFound from '../../assets/shared/NoFoundPage.js';
import './index.css';

const NoMatch = () => (
  <>
    <div className="bpp-flx-c">
      <SVGNotFound />
    </div>
    <p className="bpp-txt-center">{__('No page avaliable')}</p>
  </>
);

export default NoMatch;