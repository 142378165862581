import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Grid,
  Row,
  Column,
  ContentSwitcher,
  Switch,
  Tile,
  Loading
} from 'carbon-components-react';
import { heading02 } from '@carbon/type';
import styled from 'styled-components';
import Achievements from './achievements';
import Missions from './missions';
import { useParams } from 'react-router-dom';
import { Hills, Time, PlayerFlow } from '@carbon/pictograms-react';
import { thunks as studentThunks } from '../../redux/actions/thunks/student';
import { thunks as userThunks } from '../../redux/actions/thunks/user';
import Error from './../../shared/error';
import { parseError } from './../../utils';
import './index.css';

const Title = styled.h1(heading02);

const Student = (props) => {
  const {
    resume, getResume,
    overview, getOverview,
    user, updateBreadCrumbs
  } = props;
  const {
    payload: resumePayload,
    loading: resumeLoading,
    error: resumeError
  } = resume || {};
  const {
    payload: overviewPayload,
    loading: overviewLoading,
    error: overviewError
  } = overview || {};
  const {
    token: apiToken
  } = user || {};
  const [studentContext, setContext] = useState(0);
  const { courseId, studentId } = useParams();

  useEffect(() => {
    getResume({ 
      courseId,
      studentId 
    },{
      apiToken
    });
    getOverview({ 
      courseId,
      studentId 
    },{
      apiToken
    });
  }, []);

  useEffect(() => {
    updateBreadCrumbs({
      breadCrumb: 'student',
      id: resumePayload?.name || studentId,
      href: `/course/${courseId}/student/${studentId}`
    });
  },[resume]);
  
  return (
    <Grid>
      <Row>
        <Column sm={4} md={8} lg={3} xlg={3}>
          <Tile light={true} className="bpp-mt-16 bpp-student-resume">
            <Title>
              {
                __('Student resume')
              }
            </Title>
            {
              !resumeLoading &&
              resumeError &&
              <Error errorMessage={__(parseError(resumeError))}/>
            }
            {
              resumeLoading &&
              !resumeError &&
              <div className="bpp-flx-c">
                <Loading
                  className="bpp-flx-c"
                  description="Resume is loading"
                  withOverlay={false}
                />
              </div>
            }
            {
              !resumeLoading &&
              !resumeError &&
              resumePayload &&
                <div className='bpp-mt-16'>
                  <div className="bpp-mt-8 bpp-flx-btwn">
                    <strong>
                      {__('Name')}
                    </strong>
                    <div className='bpp-mr-8'>{resumePayload.name}</div>
                  </div>
                  <div className="bpp-mt-8 bpp-flx-btwn">
                    <strong>
                      {__('Course name')}
                    </strong>
                    <div className='bpp-mr-8'>{resumePayload.courseName}</div>
                  </div>
                  <div className="bpp-mt-8 bpp-flx-btwn">
                    <strong>
                      {__('Age')}
                    </strong>
                    <div className='bpp-mr-8'>{resumePayload.age}</div>
                  </div>
                  <div className="bpp-mt-8 bpp-flx-btwn">
                    <strong>
                      {__('Gender')}
                    </strong>
                    <div className='bpp-mr-8'>{resumePayload.gender}</div>
                  </div>
                  <div className="bpp-mt-8 bpp-flx-btwn">
                    <strong>
                      {__('Current level')}
                    </strong>
                    <div className='bpp-mr-8'>{resumePayload.currentLevel}</div>
                  </div>
                  <div className="bpp-mt-8 bpp-flx-btwn">
                    <strong>
                      {__('Current mission')}
                    </strong>
                    <div className='bpp-mr-8'>{resumePayload.currentMission}</div>
                  </div>
                  <div className="bpp-mt-8 bpp-flx-btwn">
                    <strong>
                      {__('Start game date')}
                    </strong>
                    <div className='bpp-mr-8'>{new Date(resumePayload.startGameDate).toLocaleString()}</div>
                  </div>
                  <div className="bpp-mt-8 bpp-flx-btwn">
                    <strong>
                      {__('Last game date')}
                    </strong>
                    <div className='bpp-mr-8'>{new Date(resumePayload.lastGameDate).toLocaleString()}</div>
                  </div>
                </div>
            }
          </Tile>
        </Column>
        <Column sm={4} md={8} lg={9} xlg={9}>
          <Tile light={true} className="bpp-mt-16 bpp-student-overview">
            <Title>{__('Overview')}</Title>
            {
              !overviewLoading &&
              overviewError &&
              <Error errorMessage={__(parseError(overviewError))}/>
            }
            {
              overviewLoading &&
              !overviewError&&
              <div className="bpp-flx-c">
                <Loading
                  description="Overview is loading"
                  withOverlay={false}
                />
              </div>
            }
            {
              !overviewLoading &&
              !overviewError&&
              overviewPayload &&
              <div className='bpp-overview-container bpp-flx-vc bpp-flx-c bpp-mt-16'>
                <div className='bpp-txt-center bpp-overview-item'>
                  <Hills aria-label="hills" className="bpp-overview-picto bpp-overview-canva" />
                  <p>{__('Achievements')}</p>
                  <p className="bpp-overview-number">
                    {overviewPayload.achievements}
                  </p>
                </div>
                <div className='bpp-txt-center bpp-overview-item'>
                  <PlayerFlow aria-label="missions" className="bpp-overview-picto bpp-overview-canva" />
                  <p className='bpp-mt-16'>{__('Missions')}</p>
                  <p className="bpp-overview-number">
                    {
                      `${overviewPayload.missions?.completed} / 
                      ${overviewPayload.missions?.total}`
                    }
                  </p>
                </div>
                <div className='bpp-txt-center bpp-overview-item'>
                  <Time aria-label="time" className="bpp-overview-picto bpp-overview-canva" />
                  <p>{__('Game time (m)')}</p>
                  <p className="bpp-overview-number">
                    {overviewPayload.gameTime}
                  </p>
                </div>
              </div>

            }
          </Tile>
        </Column>
      </Row>
      <Row className="bpp-mt-16">
        <Column>
          <Tile light={true} className="bpp-mt-16 ">
            <div className='bpp-flx-btwn'>
              <Title>{__('Student details')}</Title>
              <ContentSwitcher
                className="bpp-detail-context"
                selectedIndex={studentContext}
                onChange={(i) => { setContext(i.index); }}>
                <Switch name="achievements" text={__('Achievements')} />
                <Switch name="missions" text={__('Missions')} />
              </ContentSwitcher>
            </div>
            {
              studentContext === 0 &&
              <Achievements 
                courseId={courseId}
                studentId={studentId}/>
            }
            {
              studentContext === 1 &&
              <Missions
                courseId={courseId}
                studentId={studentId}/>
            }
          </Tile>
        </Column>
      </Row>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    resume: state.student.resume,
    overview: state.student.overview,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getResume: studentThunks.getResume,
      getOverview: studentThunks.getOverview,
      updateBreadCrumbs: userThunks.updateBreadCrumbs
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Student);