const URL_SERVER = process.env.REACT_APP_URL_SERVER;
const keyPersist = process.env.REACT_APP_KEY_PERSIST;

export async function getAdminCourses({ apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/admin/course';
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function getAdminCoursesL(props, { apiToken }) {
  const { page, limit  } = props;
  const url = new URL(URL_SERVER);
  
  const searchParams = new URLSearchParams();
  searchParams.append('page', page);
  searchParams.append('limit', limit);
  
  url.search = searchParams.toString();
  url.pathname = '/api/v1/admin/course';
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}


export async function getAdminTeachersL(props, { apiToken }) {
  const { page, limit  } = props;
  const url = new URL(URL_SERVER);
  
  const searchParams = new URLSearchParams();
  searchParams.append('page', page);
  searchParams.append('limit', limit);
  
  url.search = searchParams.toString();
  url.pathname = '/api/v1/admin/teacher';
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function getAdminMissionsL(props, { apiToken }) {
  const { page, limit  } = props;
  const url = new URL(URL_SERVER);
  
  const searchParams = new URLSearchParams();
  searchParams.append('page', page);
  searchParams.append('limit', limit);
  
  url.search = searchParams.toString();
  url.pathname = '/api/v1/game/mission';
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function getAdminGameLevelsL(props, { apiToken }) {
  const { page, limit  } = props;
  const url = new URL(URL_SERVER);
  
  const searchParams = new URLSearchParams();
  searchParams.append('page', page);
  searchParams.append('limit', limit);
  
  url.search = searchParams.toString();
  url.pathname = '/api/v1/game/gamelevel';
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function registerCourse(data, { apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/admin/course';

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    },
    body: JSON.stringify(data)
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function registerTeacher(data, { apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/admin/teacher';

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    },
    body: JSON.stringify(data)
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function registerMissions(data, { apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/game/mission';

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    },
    body: JSON.stringify(data)
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function registerGameLevels(data, { apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/game/gamelevel';

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    },
    body: JSON.stringify(data)
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

//////////
export async function changePasswordT(data, T_id , { apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/admin/teacher/${T_id}`;

  let response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    },
    body: JSON.stringify(data)
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const {  status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return message;
}

export async function changeMission(data, C_id , { apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/game/mission/${C_id}`;

  let response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    },
    body: JSON.stringify(data)
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const {  payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function changeGameLevel(data, GL_id , { apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/game/gamelevel/${GL_id}`;

  let response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    },
    body: JSON.stringify(data)
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const {  payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}
//////

export async function deleteTeacherAd(T_id , { apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/admin/teacher/${T_id}`;

  let response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const {  status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return message;
}

export async function deleteCourseAd(C_id , { apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/admin/course/${C_id}`;

  let response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const {  status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return message;
}

export async function deleteMissionAd(M_id , { apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/game/mission/${M_id}`;

  let response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': apiToken
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const {  status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return message;
}

//////
