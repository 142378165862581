/* eslint-disable no-unused-vars */
import * as yup from 'yup';
require('yup-password')(yup);

const register = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Invalid email')
    .required('The email is required'),
  firstname: yup
    .string('The firstname must be a text')
    .trim()
    .matches(/[a-zA-Z]+/, 'Your first only must include characters')
    .required('The firstname is required'),
  lastname: yup
    .string('The lastname must be a text')
    .trim()
    .matches(/[a-zA-Z]+/, 'Your first only must include characters')
    .required('The lastname is required'),
  phone: yup
    .string()
    .trim()
    .matches(/^\+?[0-9]{9,15}$/, 'Invalid phone number')
    .required('The phone number is required'),
  password: yup
    .string()
    .trim()
    .password()
    .minSymbols(0)
    .minNumbers(1, 'Must include at least one number')
    .minUppercase(1, 'Must include at least upper letter')
    .minLowercase(1, 'Must include at least lower letter')
    .min(8, 'Password is too short, should be 8 chars minimum')
    .required('The password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password must match'),
  institution: yup
    .string()
    .required('The institution is required'),
  captchaToken: yup
    .string()
    .required('Captcha validation is required')
});

const registerAd = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Invalid email')
    .required('The email is required'),
  firstname: yup
    .string('The firstname must be a text')
    .trim()
    .matches(/[a-zA-Z]+/, 'Your first only must include characters')
    .required('The firstname is required'),
  lastname: yup
    .string('The lastname must be a text')
    .trim()
    .matches(/[a-zA-Z]+/, 'Your first only must include characters')
    .required('The lastname is required'),
  phone: yup
    .string()
    .trim()
    .matches(/^\+?[0-9]{9,15}$/, 'Invalid phone number')
    .required('The phone number is required'),
  decide: yup
    .string(),
  password: yup
    .string()
    .when('decide', (val,schema) => {
      if (val == 'Mostrar') return yup
        .string()
        .trim()
        .password()
        .minSymbols(0)
        .minNumbers(1, 'Must include at least one number')
        .minUppercase(1, 'Must include at least upper letter')
        .minLowercase(1, 'Must include at least lower letter')
        .min(8, 'Password is too short, should be 8 chars minimum')
        .required('The password is required');
      return yup.string().notRequired();
    }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password must match'),
  institution: yup
    .string()
    .required('The institution is required')
});


const login = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Invalid email')
    .required('The email is required'),
  password: yup
    .string()
    .trim()
    .required('The password is required'),
  captchaToken: yup
    .string()
    .required('Captcha validation is required'),
  message: yup.string('The must be a string').optional().trim()
});

const forgot = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Invalid email')
    .required('The email is required'),
  message: yup.string('The must be a string').optional().trim()
});

const resetPassword = yup.object().shape({
  password: yup
    .string()
    .trim()
    .password()
    .minSymbols(0)
    .minNumbers(1, 'Must include at least one number')
    .minUppercase(1, 'Must include at least upper letter')
    .minLowercase(1, 'Must include at least lower letter')
    .min(8, 'Password is too short, should be 8 chars minimum')
    .required('The password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password must match')
});

const createCourse = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Course name is required'),
  description: yup
    .string()
    .trim()
    .required('Description is required')
});

const createCourseAd = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Course name is required'),
  description: yup
    .string()
    .trim()
    .required('Description is required'),
  teacherId: yup
    .string()
    .required('The email of teacher is required')
});

const saveCourse = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Course name is required'),
  description: yup
    .string()
    .trim()
    .required('Description is required')
});

const createMissionAd = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Mission name is required'),
  description: yup
    .string()
    .trim()
    .required('Description is required'),
  score: yup
    .string()
    .trim()
    .required('Score is required')/*,
  gameId: yup
    .string()
    .trim()
    .required('gameId is required')*/

});

const createGameLevelAd = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Game level name is required'),
  description: yup
    .string()
    .trim()
    .required('Description is required'),
  gameId: yup
    .string()
    .trim()
    .required('GameId is required')
});

const changePassAd = yup.object().shape({
  password: yup
    .string()
    .trim()
    .password()
    .minSymbols(0)
    .minNumbers(1, 'Must include at least one number')
    .minUppercase(1, 'Must include at least upper letter')
    .minLowercase(1, 'Must include at least lower letter')
    .min(8, 'Password is too short, should be 8 chars minimum')
    .required('The password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password must match')
});

const editMissionAd = yup.object().shape({
  description: yup
    .string()
    .trim()
    .required('Description is required'),
  score: yup
    .string()
    .trim()
    .required('Score is required'),
  gameId: yup
    .string()
    .trim()
    .required('GameId is required')

});

const editGameLevelAd = yup.object().shape({
  description: yup
    .string()
    .trim()
    .required('Description is required'),
  gameId: yup
    .string()
    .trim()
    .required('GameId is required')

});
export {
  login,
  forgot,
  register,
  createCourse,
  saveCourse,
  createCourseAd,
  createMissionAd,
  registerAd,
  changePassAd,
  editMissionAd,
  createGameLevelAd,
  editGameLevelAd,
  resetPassword

};
