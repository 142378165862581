import { createAction } from 'redux-actions';

export const types = {
  GET_COURSES_START: 'TEACHER/GET_COURSES_START',
  GET_COURSES_ERROR: 'TEACHER/GET_COURSES_ERROR',
  GET_COURSES_COMPLETE: 'TEACHER/GET_COURSES_COMPLETE',

  CREATE_COURSE_START: 'TEACHER/CREATE_COURSE_START',
  CREATE_COURSE_ERROR: 'TEACHER/CREATE_COURSE_ERROR',
  CREATE_COURSE_COMPLETE: 'TEACHER/CREATE_COURSE_COMPLETE',

  GET_STUDENTS_START: 'TEACHER/GET_STUDENTS_START',
  GET_STUDENTS_ERROR: 'TEACHER/GET_STUDENTS_ERROR',
  GET_STUDENTS_COMPLETE: 'TEACHER/GET_STUDENTS_COMPLETE',

  GET_OVERVIEW_START: 'TEACHER/GET_OVERVIEW_START',
  GET_OVERVIEW_ERROR: 'TEACHER/GET_OVERVIEW_ERROR',
  GET_OVERVIEW_COMPLETE: 'TEACHER/GET_OVERVIEW_COMPLETE',

  GET_ACHIEVEMENTS_START: 'TEACHER/GET_ACHIEVEMENTS_START',
  GET_ACHIEVEMENTS_ERROR: 'TEACHER/GET_ACHIEVEMENTS_ERROR',
  GET_ACHIEVEMENTS_COMPLETE: 'TEACHER/GET_ACHIEVEMENTS_COMPLETE',

  GET_MISSIONS_G_START: 'TEACHER/GET_MISSIONS_G_START',
  GET_MISSIONS_G_ERROR: 'TEACHER/GET_MISSIONS_G_ERROR',
  GET_MISSIONS_G_COMPLETE: 'TEACHER/GET_MISSIONS_G_COMPLETE',

  GET_MISSIONS_T_START: 'TEACHER/GET_MISSIONS_T_START',
  GET_MISSIONS_T_ERROR: 'TEACHER/GET_MISSIONS_T_ERROR',
  GET_MISSIONS_T_COMPLETE: 'TEACHER/GET_MISSIONS_T_COMPLETE',

  UPDATE_COURSE_START: 'TEACHER/UPDATE_COURSE_START',
  UPDATE_COURSE_ERROR: 'TEACHER/UPDATE_COURSE_ERROR',
  UPDATE_COURSE_COMPLETE: 'TEACHER/UPDATE_COURSE_COMPLETE',

  GET_COURSE_START: 'TEACHER/GET_COURSE_START',
  GET_COURSE_ERROR: 'TEACHER/GET_COURSE_ERROR',
  GET_COURSE_COMPLETE: 'TEACHER/GET_COURSE_COMPLETE'
};

export const actions = {
  createCourse: {
    start: createAction(types.CREATE_COURSE_START),
    error: createAction(types.CREATE_COURSE_ERROR, error => ({ error })),
    complete: createAction(
      types.CREATE_COURSE_COMPLETE,
      dataClass => ({ dataClass })
    )
  },
  getCourses: {
    start: createAction(types.GET_COURSES_START),
    error: createAction(types.GET_COURSES_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_COURSES_COMPLETE,
      payload => ({ payload })
    )
  },
  getStudents: {
    start: createAction(types.GET_STUDENTS_START),
    error: createAction(types.GET_STUDENTS_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_STUDENTS_COMPLETE,
      payload => ({ payload })
    )
  },
  getOverview: {
    start: createAction(types.GET_OVERVIEW_START),
    error: createAction(types.GET_OVERVIEW_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_OVERVIEW_COMPLETE,
      payload => ({ payload })
    )
  },
  getAchievements: {
    start: createAction(types.GET_ACHIEVEMENTS_START),
    error: createAction(types.GET_ACHIEVEMENTS_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_ACHIEVEMENTS_COMPLETE,
      payload => ({ payload })
    )
  },
  getMissionsG: {
    start: createAction(types.GET_MISSIONS_G_START),
    error: createAction(types.GET_MISSIONS_G_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_MISSIONS_G_COMPLETE,
      payload => ({ payload })
    )
  },
  getMissionsT: {
    start: createAction(types.GET_MISSIONS_T_START),
    error: createAction(types.GET_MISSIONS_T_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_MISSIONS_T_COMPLETE,
      payload => ({ payload })
    )
  },
  updateCourse: {
    start: createAction(types.UPDATE_COURSE_START),
    error: createAction(types.UPDATE_COURSE_ERROR, error => ({ error })),
    complete: createAction(
      types.UPDATE_COURSE_COMPLETE,
      payload => ({ payload })
    )
  },
  getCourse: {
    start: createAction(types.GET_COURSE_START),
    error: createAction(types.GET_COURSE_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_COURSE_COMPLETE,
      payload => ({ payload })
    )
  }
};