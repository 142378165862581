import React, { useEffect } from 'react';
import CourseCard from '../../../shared/courseCard';
import { Add16 } from '@carbon/icons-react';
import { Grid, Row, Column, Button } from 'carbon-components-react';
import styled from 'styled-components';
import { productiveHeading04 } from '@carbon/type';
import Empty from '../../../shared/empty';
import SVGNotFound from '../../../assets/shared/NoFoundPage.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { thunks as userThunks } from './../../../redux/actions/thunks/user';

const Title = styled.h1(productiveHeading04);

const Courses = (props) => {
  const {
    courses,
    updateBreadCrumbs
  } = props;

  useEffect(() => {
    updateBreadCrumbs({
      breadCrumb: 'courses'
    });
  }, []);

  return (
    <Grid>
      <Row>
        <Column sm={4} md={4} lg={8} xlg={9}>
          <Title>
            {
              __('Courses')
            }
          </Title>
        </Column>
        <Column sm={4} md={4} lg={4} xlg={3}>
          <div className={'bpp-flx-vc root bpp-flx-end'}>
            <Link
              to='/teacher/app/course/create'
            >
              <Button
                renderIcon={Add16}>
                {
                  __('Create a new course')
                }
              </Button>
            </Link>
          </div>
        </Column>
      </Row>
      {
        !courses?.length &&
        <Empty
          component={SVGNotFound}
          className='bpp-mt-64'
          title={__('Not courses created yet')}
          description={__('This is very lonely around here...you could create a course with the button above')}
        />
      }
      {
        courses?.length > 0 &&
        <Row className="bpp-mt-16">
          {
            courses.map((c, idx) => (
              <Column key={idx} sm={4} md={4} lg={4} xlg={3}>
                <CourseCard
                  name={c.name}
                  description={c.description}
                  code={c.code}
                  game={c.game}
                  path={`/teacher/app/course/${c.id}`}
                />
              </Column>
            ))
          }
        </Row>
      }
    </Grid>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateBreadCrumbs: userThunks.updateBreadCrumbs
}, dispatch);

export default connect(null, mapDispatchToProps)(Courses);

