import React from 'react';

const Separator = ({ header }) => {
  return (
    <div
      className={
        header ?
          'shy-row-separator shy-header-separator' :
          'shy-row-separator'
      }>
    </div>
  );
};

export default Separator;
