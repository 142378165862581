import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect
} from 'react-router-dom';
import CoursesPage from '../../components/admin/courses/CoursesPage';
import TeachersPage from '../../components/admin/teachers/TeachersPage';
import MissionsPage from '../../components/admin/missions/MissionsPage';
import GameLevelsPage from '../../components/admin/gameLevels/GameLevelsPage';
import AppLayout from '../../shared/layout/AppLayout';
import NoMatch from '../../shared/noMatch';

const AppAdminRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <AppLayout>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/courses`} />
        </Route>
        <Route path={`${path}/courses`} component={CoursesPage} />
        <Route exact path={`${path}/teachers`} component={TeachersPage} />
        <Route exact path={`${path}/students`} component={() => <h1> Students </h1>} />
        <Route exact path={`${path}/statistics`} component={() => <h1> Statisctics </h1>} />
        <Route exact path={`${path}/missions`} component={MissionsPage} />
        <Route exact path={`${path}/gameLevels`} component={GameLevelsPage} />
        <Route exact path={`${path}/*`} component={NoMatch} />
      </Switch>
    </AppLayout>
  );
};

export default AppAdminRoutes;
