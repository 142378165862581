import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DoNot_02 as DoNot02 } from '@carbon/pictograms-react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import {
  Loading
} from 'carbon-components-react';
import { thunks as teacherThunks } from '../../../../../redux/actions/thunks/teacher';
import Error from '../../../../../shared/error';
import { parseError } from './../../../../../utils';

import './index.css';

const parseData = (data) => {
  const uniqueMissions = [...new Set(data.map(d => `${d.name} - ${d.id}`))];
  const missionsObject = {};
  data.forEach(d => {
    if (!missionsObject[d.gender]) {
      missionsObject[d.gender] = {};
    }
    if (!missionsObject[d.gender].name) {
      missionsObject[d.gender].name = d.gender;
    }
    const tempList =  missionsObject[d.gender].data || [];
    tempList[uniqueMissions.findIndex(u => u==`${d.name} - ${d.id}`)] = d.genderValue;
    missionsObject[d.gender].data = tempList;
  });
  return [uniqueMissions, Object.values(missionsObject || {})];
};

const MissionsByGroup = (props) => {
  HighchartsExporting(Highcharts);
  require('highcharts/highcharts-more')(Highcharts);
  const {
    missionsG, getMissionsG,  courseId,
    user
  } = props;
  const {
    payload: missionsPayload,
    loading: missionsLoading,
    error: missionsError
  } = missionsG || {};
  const {
    id: teacherId,
    token: apiToken
  } = user || {};
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]); 
  const chartComponent = useRef(null);
  const chartOptions = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'inherit'
      }
    },
    title: {
      text: __('Missions completed by student gender')
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      title: {
        text: __('Mission')
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: __('Students')
      }
    },
    tooltip: {
      headerFormat: `<span style="font-size:10px">
                      {point.key}
                    </span>
                    <table>`,
      pointFormat: `<tr>
                      <td style="color:{series.color};padding:0">
                        {series.name}:
                      </td>
                      <td style="padding:0">
                        <b>{point.y}</b>
                      </td>
                    </tr>`,
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: data,
    credits: {
      enabled: false
    },
    colors: ['#1aa3a3', '#4d23d9', '#b55773']
  };
  useEffect(() => {
    getMissionsG(
      {
        teacherId,
        courseId,
        by: 'genderGroup'
      },
      {
        apiToken
      }
    );
  }, [getMissionsG]);

  useEffect(() => {
    if (missionsPayload) {
      const [newCategories, newData] = parseData(missionsPayload);
      setCategories(newCategories);
      setData(newData);
    }
  },[missionsPayload]);
  
  const clickHandler = () => {
    chartComponent.current.chart.reflow();
  };
  return (
    <div className="bpp-student-mission bpp-flx-c bpp-flx-clmn">
      {
        !missionsLoading &&
        missionsError &&
        <Error errorMessage={__(parseError(missionsError))}/>
      }
      {
        missionsLoading &&
        !missionsError &&
        <div className="bpp-flx-c">
          <Loading
            description="Missions are loading"
            withOverlay={false}
          />
        </div>
      }
      {
        !missionsLoading &&
        !missionsError &&
        missionsPayload &&
        missionsPayload.length > 0 &&
        <div 
          className="bpp-chart-root bpp-flx-c"
          onClick={clickHandler}>
          <HighchartsReact
            ref={chartComponent}
            key={missionsPayload.length}
            highcharts={Highcharts}
            options={chartOptions}
            allowChartUpdate={true}
            containerProps={{ style: { width: '90%' } }}
          />
        </div>
      }
      {
        (!missionsLoading && 
          !missionsError &&
          (!missionsPayload 
            || !missionsPayload.length)) &&
        <div className="bpp-flx-c bpp-flx-clmn">
          <DoNot02/>
          <p>{__('No mission were found!')}</p>
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    missionsG: state.teacher.missionsByGenderGroup,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMissionsG: teacherThunks.getMissionsG
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(MissionsByGroup);