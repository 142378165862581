const URL_SERVER = process.env.REACT_APP_URL_SERVER;
const keyPersist = process.env.REACT_APP_KEY_PERSIST;
export async function getResume(props, { apiToken }) {
  const { studentId, courseId } = props;
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/student/${studentId}/course/${courseId}/resume`;
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}
export async function getOverview(props, { apiToken }) {
  const { studentId, courseId } = props;
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/student/${studentId}/course/${courseId}/overview`;
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}
export async function getAchievements(props, { apiToken }) {
  const { studentId, courseId } = props;
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/student/${studentId}/course/${courseId}/achievements`;
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}
export async function getMissions(props, { apiToken }) {
  const { studentId, courseId } = props;
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/student/${studentId}/course/${courseId}/missions`;
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}
export async function getQuizes(props, { apiToken }) {
  const { studentId, courseId } = props;
  const url = new URL(URL_SERVER);
  url.pathname = `/api/v1/student/${studentId}/course/${courseId}/quizes`;
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': apiToken,
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}