import React, { useEffect, useState } from 'react';
import { Loading, Pagination } from 'carbon-components-react';
import CoursesTable from './CoursesTable';
import './coursesPage.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { thunks as adminThunks } from '../../../redux/actions/thunks/admin';
import Error from '../../../shared/error';
import { parseError } from '../../../utils';


const CoursesPage = (props) => {
  const {
    coursesadmin, getAdminCoursesL,
    user
  } = props;

  const {
    total: courseadminTotal,
    payload: coursesadminPayload,
    error: courseadminError,
    loading: courseadminLoading
  } = coursesadmin || {};
  const {
    token: apiToken,
    error: userError,
    loading: userLoading
  } = user || {};


  const [limit,setLimit] = useState(4);
  const [page, setPage] = useState(1);
  const [courses, setCourses] = useState([]);
  const [respaldo, setRespaldo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(null);
  useEffect(() => {

    getAdminCoursesL(
      {
        limit,
        page
      },
      {
        apiToken
      });
    setLoading(true);
    setLoading(false);
  }, [limit, page, getAdminCoursesL]);

  useEffect(() => {

    setRespaldo(coursesadminPayload);
    setCourses(coursesadminPayload);
    setTotal(courseadminTotal);
  }, [coursesadminPayload]);

  return (
    <>
      <div className='bpp-mt-16'>
        {
          !userLoading &&
          userError &&
          <Error errorMessage={__(parseError(userError))} />
        }
        {
          !courseadminLoading &&
          courseadminError &&
          <Error errorMessage={__(parseError(courseadminError))} />
        }
        {
          loading &&
          <Loading active={loading} withOverlay={true} />
        }
        {
          !userLoading &&
          !userError &&
          !courseadminLoading &&
          !courseadminError &&
          !loading &&
          /*courses?.length &&*/ (
            <>
              <CoursesTable elrespaldo={respaldo} seteoC={setCourses} courses={courses.map(s => ({ ...s, FullName: `${s.FirstName} ${s.LastName}`, DateEc: new Date(s.DateAdd).toLocaleDateString() }))} />
              <Pagination
                onChange={e => {setPage(e.page);setLimit(e.pageSize);}}
                backwardText={__('Previous page')}
                forwardText={__('Next page')}
                itemsPerPageText={__('Items per page:')}
                page={page}
                pageNumberText={__('Page Number')}
                pageSize={limit}
                pageSizes={[4,5,6]}
                totalItems={total}
              />
            </>
          )
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    coursesadmin: state.admin.courses,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAdminCoursesL: adminThunks.getAdminCoursesL
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(CoursesPage);
