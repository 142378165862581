import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect
} from 'react-router-dom';
import TeacherCourses from '../../components/teacher/index';
import CreateCourse from '../../components/course/create';
import Course from '../../components/course';
import Student from '../../components/student';
import NoMatch from '../../shared/noMatch';
import AppLayout from '../../shared/layout/AppLayout';

const AppTeacherRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <AppLayout>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/courses`} />
        </Route>
        <Route exact path={`${path}/courses`} component={TeacherCourses} />
        <Route exact path={`${path}/course/create`} component={CreateCourse} />
        <Route exact path={`${path}/course/:courseId/student/:studentId/`} component={Student} />
        <Route exact path={`${path}/course/:courseId/`} component={Course} />
        <Route exact path={`${path}/*`} component={NoMatch} />
      </Switch>
    </AppLayout>
  );
};

export default AppTeacherRoutes;
