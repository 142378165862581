import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Switcher,
  SwitcherItem,
  SwitcherDivider
} from 'carbon-components-react/lib/components/UIShell';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { thunks as userThunks } from '../../redux/actions/thunks/user';


const AdminSwitcher = (props) => {
  const { logout } = props;
  const history = useHistory();
  const location = useLocation();

  return (
    <Switcher aria-label="Switcher Container">
      <SwitcherItem
        aria-label="Courses"
        element={Link}
        to="/admin/app/courses"
        isSelected={location.pathname === '/admin/app/courses'}
      >
        {__('Courses')}
      </SwitcherItem>
      <SwitcherItem
        aria-label="Teachers"
        element={Link}
        to="/admin/app/teachers"
        isSelected={location.pathname === '/admin/app/teachers'}
      >
        {__('Teachers')}
      </SwitcherItem>
      <SwitcherItem
        aria-label="Missions"
        element={Link}
        to="/admin/app/missions"
        isSelected={location.pathname === '/admin/app/missions'}
      >
        {__('Missions')}
      </SwitcherItem>
      <SwitcherItem
        aria-label="GameLevels"
        element={Link}
        to="/admin/app/gameLevels"
        isSelected={location.pathname === '/admin/app/gameLevels'}
      >
        {__('Game levels')}
      </SwitcherItem>
      <SwitcherDivider />
      <SwitcherItem
        className='bpp-pointer'
        aria-label="Sign out"
        onClick={() => {
          logout();
          history.push('/admin');
        }}>
        {
          __('Sign out')
        }
      </SwitcherItem>
    </Switcher>
  );
};

const mapStateToProps = state => {
  return {
    breadCrumbs: state.user.breadCrumbs
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: userThunks.logout
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminSwitcher);

