/* eslint-disable max-len */
import React , {  useState } from 'react';
import 'carbon-components/css/carbon-components.min.css';
import ReactDOM from 'react-dom';

import {
  Button,
  ComposedModal,
  ModalHeader,
  ModalBody,
  Grid,
  Row,
  Column
} from 'carbon-components-react';


import CourseTeacherAdd from './CourseTeacherAdd';
import CourseAdd from './CourseAdd';


const ModalCourseAdd = () => {
 
  const ModalStateManager = ({
    renderLauncher: LauncherContent,
    children: ModalContent
  }) => {
    const [open, setOpen] = useState(false);
    
    return (
      <>
        {!ModalContent || typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <ModalContent open={open} setOpen={setOpen} />,
            document.body
          )}
        {LauncherContent && <LauncherContent open={open} setOpen={setOpen} />}
      </>
    );
  };
  
  return (
    
    <ModalStateManager
      renderLauncher={({ setOpen }) => (
        <Button 
          onClick={() => {
            setOpen(true);   
          }}
          size="small"
          kind="primary"
        >
          {__('Add new course')}
        </Button>
      )}>
      {({ open, setOpen }) => (
        <ComposedModal
          
          open={open}
          onClose={() => 
          {
            setOpen(false);
            var lzd = document.getElementById('aquidebo');
            lzd.setAttribute('style', 'display: none');
            var bz = document.getElementById('subircurso');
            bz.disabled=false;
                        
          }
          }>
          <ModalHeader>
            {__('Create new course')}
          </ModalHeader>
          <ModalBody>
            <Grid className="bpp-create-course">

              <Row>
                <Column sm={0} md={0} lg={6} xlg={6}> 
                  <CourseAdd/>
                 
                </Column>

                <Column sm={12} md={12} lg={6} xlg={6}>
                            
                  <div id="aquidebo" style={{ display: 'none' }}>
                            
                    <CourseTeacherAdd/>       
                  </div>
                            
                          
                </Column>
              </Row>
            </Grid>
          </ModalBody>  
     
        </ComposedModal>
      )}
    </ModalStateManager>
    
  );
};


export default ModalCourseAdd;