
const URL_SERVER = process.env.REACT_APP_URL_SERVER;
const keyPersist = process.env.REACT_APP_KEY_PERSIST;
export async function register(data) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/teacher/register';

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function login(data) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/auth';

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function get({ apiToken }) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/teacher';
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ token: apiToken })
  });
  if (response.status === 401) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
  }
  if (response.status >= 400 && response.status < 600) {
    const { error, message, status }  = await response.json();
    if (status == 400 && error == 'Invalid token') {
      window.location.replace('/');
      localStorage.removeItem(`persist:${keyPersist}`);
    }
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function forgotPassword(data) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/teacher/forgotPassword';

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function resetPassword(data) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/teacher/resetPassword';

  let response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}

export async function validateExpiry(data) {
  const url = new URL(URL_SERVER);
  url.pathname = '/api/v1/teacher/validateExpiry';

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  if (response.status >= 400 && response.status < 600) {
    const { error, message }  = await response.json();
    throw new Error(error || message);
  }
  const { payload, status, error, message } = await response.json();
  if (status == 404) {
    throw new Error(error || message);
  }
  return payload;
}