/* eslint-disable no-unused-vars */
import React from 'react';
import 'carbon-components/css/carbon-components.min.css';
const URL_SERVER = process.env.REACT_APP_URL_SERVER;
import ModalCourseAdd from './ModalCourseAdd';
import {
  DataTable,
  TableContainer,
  TableToolbar,
  TableBatchActions,
  TableBatchAction,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectAll,
  TableSelectRow,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell
} from 'carbon-components-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Delete16 as Delete
  //Save16 as Save,
  //Download16 as Download
} from '@carbon/icons-react';


import { coursesListHeader } from '../../../constants';
import { thunks as adminThunks } from '../../../redux/actions/thunks/admin';

import Swal from 'sweetalert2';


const CoursesTable = (props) => {
  const { courses , seteoC, elrespaldo, deleteAdminCourses, user } = props;

  const {
    token: apiToken
  } = user || {};
  const batchActionClick = (e, action, data) => {
    e.preventDefault();

    Swal.fire({
      title: '¿Estas seguro?',
      text: '¡No seras capaz de revertir esta accion!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borralo(s)!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        for (let elemento of data) {
          var idDeCourse = elemento.id;
          deleteAdminCourses(
            idDeCourse ,{
              apiToken 
            })
            .then(() => {
              console.log('Eliminacion exitosa');
            })
            .catch(err => {
              console.log('ERROR:', err);
            });
        }


        Swal.fire(
          '¡Operacion exitosa!',
          'Los elementos seleccionados han sido borrados.',
          'success'
        )
          .then(() => {
            window.location.reload(); 
          }
          );}
    });
  };
 
  const loadResults = async (inputValue4) => {

    var palabra = inputValue4.target.value;
    var palabra_size = palabra.length;

    if (palabra_size!=0) {
      const url = new URL(URL_SERVER);
      url.pathname = '/api/v1/admin/course';
      const res = await fetch(url+`?search=${palabra}`, {
        method: 'GET',
        headers: {
          'Authorization': apiToken,
          'Content-Type': 'application/json'
        }
      });
      const { payload, status } = await res.json();
      if (status == 404) {
        throw new Error('Error!!!');
      }
     
      seteoC(payload.course);

    }
    
    else {   
      seteoC(elrespaldo);
    }
    
  };

  return (
    <>
      <DataTable 
        rows={courses.map(s => ({ ...s, id: String(s.CourseId) }))}
        headers={coursesListHeader.map(s =>
          ({ ...s, header: __(s.header) }))}
        isSortable >
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          getBatchActionProps,
          //onInputChange,
          selectedRows,
          getTableProps,
          getToolbarProps,
          getTableContainerProps
        }) => (
          <TableContainer
            {...getTableContainerProps()}
            title={__('Courses')}
            description={__('List of courses')}
          >
            <TableToolbar
              {...getToolbarProps()}
            >
              <TableBatchActions {...getBatchActionProps()}>
                <TableBatchAction
                  tabIndex={
                    getBatchActionProps().shouldShowBatchActions ? 0 : -1
                  }
                  renderIcon={Delete}
                  onClick={(e) => batchActionClick(e, 'delete', selectedRows)}>
                  {__('Delete')}
                </TableBatchAction>
                {/*
                <TableBatchAction
                  tabIndex={
                    getBatchActionProps().shouldShowBatchActions ? 0 : -1
                  }
                  renderIcon={Download}
                  onClick={(e) => batchActionClick(e, 'download', selectedRows)}
                  >
                  {__('Download')}
                </TableBatchAction>*/
                }
              </TableBatchActions>
              <TableToolbarContent>
                <TableToolbarSearch
                  tabIndex={
                    getBatchActionProps().shouldShowBatchActions ? -1 : 0
                  }
                  onChange={loadResults}
                />


                <ModalCourseAdd/>


              </TableToolbarContent>
            </TableToolbar>
            <Table
              {...getTableProps()}
            >
              <TableHead>
                <TableRow>
                  <TableSelectAll {...getSelectionProps()} />
                  {headers.map((header, i) => (
                    <TableHeader key={i} {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => ( 
                  <TableRow key={i} {...getRowProps({ row })}>
                    <TableSelectRow {...getSelectionProps({ row })} />
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    error: state.user.error,
    loading: state.user.loading
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  deleteAdminCourses: adminThunks.deleteAdminCourses
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CoursesTable);