import React, { useState, useEffect } from 'react';
import { 
  Grid,
  Row,
  Column,
  ContentSwitcher,
  Switch,
  SkeletonText
} from 'carbon-components-react';
import {
  useParams
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { thunks as userThunks } from './../../redux/actions/thunks/user';
import { thunks as teacherThunks } from '../../redux/actions/thunks/teacher';

import Students from './students';
import styled from 'styled-components';
import { productiveHeading04 } from '@carbon/type';
import Dashboard  from './dashboard';
import Settings from './settings';
import './index.scss';

const Title = styled.h1(productiveHeading04);

const Course = (props) => {
  const [courseContext, setCourseContext] = useState(0);
  const { courseId } = useParams();
  const { updateBreadCrumbs, currentCourse, user, getCourse } = props;
  const {
    payload: cCoursePayload,
    loading: cCourseLoading,
    error: cCourseError
  } = currentCourse || {};
  const {
    id: teacherId,
    token: apiToken
  } = user || {};

  useEffect(() => {
    updateBreadCrumbs({
      breadCrumb: 'course',
      id: cCoursePayload?.name || courseId,
      href: `/course/${courseId}`
    });
  },[currentCourse]);

  useEffect(() => {
    getCourse(
      {
        courseId,
        teacherId
      },
      {
        apiToken
      });
  }, []);
  
  return (
    <Grid>
      <Row>
        <Column sm={8} md={8} lg={12} xlg={12}>
          <Title>
            {
              cCourseLoading &&
              <SkeletonText 
                width={'30%'}
                heading/>
            }
            {
              !cCourseLoading &&
              cCoursePayload &&
              !cCourseError &&
              __('Course *COURSENAME*')
                .replace('*COURSENAME*', cCoursePayload.name)
            }
            {
              cCourseError && 
              __('Course *COURSENAME*')
                .replace('*COURSENAME*', courseId)
            }
          </Title>
        </Column>
      </Row>
      <Row className="bpp-mt-16">
        <Column sm={8} md={4} lg={4} xlg={4}>
          <ContentSwitcher
            selectedIndex={courseContext} 
            onChange={(i) => { setCourseContext(i.index);}}>
            <Switch name="dashboard" text={__('Dashboard')} />
            <Switch name="students" text={__('Students')} />
            <Switch name="settings" text={__('Settings')} />
          </ContentSwitcher>
        </Column>
      </Row>
      <Row className="bpp-mt-32">
        <Column sm={8} md={8} lg={12} xlg={12}>
          {
            courseContext === 1 &&
              <Students courseId={courseId}/>
          }
          {
            courseContext === 0 &&
              <Dashboard courseId={courseId}/>
          }
          {
            courseContext === 2 &&
              <Settings courseId={courseId}/>
          }
        </Column>
      </Row>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    currentCourse: state.teacher.currentCourse
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  updateBreadCrumbs: userThunks.updateBreadCrumbs,
  getCourse: teacherThunks.getCourse
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Course);
