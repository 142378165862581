import React from 'react';
import 'carbon-components/css/carbon-components.min.css';
import {
  Tile,
  Button,
  CodeSnippet,
  OverflowMenu,
  OverflowMenuItem
} from 'carbon-components-react';
import styled from 'styled-components';
import { ArrowRight20 } from '@carbon/icons-react';
import { heading02 } from '@carbon/type';
import './index.css';
import { Link } from 'react-router-dom';

const Title = styled.h1(heading02);

const CourseCard = (props) => {
  const {
    name, description,
    code, game, path
  } = props;

  return (
    <Tile light={true} className="bpp-mt-16 course-card">
      <div className='bpp-flx-vc bpp-flx-btwn'>
        <div className='bpp-flx-clmn'>
          <Title>{name}</Title>
          <p>{description}</p>
          <p>{game}</p>
        </div>
        <OverflowMenu
          size={'sm'}
          iconDescription={__('Course options')}
          flipped={true}>
          <OverflowMenuItem itemText={__('Delete course')} />
        </OverflowMenu>
      </div>
      <CodeSnippet className="bpp-mt-16" type="single" copyButtonDescription={__('copy to clipboard')}>
        {
          code
        }
      </CodeSnippet>
      <Link
        to={path}
      >
        <Button
          renderIcon={ArrowRight20}
          className="bpp-mt-16"
          kind="ghost">
          {
            __('Go to course')
          }
        </Button>
      </Link>
    </Tile>
  );
};
export default CourseCard;
