import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router
} from 'react-router-dom';
import TeacherRouter from './teacher/TeacherRouter';
import AdminRouter from './admin/AdminRouter';


export default function home() {
  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/teacher" />
        <Route path="/teacher" component={TeacherRouter} />
        <Route path="/admin" component={AdminRouter} />
      </Switch>
    </Router>
  );
}

