import * as React from 'react';

const SVGNotFound = (props) => (
  <svg
    data-name='Layer 1'
    xmlns='https://www.w3.org/2000/svg'
    width={720.252}
    height={500}
    viewBox='0 0 720.252 500'
    {...props}
  >
    <path
      cx={212.592}
      cy={103}
      r={64}
      fill='#ff6584'
      d='M177.778 66.203A41.136 41.136 0 0 1 136.642 107.338A41.136 41.136 0 0 1 95.506 66.203A41.136 41.136 0 0 1 177.778 66.203z'
    />
    <path
      d='M336.782 220.539c0 97.061 -57.702 130.952 -128.88 130.952s-128.88 -33.891 -128.88 -130.953S207.902 0.001 207.902 0.001s128.88 123.477 128.88 220.538Z'
      fill='#f2f2f2'
    />
    <path
      fill='#3f3d56'
      d='m203.207 336.644 1.32 -81.231 54.931 -100.495 -54.724 87.753 0.593 -36.525 37.859 -72.707 -37.702 63.041 1.067 -65.692 40.54 -57.884 -40.372 47.554L207.386 0l-4.19 159.462 0.344 -6.579 -41.217 -63.09 40.557 75.718 -3.84 73.366 -0.115 -1.947 -47.516 -66.393 47.372 73.272 -0.48 9.175 -0.086 0.138 0.039 0.753 -9.744 186.138h13.018l1.562 -96.144 47.256 -73.093 -47.139 65.866z'
    />
    <path
      d='M720.252 260.293c0 79.45 -47.231 107.19 -105.494 107.19s-105.495 -27.741 -105.495 -107.19S614.758 79.771 614.758 79.771s105.494 101.072 105.494 180.522Z'
      fill='#f2f2f2'
    />
    <path
      fill='#3f3d56'
      d='m610.915 355.329 1.08 -66.492 44.965 -82.26 -44.795 71.829 0.486 -29.897 30.989 -59.514 -30.861 51.602 0.874 -53.772 33.183 -47.381 -33.047 38.926 0.547 -98.599 -3.431 130.527 0.282 -5.385 -33.739 -51.642 33.198 61.979 -3.144 60.054 -0.094 -1.594 -38.894 -54.346 38.777 59.977 -0.393 7.511 -0.07 0.113 0.032 0.617 -7.976 152.362h10.656l1.278 -78.698 38.682 -59.83 -38.585 53.914z'
    />
    <path
      cx={554.592}
      cy={680.479}
      rx={554.592}
      ry={28.034}
      fill='#3f3d56'
      d='M712.92 437.373A356.46 18.019 0 0 1 356.46 455.392A356.46 18.019 0 0 1 0 437.373A356.46 18.019 0 0 1 712.92 437.373z'
    />
    <path
      cx={892.445}
      cy={726.797}
      rx={94.989}
      ry={4.802}
      fill='#3f3d56'
      d='M634.666 467.143A61.054 3.086 0 0 1 573.612 470.229A61.054 3.086 0 0 1 512.559 467.143A61.054 3.086 0 0 1 634.666 467.143z'
    />
    <path
      cx={548.72}
      cy={773.114}
      rx={94.989}
      ry={4.802}
      fill='#3f3d56'
      d='M413.739 496.913A61.054 3.086 0 0 1 352.686 500A61.054 3.086 0 0 1 291.632 496.913A61.054 3.086 0 0 1 413.739 496.913z'
    />
    <path
      cx={287.944}
      cy={734.279}
      rx={217.014}
      ry={10.97}
      fill='#3f3d56'
      d='M324.558 471.952A139.484 7.051 0 0 1 185.074 479.003A139.484 7.051 0 0 1 45.59 471.952A139.484 7.051 0 0 1 324.558 471.952z'
    />
    <path
      cx={97.084}
      cy={566.27}
      r={79}
      fill='#2f2e41'
      d='M113.177 363.966A50.776 50.776 0 0 1 62.4 414.743A50.776 50.776 0 0 1 11.623 363.966A50.776 50.776 0 0 1 113.177 363.966z'
    />
    <path
      fill='#2f2e41'
      d='m38.796 403.546 15.425 0.182 -0.326 27.636 -15.424 -0.182zm30.851 0.363 15.424 0.182 -0.326 27.636 -15.424 -0.182z'
    />
    <path
      cx={119.546}
      cy={732.616}
      rx={7.5}
      ry={20}
      transform='rotate(-89.325 68.811 722.182)'
      fill='#2f2e41'
      d='M81.658 470.883A4.821 12.855 0 0 1 76.837 483.739A4.821 12.855 0 0 1 72.017 470.883A4.821 12.855 0 0 1 81.658 470.883z'
    />
    <path
      cx={167.554}
      cy={732.182}
      rx={7.5}
      ry={20}
      transform='rotate(-89.325 116.82 721.748)'
      fill='#2f2e41'
      d='M112.515 470.605A4.821 12.855 0 0 1 107.694 483.459A4.821 12.855 0 0 1 102.874 470.605A4.821 12.855 0 0 1 112.515 470.605z'
    />
    <path
      cx={99.319}
      cy={546.295}
      r={27}
      fill='#fff'
      d='M81.19 351.127A17.354 17.354 0 0 1 63.837 368.481A17.354 17.354 0 0 1 46.482 351.127A17.354 17.354 0 0 1 81.19 351.127z'
    />
    <path
      cx={99.319}
      cy={546.295}
      r={9}
      fill='#3f3d56'
      d='M69.621 351.127A5.784 5.784 0 0 1 63.837 356.912A5.784 5.784 0 0 1 58.052 351.127A5.784 5.784 0 0 1 69.621 351.127z'
    />
    <path
      d='M13.704 316.168c-3.884 -18.408 9.44 -36.806 29.759 -41.093s39.939 7.162 43.822 25.57 -9.627 25.022 -29.945 29.309 -39.752 4.622 -43.636 -13.787Z'
      fill='#1e824c'
    />
    <path
      d='M139.856 392.292c0 35.4 -21.044 47.76 -47.004 47.76q-0.902 0 -1.801 -0.019c-1.204 -0.026 -2.394 -0.084 -3.572 -0.164 -23.428 -1.658 -41.631 -14.654 -41.631 -47.576 0 -34.072 43.538 -77.063 46.808 -80.245l0.006 -0.006 0.189 -0.182s47.004 45.033 47.004 80.433Z'
      fill='#1e824c'
    />
    <path
      d='m91.139 434.638 17.192 -24.022 -17.234 26.659 -0.045 2.758c-1.204 -0.026 -2.394 -0.083 -3.572 -0.163l1.853 -35.416 -0.014 -0.275 0.032 -0.051 0.175 -3.345 -17.277 -26.725 17.331 24.216 0.04 0.71 1.401 -26.76 -14.792 -27.615 14.972 22.919 1.458 -55.477 0.006 -0.189v0.183l-0.243 43.747 14.726 -17.343 -14.785 21.112 -0.39 23.958 13.749 -22.993 -13.806 26.519 -0.218 13.32 19.961 -32.003 -20.035 36.651Z'
      fill='#3f3d56'
    />
    <path
      cx={712.485}
      cy={565.415}
      r={79}
      fill='#2f2e41'
      d='M508.721 363.416A50.776 50.776 0 0 1 457.944 414.193A50.776 50.776 0 0 1 407.168 363.416A50.776 50.776 0 0 1 508.721 363.416z'
    />
    <path
      fill='#2f2e41'
      d='m447.534 408.307 14.745 -4.531 8.119 26.419 -14.745 4.531zm29.489 -9.061 14.745 -4.531 8.119 26.419 -14.746 4.531z'
    />
    <path
      cx={767.887}
      cy={732.003}
      rx={20}
      ry={7.5}
      transform='rotate(-17.083 544.826 833.656)'
      fill='#2f2e41'
      d='M506.408 470.489A12.855 4.821 0 0 1 493.553 475.31A12.855 4.821 0 0 1 480.698 470.489A12.855 4.821 0 0 1 506.408 470.489z'
    />
    <path
      cx={813.475}
      cy={716.946}
      rx={20}
      ry={7.5}
      transform='rotate(-17.083 590.415 818.599)'
      fill='#2f2e41'
      d='M535.71 460.811A12.855 4.821 0 0 1 522.855 465.632A12.855 4.821 0 0 1 510 460.811A12.855 4.821 0 0 1 535.71 460.811z'
    />
    <path
      cx={708.522}
      cy={545.71}
      r={27}
      fill='#fff'
      d='M472.751 350.751A17.354 17.354 0 0 1 455.397 368.105A17.354 17.354 0 0 1 438.043 350.751A17.354 17.354 0 0 1 472.751 350.751z'
    />
    <path
      cx={708.522}
      cy={545.71}
      r={9}
      fill='#3f3d56'
      d='M461.182 350.751A5.784 5.784 0 0 1 455.397 356.536A5.784 5.784 0 0 1 449.612 350.751A5.784 5.784 0 0 1 461.182 350.751z'
    />
    <path
      d='M396.991 332.749c-9.314 -16.347 -2.236 -37.932 15.807 -48.212s40.22 -5.36 49.534 10.987 -1.536 26.766 -19.58 37.045 -36.448 16.527 -45.761 0.18Zm-29.604 53.062c0 32.495 -19.317 43.842 -43.147 43.842q-0.828 0 -1.653 -0.019a70.426 70.426 0 0 1 -3.278 -0.149c-21.507 -1.522 -38.216 -13.452 -38.216 -43.673 0 -31.276 39.966 -70.74 42.969 -73.66l0.005 -0.006 0.174 -0.168s43.147 41.339 43.147 73.834Z'
      fill='#1e824c'
    />
    <path
      d='m322.667 424.681 15.781 -22.051 -15.82 24.472 -0.042 2.532a70.426 70.426 0 0 1 -3.278 -0.15l1.7 -32.511 -0.013 -0.253 0.029 -0.047 0.161 -3.07 -15.861 -24.533 15.91 22.23 0.037 0.652 1.286 -24.563 -13.579 -25.35 13.744 21.039 1.338 -50.925 0.005 -0.174v0.168l-0.223 40.158 13.517 -15.92 -13.572 19.38 -0.358 21.994 12.622 -21.108 -12.674 24.344 -0.2 12.227 18.322 -29.378 -18.392 33.644Z'
      fill='#3f3d56'
    />
    <path
      d='M537.07 399.363c0 35.4 -21.044 47.76 -47.004 47.76q-0.902 0 -1.801 -0.019c-1.204 -0.026 -2.394 -0.084 -3.572 -0.164 -23.428 -1.658 -41.631 -14.654 -41.631 -47.576 0 -34.072 43.538 -77.063 46.808 -80.245l0.006 -0.006 0.189 -0.182s47.004 45.033 47.004 80.433Z'
      fill='#1e824c'
    />
    <path
      d='m488.355 441.708 17.192 -24.022 -17.234 26.659 -0.045 2.758c-1.204 -0.026 -2.394 -0.083 -3.572 -0.163l1.853 -35.416 -0.014 -0.275 0.032 -0.051 0.175 -3.345 -17.277 -26.725 17.331 24.216 0.04 0.71 1.401 -26.76 -14.792 -27.615 14.972 22.919 1.458 -55.477 0.006 -0.189v0.183l-0.244 43.747 14.726 -17.343 -14.785 21.112 -0.39 23.958 13.749 -22.993 -13.806 26.519 -0.218 13.32 19.961 -32.003 -20.035 36.651Z'
      fill='#3f3d56'
    />
    <path
      cx={721.517}
      cy={656.822}
      rx={12.4}
      ry={39.5}
      transform='rotate(-64.626 653.41 657.688)'
      fill='#2f2e41'
      d='M471.72 422.167A7.97 25.388 0 0 1 463.749 447.556A7.97 25.388 0 0 1 455.78 422.167A7.97 25.388 0 0 1 471.72 422.167z'
    />
    <path
      cx={112.517}
      cy={651.822}
      rx={12.4}
      ry={39.5}
      transform='rotate(-68.158 47.55 650.645)'
      fill='#2f2e41'
      d='M80.289 418.953A7.97 25.388 0 0 1 72.32 444.342A7.97 25.388 0 0 1 64.349 418.953A7.97 25.388 0 0 1 80.289 418.953z'
    />
  </svg>
);

export default SVGNotFound;