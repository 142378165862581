import { createAction } from 'redux-actions';

export const types = {
  REGISTER_START: 'USER/REGISTER_START',
  REGISTER_ERROR: 'USER/REGISTER_ERROR',
  REGISTER_COMPLETE: 'USER/REGISTER_COMPLETE',

  LOGIN_START: 'USER/LOGIN_START',
  LOGIN_ERROR: 'USER/LOGIN_ERROR',
  LOGIN_COMPLETE: 'USER/LOGIN_COMPLETE',

  GET_START: 'USER/GET_START',
  GET_ERROR: 'USER/GET_ERROR',
  GET_COMPLETE: 'USER/GET_COMPLETE',

  USER_LOGOUT: 'USER/USER_LOGOUT',
  UPDATE_BREADCRUMBS: 'USER/UPDATE_BREADCRUMBS'
};

export const actions = {
  register: {
    start: createAction(types.REGISTER_START),
    error: createAction(types.REGISTER_ERROR, error => ({ error })),
    complete: createAction(types.REGISTER_COMPLETE, dataUser => ({ dataUser }))
  },
  login: {
    start: createAction(types.LOGIN_START),
    error: createAction(types.LOGIN_ERROR, error => ({ error })),
    complete: createAction(types.LOGIN_COMPLETE, dataUser => ({ dataUser }))
  },
  get: {
    start: createAction(types.GET_START),
    error: createAction(types.GET_ERROR, error => ({ error })),
    complete: createAction(types.GET_COMPLETE, dataUser => ({ dataUser }))
  },
  logout: createAction(types.USER_LOGOUT),
  updateBreadCrumbs: createAction(types.UPDATE_BREADCRUMBS, action => action)
};