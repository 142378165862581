import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Grid,
  Row,
  Form,
  Column,
  TextInput,
  Tile,
  Button,
  Loading,
  CodeSnippet,
  CodeSnippetSkeleton,
  TextInputSkeleton
} from 'carbon-components-react';
import { thunks as teacherThunks } from '../../../redux/actions/thunks/teacher';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { heading02 } from '@carbon/type';
import { saveCourse as saveCourseSchema } from '../../../schema';
import Success from './../../../shared/success';
import Error from './../../../shared/error';
import { parseError } from './../../../utils';
import './index.css';

const Title = styled.h1(heading02);

const Settings = (props) => {
  const {
    currentCourse, updateCourse,
    user, courseId, getCourse
  } = props;
  const {
    payload: cCoursePayload,
    loading: cCourseLoading,
    error: cCourseError
  } = currentCourse || {};
  const {
    id: teacherId,
    token: apiToken
  } = user || {};
  const {
    formState: { errors },
    control, handleSubmit, setValue
  } = useForm({ 
    resolver: yupResolver(saveCourseSchema)
  });
  const [successfull, setSuccessfull] = useState(false);
  
  const onSubmit = (data) => {
    setSuccessfull(false);
    updateCourse(
      {
        courseId,
        teacherId,
        courseName: data.name,
        courseDescription: data.description
      },
      {
        apiToken
      })
      .then(() => {
        setSuccessfull(true);
      })
      .catch(err => {
        console.log('ERROR:', err);
      });
  };
  useEffect(() => {
    if (cCoursePayload?.name != undefined &&
      cCoursePayload?.description != undefined) {
      setValue('name', cCoursePayload?.name);
      setValue('description', cCoursePayload?.description);
    }
  }, [cCoursePayload?.name + cCoursePayload?.description]);
  
  useEffect(() => {
    if (successfull) {
      setTimeout(() => {
        setSuccessfull(!successfull);
      },2000);
    }
  },[successfull]);

  useEffect(() => {
    getCourse(
      {
        courseId,
        teacherId
      },
      {
        apiToken
      });
  }, []);

  return (
    <Grid>
      <Row>
        <Column sm={8} md={4} lg={6} xlg={6}>
          <Tile light={true} className="bpp-mt-16">
            <Title>{__('Class details')}</Title>
            {
              !cCourseLoading &&
              cCourseError &&
              <Error errorMessage={__(parseError(cCourseError))}/>
            }
            {
              !cCourseError &&
              <Form noValidate onSubmit={handleSubmit(onSubmit)} className={'bpp-course-form'}>
                {
                  cCourseLoading &&
                  <div className="bpp-flx-c bpp-mt-32">
                    <TextInputSkeleton/>
                  </div>
                }
                {
                  !cCourseLoading &&
                  <div className='bpp-mt-32'>
                    <Controller
                      name='name'
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          className= 'bpp-mt-8'
                          id='name'
                          labelText={__('Course name')}
                          type='text'
                          invalid={!!errors.name}
                          helperText={__('* Required')}
                          invalidText={__(errors.name?.message)}
                          {...field}
                        />
                      )}
                    />
                  </div>
                }
                {
                  cCourseLoading &&
                  <div className="bpp-flx-c bpp-mt-32">
                    <TextInputSkeleton/>
                  </div>
                }
                {
                  !cCourseLoading &&
                  <div className='bpp-mt-32'>
                    <Controller
                      name='description'
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          id='description'
                          type='text'
                          labelText={__('Course description')}
                          invalid={!!errors.description}
                          helperText={__('* Required')}
                          invalidText={__(errors.description?.message)}
                          {...field}
                        />
                      )}
                    />
                  </div>
                }
                {
                  successfull &&
                  <Success successMessage={__('successful process')}/>
                }
                <Button type='submit' className='bpp-mt-32'>
                  {__('Save')}
                  { cCourseLoading && (
                    <Loading
                      className='bpp-ml-64'
                      description='Save class details'
                      small
                      withOverlay={false}
                    />
                  )}
                </Button>
              </Form>  
            }
          </Tile>
        </Column>
        <Column sm={8} md={4} lg={6} xlg={6}>
          <Tile light={true} className="bpp-mt-16 ">
            <Title>{__('Class code')}</Title>
            {
              cCourseLoading &&
              <div className="bpp-flx-c bpp-mt-32">
                <CodeSnippetSkeleton className="bpp-mt-16"/>
              </div>
            }
            {
              !cCourseLoading &&
              <div className='bpp-flx-btwn'>
                <CodeSnippet className="bpp-mt-16" type="single" copyButtonDescription={__('copy to clipboard')}>
                  {
                    cCoursePayload?.code
                  }
                </CodeSnippet>
              </div>
            }
          </Tile>
        </Column>
      </Row>
    </Grid>
  
    
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    currentCourse: state.teacher.currentCourse
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCourse: teacherThunks.updateCourse,
      getCourse: teacherThunks.getCourse
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Settings);