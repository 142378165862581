export const ChartOptions = {
  GAUGE: {
    resizable: true,
    height: '88px',
    gauge: {
      type: 'semi'
    }
  },
  METER: {
    'meter': {
      'status': {
        'ranges': [
          {
            'range': [
              0,
              50
            ],
            'status': 'danger'
          },
          {
            'range': [
              50,
              60
            ],
            'status': 'warning'
          },
          {
            'range': [
              60,
              100
            ],
            'status': 'success'
          }
        ]
      }
    },
    'height': '100px'
  },
  GROUPEDBAR: {
    'axes': {
      'left': {
        'mapsTo': 'value'
      },
      'bottom': {
        'scaleType': 'labels',
        'mapsTo': 'key'
      }
    },
    'toolbar': {
      'enabled': true
    }
  },
  BOXPLOT: {
    'axes': {
      'bottom': {
        'scaleType': 'labels',
        'mapsTo': 'group'
      },
      'left': {
        'mapsTo': 'value'
      }
    },
    'toolbar': {
      'enabled': true
    }
  }
};

export const studentsListHeader = [
  {
    key: 'id',
    header: 'ID'
  },
  {
    key: 'name',
    header: 'Name'
  },
  {
    key: 'age',
    header: 'Age'
  },
  {
    key: 'gender',
    header: 'Gender'
  },
  {
    key: 'grades',
    header: 'Grades'
  },
  {
    key: 'playTime',
    header: 'Play time (minutes)'
  },
  {
    key: 'currentLevel',
    header: 'Current level'
  },
  {
    key: 'actions',
    header: 'Actions'
  }
];

export const coursesListHeader = [
  {
    key: 'CourseId',
    header: 'ID'
  },
  {
    key: 'Name',
    header: 'Name'
  },
  {
    key: 'Code',
    header: 'Code'
  },
  {
    key: 'DateEc',
    header: 'Date'
  },
  {
    key: 'Email',
    header: 'Email_Teacher'
  },
  {
    key: 'FullName',
    header: 'Teacher_Name'
  }
];

export const teachersListHeader = [
  {
    key: 'TeacherId',
    header: 'ID'
  },
  {
    key: 'Phone',
    header: 'Phone'
  },
  {
    key: 'Institution',
    header: 'Institution'
  },
  {
    key: 'UserId',
    header: 'User_ID'
  },
  {
    key: 'User.Email',
    header: 'Email_Teacher'
  },
  {
    key: 'FullName',
    header: 'Full_Name'
  },
  {
    key: 'actions',
    header: 'Actions'
  }
];

export const missionsListHeader = [
  {
    key: 'id',
    header: 'ID'
  },
  {
    key: 'name',
    header: 'Name'
  },
  {
    key: 'description',
    header: 'Description'
  },
  {
    key: 'score',
    header: 'Score'
  },
  {
    key: 'actions',
    header: 'Actions'
  }
];

export const gameLevelsListHeader = [
  {
    key: 'gameLevelId',
    header: 'ID'
  },
  {
    key: 'name',
    header: 'Name'
  },
  {
    key: 'description',
    header: 'Description'
  },
  {
    key: 'gameId',
    header: 'Game_ID'
  },
  {
    key: 'actions',
    header: 'Actions'
  }
];