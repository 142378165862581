import React from 'react';
import {
  Button,
  ButtonSet, 
  Grid,
  Row,
  Column
} from 'carbon-components-react';
import './index.css';
import { Link, useRouteMatch } from 'react-router-dom';


import captura2 from '../../assets/img/register.png';
import captura6 from '../../assets/img/Captura6.png';

const StoryContent = () => {
  const { __ } = window;
  const { path } = useRouteMatch();

  return (
    <Grid className="bpp-login">
      <Row>
        <Column sm={0} md={0} lg={6} xlg={6}>
          <img className='bpp-login-img-per' src={captura2} />
        </Column>
        <Column sm={12} md={12} lg={6} xlg={6}>
          <h1 className='landing-title'>
            {__('Mi Bosque 3D')}
          </h1>
          <h2 className='landing-subtitle'>
            {__('"Bosque Protector La Prosperina"')}
          </h2>
          <h2 className='landing-subtitle'>
            {__('Sembrando el Futuro')}
          </h2>
          <br />
          <p className="content">
            {'Mi Bosque es un videojuego basado en el Bosque Protector Prosperina y el recorrido Sendero Mirador. En este los niños de 10 a 12 años aprenderán mientras juegan de las cualidades y riquezas existentes en el bosque Protector La Prosperina, así como las mejores prácticas para protegerlo y mantenerlo.'}  
          </p>
          <br />
          <br />
          <ButtonSet>
            <Link
              to={`${path}/register`}
            >
              <Button kind="primary">{__('Sign up free')}</Button>
            </Link>
            <Link
              to={`${path}/login`}
            >
              <Button kind="tertiary">{__('Log in')}</Button>
            </Link>
          </ButtonSet>
         
          <img className='bpp-login-img-cap' src={captura6} />
        </Column>
      </Row>
    </Grid>
  );
};
export default StoryContent;