
import { combineReducers } from 'redux';

import user from './user';
import teacher from './teacher';
import student from './student';
import admin from './admin';

export default combineReducers({
  user,
  teacher,
  student,
  admin
});

