import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { login as loginSchema } from '../../schema';
import { thunks as userThunks } from '../../redux/actions/thunks/user';
import { parseError } from '../../utils';
import Separator from '../../shared/separator';
import ReCAPTCHA from 'react-google-recaptcha';
import Error from '../../shared/error';
import {
  Grid,
  Row,
  Column,
  Form,
  Button,
  Loading,
  TextInput,
  Tooltip
} from 'carbon-components-react';
import LoginImg from '../../assets/img/Login.jpg';
import './index.scss';

const Login = (props) => {
  let history = useHistory();
  const {
    login, getUser
  } = props;
  const {
    formState: { errors },
    control, handleSubmit
  } = useForm({ resolver: yupResolver(loginSchema) });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const onSubmit = (data) => {
    setError();
    setLoading(true);
    return login(data)
      .then((res) => {
        if (res.rol==1) {
          var message_error = 'Usuario no autorizado';
          setLoading(false);
          setError(message_error);
          console.log('ERROR:', message_error);
        }
        else {
          getUser({ apiToken: res.token })
            .then(() => {
              setLoading(false);
              return history.push('/teacher/app/courses');
            })
            .catch((err) => {
              setLoading(false);
              setError(err);
              console.log('ERROR:', err);
            });
        }
      })
      .catch(err => {
        console.log('ERROR:', err);
        setError(err);
        setLoading(false);
      });
  };

  return (
    <Grid className="bpp-login">
      <Row>
        <Column sm={0} md={0} lg={6} xlg={6}>
          <img className='bpp-login-img' src={LoginImg} />
        </Column>
        <Column sm={12} md={12} lg={6} xlg={6}>
          <div className='bpp-login-title bpp-mt-32'>
            <h3 className='bpp-login-divisor'>
              {
                __('Sign In')
              }
            </h3>
          </div>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            {
              error &&
              <Error errorMessage={__(parseError(error))} />
            }
            <div className='bpp-mt-32'>
              <Tooltip direction='bottom' tabIndex={0} triggerText={__('Email')}>
                <p>
                  {
                    __('Email')
                  }
                </p>
              </Tooltip>
              <Controller
                name='email'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextInput
                    className='bpp-mt-8'
                    id='email'
                    labelText=''
                    type='text'
                    placeholder='ejemplo@email.com'
                    invalid={!!errors.email}
                    helperText={__('* Required')}
                    invalidText={__(errors.email?.message)}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='bpp-mt-32'>
              <Controller
                name='password'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextInput
                    id='password'
                    type='password'
                    labelText={__('Password')}
                    invalid={!!errors.password}
                    helperText={__('* Required')}
                    invalidText={__(errors.password?.message)}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='bpp-mt-16'>
              <Controller
                name='captchaToken'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <ReCAPTCHA
                    id='captchaToken'
                    sitekey="6LcwzF0eAAAAAGNa3gXuFiCl3_kzR07Yd81K02e5"
                    {...field}
                  /> 
                )}
              />
            </div>
            <Button type='submit' className='bpp-mt-32'>
              {__('Sign In')}
              {loading && (
                <Loading
                  className='bpp-ml-64'
                  description='Sending auth'
                  small
                  withOverlay={false}
                />
              )}
            </Button>
            <Separator className='bpp-login-sep' />
            <div className='bpp-flx-vc bpp-mt-16 '>
              <p className='bpp-register-label'>{__('Forgotten password?')}</p>
              <Link className="bpp-register-button bpp-ml-8 bpp-register-label" to='/teacher/forgot-password'>{__('Reset it')}</Link>
            </div>
            <div className='bpp-flx-vc bpp-mt-4 '>
              <p className='bpp-register-label'>{__('Don\'t have an account?')}</p>
              <Link className="bpp-register-button bpp-ml-8 bpp-register-label" to='/teacher/register'>{__('Create an account')}</Link>
            </div>
          </Form>
        </Column>
      </Row>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: userThunks.login,
      getUser: userThunks.get
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
