import { 
  getAdminCourses,
  getAdminCoursesL,
  registerCourse,
  getAdminTeachersL,
  registerTeacher,
  getAdminMissionsL,
  registerMissions,
  changePasswordT,
  changeMission,
  deleteCourseAd,
  deleteTeacherAd,
  getAdminGameLevelsL,
  registerGameLevels,
  changeGameLevel,
  deleteMissionAd
} from '../../../services/admin';
import { actions as adminActions } from '../admin';

export const thunks = {
  getAdminCourses(credentials) {
    return async (dispatch) => {
      dispatch(adminActions.getAdminCourses.start());
      try {
        const payload = await getAdminCourses(credentials);
        dispatch(adminActions.getAdminCourses.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.getAdminCourses.error(error));
        return;
      }
    };
  },
  getAdminCoursesL(props, credentials) {
    return async (dispatch) => {
      dispatch(adminActions.getAdminCoursesL.start());
      try {
        const payload = await getAdminCoursesL(props, credentials);
        dispatch(adminActions.getAdminCoursesL.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.getAdminCoursesL.error(error));
        return;
      }
    };
  },
  getAdminTeachersL(props, credentials) {
    return async (dispatch) => {
      dispatch(adminActions.getAdminTeachersL.start());
      try {
        const payload = await getAdminTeachersL(props, credentials);
        dispatch(adminActions.getAdminTeachersL.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.getAdminTeachersL.error(error));
        return;
      }
    };
  },
  getAdminMissionsL(props, credentials) {
    return async (dispatch) => {
      dispatch(adminActions.getAdminMissionsL.start());
      try {
        const payload = await getAdminMissionsL(props, credentials);
        dispatch(adminActions.getAdminMissionsL.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.getAdminMissionsL.error(error));
        return;
      }
    };
  },
  getAdminGameLevelsL(props, credentials) {
    return async (dispatch) => {
      dispatch(adminActions.getAdminGameLevelsL.start());
      try {
        const payload = await getAdminGameLevelsL(props, credentials);
        dispatch(adminActions.getAdminGameLevelsL.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.getAdminGameLevelsL.error(error));
        return;
      }
    };
  },
  createAdminCourses(data, credentials) {
    return async (dispatch) => {
      dispatch(adminActions.createAdminCourses.start());
      try {
        const payload = await registerCourse(data, credentials);
        dispatch(adminActions.createAdminCourses.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.createAdminCourses.error(error));
        throw error;
      }
    };
  },
  createAdminTeachers(data, credentials) {
    return async (dispatch) => {
      dispatch(adminActions.createAdminTeachers.start());
      try {
        const payload = await registerTeacher(data, credentials);
        dispatch(adminActions.createAdminTeachers.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.createAdminTeachers.error(error));
        throw error;
      }
    };
  },

  createAdminMissions(data, credentials) {
    return async (dispatch) => {
      dispatch(adminActions.createAdminMissions.start());
      try {
        const payload = await registerMissions(data, credentials);
        dispatch(adminActions.createAdminMissions.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.createAdminMissions.error(error));
        throw error;
      }
    };
  },

  createAdminGameLevels(data, credentials) {
    return async (dispatch) => {
      dispatch(adminActions.createAdminGameLevels.start());
      try {
        const payload = await registerGameLevels(data, credentials);
        dispatch(adminActions.createAdminGameLevels.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.createAdminGameLevels.error(error));
        throw error;
      }
    };
  },

  updatePasswordT(props,TID ,credentials) {
    return async (dispatch) => {
      dispatch(adminActions.updatePasswordT.start());
      try {
        const payload = await changePasswordT(props, TID , credentials);
        dispatch(adminActions.updatePasswordT.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.updatePasswordT.error(error));
        throw error;
      }
    };
  },
  updateMission(props,CID ,credentials) {
    return async (dispatch) => {
      dispatch(adminActions.updateMission.start());
      try {
        const payload = await changeMission(props, CID , credentials);
        dispatch(adminActions.updateMission.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.updateMission.error(error));
        throw error;
      }
    };
  },
  updateGameLevel(props,CID ,credentials) {
    return async (dispatch) => {
      dispatch(adminActions.updateGameLevel.start());
      try {
        const payload = await changeGameLevel(props, CID , credentials);
        dispatch(adminActions.updateGameLevel.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.updateGameLevel.error(error));
        throw error;
      }
    };
  },


  deleteAdminCourses(CID ,credentials) {
    return async (dispatch) => {
      dispatch(adminActions.deleteAdminCourses.start());
      try {
        const payload = await deleteCourseAd( CID , credentials);
        dispatch(adminActions.deleteAdminCourses.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.deleteAdminCourses.error(error));
        throw error;
      }
    };
  },
  deleteAdminTeachers(TID ,credentials) {
    return async (dispatch) => {
      dispatch(adminActions.deleteAdminTeachers.start());
      try {
        const payload = await deleteTeacherAd( TID , credentials);
        dispatch(adminActions.deleteAdminTeachers.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.deleteAdminTeachers.error(error));
        throw error;
      }
    };
  },
  deleteAdminMissions(MID ,credentials) {
    return async (dispatch) => {
      dispatch(adminActions.deleteAdminMissions.start());
      try {
        const payload = await deleteMissionAd( MID , credentials);
        dispatch(adminActions.deleteAdminMissions.complete(payload));
        return;
      } catch (error) {
        console.log('ADMIN ACTIONS', error);
        dispatch(adminActions.deleteAdminMissions.error(error));
        throw error;
      }
    };
  }


};