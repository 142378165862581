import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Switcher,
  SwitcherItem,
  SwitcherDivider
} from 'carbon-components-react/lib/components/UIShell';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { thunks as userThunks } from '../../redux/actions/thunks/user';

const TeacherSwitcher = (props) => {
  const { logout } = props;

  const history = useHistory();
  const location = useLocation();
  return (
    <Switcher aria-label="Switcher Container">
      <SwitcherItem
        aria-label="Courses"
        element={Link}
        to="/teacher/app/courses"
        isSelected={location.pathname === '/teacher/app/courses'}
      >
        Courses
      </SwitcherItem>
      <SwitcherDivider />
      <SwitcherItem
        className='bpp-pointer'
        aria-label="Sign out"
        onClick={() => {
          logout();
          history.push('/teacher');
        }}>
        {
          __('Sign out')
        }
      </SwitcherItem>
    </Switcher>
  );
};

const mapStateToProps = state => {
  return {
    breadCrumbs: state.user.breadCrumbs
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: userThunks.logout
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TeacherSwitcher);
