import { types } from '../actions/teacher';

const {
  GET_COURSES_START,
  GET_COURSES_ERROR,
  GET_COURSES_COMPLETE,

  CREATE_COURSE_START,
  CREATE_COURSE_ERROR,
  CREATE_COURSE_COMPLETE,

  GET_STUDENTS_START,
  GET_STUDENTS_ERROR,
  GET_STUDENTS_COMPLETE,

  GET_OVERVIEW_START,
  GET_OVERVIEW_ERROR,
  GET_OVERVIEW_COMPLETE,

  GET_ACHIEVEMENTS_START,
  GET_ACHIEVEMENTS_ERROR,
  GET_ACHIEVEMENTS_COMPLETE,

  GET_MISSIONS_T_START,
  GET_MISSIONS_T_ERROR,
  GET_MISSIONS_T_COMPLETE,

  GET_MISSIONS_G_START,
  GET_MISSIONS_G_ERROR,
  GET_MISSIONS_G_COMPLETE,

  UPDATE_COURSE_START,
  UPDATE_COURSE_ERROR,
  UPDATE_COURSE_COMPLETE,

  GET_COURSE_START,
  GET_COURSE_ERROR,
  GET_COURSE_COMPLETE
} = types;

const initialState = {
  courses: {
    total: 0,
    payload: [],
    error: null,
    loading: false
  },
  students: {
    payload: [],
    error: null,
    loading: false
  },
  overview: {
    payload: {},
    error: null,
    loading: false
  },
  achievements: {
    payload: [],
    error: null,
    loading: false
  },
  missionsByGenderGroup: {
    payload: [],
    error: null,
    loading: false
  },
  missionsByFinishTime: {
    payload: [],
    error: null,
    loading: false
  },
  newCourse: {
    payload: {},
    error: null,
    loading: false
  },
  currentCourse: {
    payload: {},
    error: null,
    loading: false
  }
};

const startCreateCourse = (state) => {
  return {
    ...state,
    newCourse: {
      ...state.newCourse,
      error: null,
      loading: true
    }
  };
};

const errorCreateCourse = (state, action) => {
  return {
    ...state,
    newCourse: {
      ...state.newCourse,
      error: action.payload,
      loading: false
    }
  };
};

const completeCreateCourse = (state, action) => {
  const { dataClass } = action.payload;
  return {
    ...state,
    courses: {
      ...state.courses,
      payload: [
        ...state.courses.payload
        //,dataClass
      ],
      loading: false
    },
    newCourse: {
      ...state.newCourse,
      payload: dataClass,
      loading: false,
      error: null
    }
  };
};
  
const generalOnStart = (state, property) => {
  return {
    ...state,
    [property]: {
      ...state[property],
      error: null,
      loading: true
    }
  };
};

const generalOnError = (state, action, property) => {
  return {
    ...state,
    [property]: {
      ...state[property],
      error: action.payload,
      loading: false
    }
  };
};

const generalOnComplete = (state, action, property) => {
  const payload = action.payload;
  return {
    ...state,
    [property]: {
      ...state[property],
      payload: payload.payload,
      loading: false,
      error: null
    }
  };
};

const startGetCourses = (state) => {
  return {
    ...state,
    courses: {
      ...state.courses,
      error: null,
      loading: true
    }
  };
};

const errorGetCourses = (state, action) => {
  return {
    ...state,
    courses: {
      ...state.courses,
      error: action.payload,
      loading: false
    }
  };
};

const completeGetCourses = (state, action) => {
  const { payload } = action.payload;
  return {
    ...state,
    courses: {
      ...state.courses,
      error: null,
      loading: false,
      payload: [
        ...payload.courses
      ],
      total: payload.total
    }
  };
};

const OnGetCourseStart = (state) => {
  return {
    ...state,
    currentCourse: {
      ...state.currentCourse,
      error: null,
      loading: true
    }
  };
};

const OnGetCourseError = (state, action) => {
  return {
    ...state,
    currentCourse: {
      ...state.currentCourse,
      error: action.payload,
      loading: false
    }
  };
};

const OnGetCourseComplete = (state, action) => {
  return {
    ...state,
    currentCourse: {
      ...state.currentCourse,
      payload: {
        ...state.currentCourse.payload,
        ...action.payload.payload
      },
      loading: false,
      error: null
    }
  };
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
  case GET_COURSES_START:
    return startGetCourses(state, action);
  case GET_COURSES_ERROR:
    return errorGetCourses(state, action);
  case GET_COURSES_COMPLETE:
    return completeGetCourses(state, action);

  case CREATE_COURSE_START:
    return startCreateCourse(state, action);
  case CREATE_COURSE_ERROR:
    return errorCreateCourse(state, action);
  case CREATE_COURSE_COMPLETE:
    return completeCreateCourse(state, action);

  case GET_STUDENTS_START:
    return generalOnStart(state, 'students');
  case GET_STUDENTS_ERROR:
    return generalOnError(state, action, 'students');
  case GET_STUDENTS_COMPLETE:
    return generalOnComplete(state, action, 'students');

  case GET_OVERVIEW_START:
    return generalOnStart(state, 'overview');
  case GET_OVERVIEW_ERROR:
    return generalOnError(state, action, 'overview');
  case GET_OVERVIEW_COMPLETE:
    return generalOnComplete(state, action, 'overview');

  case GET_ACHIEVEMENTS_START:
    return generalOnStart(state, 'achievements');
  case GET_ACHIEVEMENTS_ERROR:
    return generalOnError(state, action, 'achievements');
  case GET_ACHIEVEMENTS_COMPLETE:
    return generalOnComplete(state, action, 'achievements');

  case GET_MISSIONS_G_START:
    return generalOnStart(state, 'missionsByGenderGroup');
  case GET_MISSIONS_G_ERROR:
    return generalOnError(state, action, 'missionsByGenderGroup');
  case GET_MISSIONS_G_COMPLETE:
    return generalOnComplete(state, action, 'missionsByGenderGroup');

  case GET_MISSIONS_T_START:
    return generalOnStart(state, 'missionsByFinishTime');
  case GET_MISSIONS_T_ERROR:
    return generalOnError(state, action, 'missionsByFinishTime');
  case GET_MISSIONS_T_COMPLETE:
    return generalOnComplete(state, action, 'missionsByFinishTime');
  
  case GET_COURSE_START:
    return generalOnStart(state, 'currentCourse');
  case GET_COURSE_ERROR:
    return generalOnError(state, action, 'currentCourse');
  case GET_COURSE_COMPLETE:
    return generalOnComplete(state, action, 'currentCourse');
  
  case UPDATE_COURSE_START:
    return OnGetCourseStart(state, action);
  case UPDATE_COURSE_ERROR:
    return OnGetCourseError(state, action);
  case UPDATE_COURSE_COMPLETE:
    return OnGetCourseComplete(state, action);

  default:
    return state;
  }
}
