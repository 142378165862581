import React , { useState }  from 'react';
import Select from 'react-select/creatable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Error from '../../../shared/error';

import {
  Form,
  Button,
  SecondaryButton,
  Loading,
  TextInput,
  ListItem as Item,
  UnorderedList as UList,
  RadioButtonGroup,
  RadioButton
} from 'carbon-components-react';

import { parseError } from '../../../utils';
import { helperText01, label01 } from '@carbon/type';
import { registerAd as registerSchema } from '../../../schema';
import { thunks as adminThunks } from '../../../redux/actions/thunks/admin';


const options = [
  {
    id: 1,
    name: 'Escuela Superior Politécnica del Litoral'
  },
  {
    id: 2,
    name: 'Massachusetts Institute of Technology'
  },
  {
    id: 3,
    name: 'Universidad San Francisco de Quito'
  }
];

const CourseTeacherAdd = (props) => {
  
  const {
    user,  createTeacher
  } = props;
  const {
    token: apiToken
  } = user || {};
 

  const {
    formState: { errors },
    control , handleSubmit , unregister
  } = useForm({ resolver: yupResolver(registerSchema) });
  
  
  const [isEnabled, setIsEnabled] = useState(true);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const laFuncion = () => {
    var lzcdv = document.getElementById('aquidebo');
    lzcdv.setAttribute('style', 'display: none');
    var bzcv = document.getElementById('subircurso');
    bzcv.disabled=false;
  };
  
  const onSubmit = (data) => {
    setError();
    setLoading(true);
    return createTeacher({
      ...data
    }, {
      apiToken 
    })
      .then(() => {
        setLoading(false);
        laFuncion();
      })
      .catch(err => {
        console.log('ERROR:', err);
        setError(err);
        setLoading(false);
        
        
      });
    
  };
  
  const capitalize = (sentence) => {
    return sentence.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  };
  return (
    
  
    <Form className="progreso" noValidate onSubmit={handleSubmit(onSubmit)}>
      {
        error &&
        <Error errorMessage={__(parseError(error))} />
      }
      <div >
        <Controller
          name='email'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextInput
              id='email'
              type='text'
              labelText={__('Email')}
              placeholder='example@email.com'
              helperText={__('* Required')}
              invalid={!!errors.email}
              invalidText={__(errors.email?.message)}
              {...field}
            />
          )} />
      </div>
      <div >
        <Controller
          name='firstname'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <div className='bpp-w-100'>
              <TextInput
                id='firstname'
                type='text'
                labelText={__('Firstname')}
                placeholder=''
                invalid={!!errors.firstname}
                helperText={__('* Required')}
                invalidText={__(errors.firstname?.message)}
                {...field}
              />
            </div>
          )} />
      </div>
      <div >
        <Controller
          name='lastname'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <div className='bpp-w-100'>
              <TextInput
                id='lastname'
                type='text'
                labelText={__('Lastname')}
                placeholder=''
                invalid={!!errors.lastname}
                helperText={__('* Required')}
                invalidText={__(errors.lastname?.message)}
                {...field}
              />
            </div>
          )} />
      </div>
      <div >
        <Controller
          name='phone'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextInput
              id='phone'
              type='tel'
              labelText={__('Phone')}
              placeholder=''
              invalid={!!errors.phone}
              helperText={__('* Required')}
              invalidText={__(errors.phone?.message)}
              {...field}
            />
          )} />
      </div>
      <div >
        <div className='bpp-label bpp-mb-8' style={label01}>
          {
            __('Do you want to enter a password?')
          }
        </div>
        <Controller
          name='decide'
          control={control}
          defaultValue='Mostrar'
          render={({ field }) => (
      
            <RadioButtonGroup
              defaultSelected="default-selected"
              legend="Group Legend"
              name="decide"
              valueSelected="Mostrar"
              
              {...field}
            >
              <RadioButton
                id="radio-1"
                labelText={__('No')}
                value="Nomostrar"
                onClick={() => { 
                  unregister('password');
                  unregister('confirmPassword');
                  setIsEnabled(false);
                }}
                
              />
              <RadioButton
                id="radio-2"
                labelText={__('Yes')}
                value="Mostrar"
                onClick={() => {setIsEnabled(true);}}
              />
            </RadioButtonGroup>
          )} />
      </div>
     
      {isEnabled ? <Controller
        name='password'
        control={control}
        defaultValue=''
        render={({ field }) => (
          <TextInput
            id='password'
            type='password'
            labelText={__('Password')}
            placeholder=''
            invalid={!!errors.password}
            helperText={__('* Required')}
            invalidText={__(errors.password?.message)}
            {...field}
          />
        )} /> : <></>}
      {isEnabled ? <UList className='bpp-pl-16'>
        <Item style={helperText01}>
          {
            __('8 character minimum')
          }
        </Item>
        <Item style={helperText01}>
          {
            __('One lowercase character')
          }
        </Item>
      </UList> : <></>}
      {isEnabled ? <UList className='bpp-pl-32'>
        <Item style={helperText01}>
          {
            __('One upper character')
          }
        </Item>
        <Item style={helperText01}>
          {
            __('One number minimum')
          }
        </Item>
      </UList> : <></>}
      {isEnabled ? <Controller
        name='confirmPassword'
        control={control}
        defaultValue=''
        render={({ field }) => (
          <TextInput
            id='confirmPassword'
            type='password'
            labelText={__('Confirm Password')}
            placeholder=''
            invalid={!!errors.confirmPassword}
            helperText={__('* Required')}
            invalidText={__(errors.confirmPassword?.message)}
            {...field}
          />
        )} /> : <></>}

        
      <div >
        <div className='bpp-label bpp-mb-8' style={label01}>
          {
            __('Institution')
          }
        </div>
        <Controller
          name='institution'
          control={control}
          defaultValue=''
          render={
            ({ field }) => {
              const {
                onChange, value, name, ref
              } = field;
              return <Select
                name={name}
                inputRef={ref}
                isClearable
                options={options}
                classNamePrefix={
                  errors.institution ?
                    'bpp-select-error' : 'bpp-select'
                }
                getNewOptionData={(inputValue, optionLabel) => ({
                  id: optionLabel,
                  name: capitalize(inputValue),
                  __isNew__: true
                })}
                placeholder={__('Choose an institution')}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                
                onChange={val => onChange(val?.name)}
                value={options.find(c => c?.name === value)} />;
            }
          }
        />
        <span style={{ ...helperText01, color: '#525252' }}>
          {__('* Required')}
        </span>
        {
          !!errors.institution &&
                <div className='form-error-text'>
                  {__(errors.institution?.message)}
                </div>
        }
      </div>
      <Button
        type='submit'
        className='bpp-mt-16'>
        {
          __('Create account')
        }
              
        {
          
          loading && (
            <Loading
              className='bpp-ml-64'
              description="Sending email" small 
              withOverlay={false} 
            />
                
          )}
      </Button>
      <SecondaryButton
        onClick={laFuncion}
        type='button'
        className='bpp-mt-16'>
        {
          __('Cancel')
        }
          
      </SecondaryButton>
      
    </Form>
   
      
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  createTeacher: adminThunks.createAdminTeachers
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CourseTeacherAdd);