/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React , {  useState } from 'react';
import 'carbon-components/css/carbon-components.min.css';
const URL_SERVER = process.env.REACT_APP_URL_SERVER;

import {
  Button,
  TextInput,
  TextArea,
  Form,
  Loading
} from 'carbon-components-react';

import { useForm, Controller } from 'react-hook-form';
import { createCourseAd as CreateCourseSchema } from '../../../schema';
import { thunks as adminThunks } from '../../../redux/actions/thunks/admin';
import { yupResolver } from '@hookform/resolvers/yup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AsyncSelect from 'react-select/async';
import { helperText01, label01 } from '@carbon/type';
import Error from '../../../shared/error';
import { parseError } from '../../../utils';


const CourseAdd = (props) => {
  const {
    user,  createCourse
  } = props;
  const {
    token: apiToken
  } = user || {};
  
  
  const [opciones, setOpciones] = useState([]);
  
    
  const loadOptions = async (inputValue4) => {
    const url = new URL(URL_SERVER);
    url.pathname = '/api/v1/admin/teacher/findEmail';
    const res = await fetch(url+`?email=${inputValue4}`, {
      method: 'GET',
      headers: {
        'Authorization': apiToken,
        'Content-Type': 'application/json'
      }
    });
    const { payload, status } = await res.json();
    if (status == 404) {
      throw new Error('Error!!!');
    }
    
    var consi = payload.map(suggestion => 
      ({
        label: suggestion['User.Email'],
        value: suggestion.id
      }));

    setOpciones(consi);  
    return consi;
  };
  
  
  const {
    formState: { errors },
    control , handleSubmit
  } = useForm({ resolver: yupResolver(CreateCourseSchema) });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
    
  const onSubmit = (data) => {
    setError();
    setLoading(true);
    return createCourse({
      ...data
    }, {
      apiToken 
    })
      .then(() => {
        setLoading(false);
        window.location.reload(); 
      })
      .catch(err => {
        console.log('ERROR:', err);
        setError(err);
        setLoading(false);
      });
    
        
  };
  
  
  return (
    
  
    <Form className="miform" noValidate onSubmit={handleSubmit(onSubmit)} >
      {
        error &&
        <Error errorMessage={__(parseError(error))} />
      }
      <div >
        <Controller
          name='name'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextInput
              id='name'
              type='text'
              labelText={__('Course name')}
              placeholder=''
              invalid={!!errors.name}
              invalidText={__(errors.name?.message)}
              helperText={__('* Required')}
              {...field}
            />
          )}/>
      </div>
  
      <div>
        <a onClick={() => {
          var lz = document.getElementById('aquidebo');
          lz.setAttribute('style', 'display: block');
          var bz = document.getElementById('subircurso');
          bz.disabled=true;
        }
        }>{__('Create new teacher')}</a>
      </div>
      <div>
        <span style={{ ...helperText01, color: '#525252' }}>
          {__('Email of teacher')}
        </span>
        <Controller
          name='teacherId'
          control={control}
          defaultValue=''
          render={
            ({ field }) => {
              const {
                onChange, value, name, ref
              } = field;

              return <AsyncSelect
              
                classNamePrefix={
                  errors.teacherId ?
                    'bpp-select-error' : 'bpp-select'
                }
                loadOptions={loadOptions}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
                
                onChange={val => onChange(val?.value)}
                value={opciones.find(c => c?.value === value)}
              
                
              />;
            }
          }
        />
        <span style={{ ...helperText01, color: '#525252' }}>
          {__('* Required')}
        </span>
        {
          !!errors.teacherId &&
                <div className='form-error-text'>
                  {__(errors.teacherId?.message)}
                </div>
        }
     
         
      </div>
      <div >
        <Controller
          name='description'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextArea
              id='description'
              type='text'
              helperText={__('* Required')}
              labelText={__('Description')}
              placeholder=''
              invalid={!!errors.description}
              invalidText={__(errors.description?.message)}
              {...field}
            />
          )}/>
      </div>
      <Button
        id="subircurso"
        type='submit'
      >
        {__('Create course')}
        {
              
          loading && (
            <Loading
              className='bpp-ml-64'
              description='Creating course' 
              small 
              withOverlay={false} 
            />
              
          )}
      </Button>
    </Form>
   
      
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  createCourse: adminThunks.createAdminCourses
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CourseAdd);