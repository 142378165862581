import React , { useState }  from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import {
  Form,
  Button,
  Loading,
  TextInput
} from 'carbon-components-react';

import Error from '../../../shared/error';
import { parseError } from '../../../utils';
import { createGameLevelAd as registerSchema } from '../../../schema';
import { thunks as adminThunks } from '../../../redux/actions/thunks/admin';


const GameLevelAdd = (props) => {
  
  const {
    user, createGameLevel
  } = props;
  const {
    token: apiToken
  } = user || {};
 

  const {
    formState: { errors },
    control , handleSubmit 
  } = useForm({ resolver: yupResolver(registerSchema) });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  
  const onSubmit = (data) => {
    data['isActive']='1';
    setError();
    setLoading(true);
    return createGameLevel({
      ...data
    }, {
      apiToken 
    })
      .then(() => {
        setLoading(false);
        window.location.reload(); 
      })
      .catch(err => {
        console.log('ERROR:', err);
        setError(err);
        setLoading(false);
      });
    
    
  };
  

  return (
    
  
    <Form className="progreso" noValidate onSubmit={handleSubmit(onSubmit)}>
      {
        error &&
        <Error errorMessage={__(parseError(error))} />
      }
      <div >
        <Controller
          name='name'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <div className='bpp-w-100'>
              <TextInput
                id='name'
                type='text'
                labelText={__('Name')}
                placeholder=''
                invalid={!!errors.name}
                helperText={__('* Required')}
                invalidText={__(errors.name?.message)}
                {...field}
              />
            </div>
          )} />
      </div>
      <div >
        <Controller
          name='description'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <div className='bpp-w-100'>
              <TextInput
                id='description'
                type='text'
                labelText={__('Description')}
                placeholder=''
                invalid={!!errors.description}
                helperText={__('* Required')}
                invalidText={__(errors.description?.message)}
                {...field}
              />
            </div>
          )} />
      </div>
      <div >
        <Controller
          name='gameId'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <div className='bpp-w-100'>
              <TextInput
                id='gameId'
                type='text'
                labelText={__('Game Id')}
                placeholder=''
                invalid={!!errors.gameId}
                helperText={__('* Required')}
                invalidText={__(errors.gameId?.message)}
                {...field}
              />
            </div>
          )} />
      </div>
      
      
      <Button
        type='submit'
        className='bpp-mt-16'>
        {
          __('Create game level')
        }
              
        {
          
          loading && (
            <Loading
              className='bpp-ml-64'
              description="Sending email" small withOverlay={false} />
                
          )}
      </Button>
      
    </Form>
   
      
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  createGameLevel: adminThunks.createAdminGameLevels
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(GameLevelAdd);