import { 
  getResume,
  getOverview,
  getAchievements,
  getMissions,
  getQuizes
} from '../../../services/student';
import { actions as studentActions } from '../student';


export const thunks = {
  getResume(props, credentials) {
    return async (dispatch) => {
      dispatch(studentActions.getResume.start());
      try {
        const payload = await getResume(props, credentials);
        dispatch(studentActions.getResume.complete(payload));
        return;
      } catch (error) {
        console.log('STUDENT ACTIONS', error);
        dispatch(studentActions.getResume.error(error));
        return;
      }
    };
  },
  getOverview(props, credentials) {
    return async (dispatch) => {
      dispatch(studentActions.getOverview.start());
      try {
        const payload = await getOverview(props, credentials);
        dispatch(studentActions.getOverview.complete(payload));
        return;
      } catch (error) {
        console.log('STUDENT ACTIONS', error);
        dispatch(studentActions.getOverview.error(error));
        return;
      }
    };
  },
  getAchievements(props, credentials) {
    return async (dispatch) => {
      dispatch(studentActions.getAchievements.start());
      try {
        const payload = await getAchievements(props, credentials);
        dispatch(studentActions.getAchievements.complete(payload));
        return;
      } catch (error) {
        console.log('STUDENT ACTIONS', error);
        dispatch(studentActions.getAchievements.error(error));
        return;
      }
    };
  },
  getMissions(props, credentials) {
    return async (dispatch) => {
      dispatch(studentActions.getMissions.start());
      try {
        const payload = await getMissions(props, credentials);
        dispatch(studentActions.getMissions.complete(payload));
        return;
      } catch (error) {
        console.log('STUDENT ACTIONS', error);
        dispatch(studentActions.getMissions.error(error));
        return;
      }
    };
  },
  getQuizes(props, credentials) {
    return async (dispatch) => {
      dispatch(studentActions.getQuizes.start());
      try {
        const payload = await getQuizes(props, credentials);
        dispatch(studentActions.getQuizes.complete(payload));
        return;
      } catch (error) {
        console.log('STUDENT ACTIONS', error);
        dispatch(studentActions.getQuizes.error(error));
        return;
      }
    };
  }
};