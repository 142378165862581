/* eslint-disable no-unused-vars */
import React , { useState }  from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import {
  Form,
  Button,
  Loading,
  TextInput,
  ListItem as Item,
  UnorderedList as UList
} from 'carbon-components-react';

import Error from '../../../shared/error';
import { parseError } from '../../../utils';
import { helperText01, label01 } from '@carbon/type';
import { changePassAd as registerSchema } from '../../../schema';
import { thunks as adminThunks } from '../../../redux/actions/thunks/admin';


const PasswordChange = (props) => {
  
  const {
    user, changePasswordT,idDelTeacher
  } = props;
  const {
    token: apiToken
  } = user || {};
 

  const {
    formState: { errors },
    control , handleSubmit 
  } = useForm({ resolver: yupResolver(registerSchema) });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  
  
  const onSubmit = async (data) => {

    setError();
    setLoading(true);
    return changePasswordT({
      ...data
    }, idDelTeacher ,{
      apiToken 
    })
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR:', err);
        setError(err);
        setLoading(false);
      });
    
  };
  
  return (
    
  
    <Form className="progreso" noValidate onSubmit={handleSubmit(onSubmit)}>
      {
        error &&
        <Error errorMessage={__(parseError(error))} />
      }
      
      <div>
        <Controller
          name='password'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextInput
              id='password'
              type='password'
              labelText={__('New password')}
              placeholder=''
              invalid={!!errors.password}
              helperText={__('* Required')}
              invalidText={__(errors.password?.message)}
              {...field}
            />
          )} />
      </div> 
      <div>
        <UList className='bpp-pl-16'>
          <Item style={helperText01}>
            {
              __('8 character minimum')
            }
          </Item>
          <Item style={helperText01}>
            {
              __('One lowercase character')
            }
          </Item>
        </UList> 
        <UList className='bpp-pl-32'>
          <Item style={helperText01}>
            {
              __('One upper character')
            }
          </Item>
          <Item style={helperText01}>
            {
              __('One number minimum')
            }
          </Item>
        </UList>
      </div> 
      <div>
        <Controller
          name='confirmPassword'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextInput
              id='confirmPassword'
              type='password'
              labelText={__('Confirm new password')}
              placeholder=''
              invalid={!!errors.confirmPassword}
              helperText={__('* Required')}
              invalidText={__(errors.confirmPassword?.message)}
              {...field}
            />
          )} /> 
      </div>

        
      <Button
        type='submit'
        className='bpp-mt-16'>
        {
          __('Change password')
        }
              
        {
          
          loading && (
            <Loading
              className='bpp-ml-64'
              description="Sending email" small withOverlay={false} />
                
          )}
      </Button>
      
    </Form>
   
      
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  changePasswordT: adminThunks.updatePasswordT
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);