import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect
} from 'react-router-dom';
import AdminLogin from '../../components/admin/login/AdminLogin';
import HomeHeader from '../../shared/homeHeader';
import NoMatch from '../../shared/noMatch';
import AppAdminRoutes from './AppAdminRoutes';


const AdminRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/login`} />
      </Route>
      <Route exact path={`${path}/login`} component={AdminLogin} />
      <Route path={`${path}/app`} component={AppAdminRoutes} />
      <Route path='*' component={() => <HomeHeader where="admin" name="NoMatch"><NoMatch /></HomeHeader>} />
    </Switch>
  );
};
export default AdminRouter;