import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Badge, DoNot_02 as DoNot02 } from '@carbon/pictograms-react';
import {
  Loading
} from 'carbon-components-react';
import { thunks as studentThunks } from '../../../redux/actions/thunks/student';
import Error from './../../../shared/error';
import { parseError } from './../../../utils';
import Separator from './../../../shared/separator';
import './index.css';

const Achievements = (props) => {
  const {
    achievements, getAchievements,
    courseId, studentId, user
  } = props;
  const {
    payload: achPayload,
    loading: achLoading,
    error: achError
  } = achievements || {};
  const {
    token: apiToken
  } = user || {};

  useEffect(() => {
    getAchievements(
      {
        courseId,
        studentId
      },
      {
        apiToken
      });
  }, []);

  return (
    <div className="bpp-student-achivements">
      {
        !achLoading &&
        achError &&
        <Error errorMessage={__(parseError(achError))}/>
      }
      {
        achLoading &&
        !achError &&
        <div className="bpp-flx-c">
          <Loading
            description="Achievements are loading"
            withOverlay={false}
          />
        </div>
      }
      {
        !achLoading &&
        !achError &&
        achPayload &&
        achPayload.length > 0 &&
        <ul 
          className="bpp-flx-c bpp-flx-clmn">
          {
            achPayload?.map((a,i) => (
              <React.Fragment key={a.id}>
                <li 
                  className="bpp-mt-32 bpp-achvm-detail bpp-flx-c  bpp-flx-clmn">
                  {
                    a.srcPath &&
                        <img src={a.srcPath} alt={'Badge'}/>
                  }
                  <Badge />
                  <p>{a.name}</p>
                  <p>{a.description}</p>
                  {
                    a.dateTime && 
                    <p>{new Date(a.dateTime).toLocaleString()}</p>
                  }
                </li>
                {
                  i != achPayload.length - 1 &&
                  <Separator/>
                }
              </React.Fragment>
            ))
          }
        </ul>
      }
      {
        (!achLoading &&( !achPayload || !achPayload.length)) &&
        <div className="bpp-flx-c bpp-flx-clmn">
          <DoNot02/>
          <p>
            {
              __('No achievements were found!')
            }
          </p>
        </div>
      }
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    achievements: state.student.achievements,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAchievements: studentThunks.getAchievements
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Achievements);