import React from 'react';
import 'carbon-components/css/carbon-components.min.css';
import ModalGLAdd from './ModalGLAdd';
import ModalGLEdit from './ModalGLEdit';
import {
  DataTable,
  TableContainer,
  TableToolbar,
  TableBatchActions,
  TableBatchAction,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectAll,
  TableSelectRow,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell
} from 'carbon-components-react';

import {
  Delete16 as Delete
  //Save16 as Save,
  //Download16 as Download
} from '@carbon/icons-react';


import { gameLevelsListHeader } from '../../../constants';
const GameLevelsTable = (props) => {
  const { gameLevels } = props;

  const batchActionClick = (e, action, data) => {
    e.preventDefault();
    console.log(action, data);
  };
   

  return (
    <>
      <DataTable 
        rows={gameLevels.map(s => ({ ...s, id: String(s.gameLevelId) }))}
        headers={gameLevelsListHeader.map(s =>
          ({ ...s, header: __(s.header) }))}
        isSortable>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          getBatchActionProps,
          onInputChange,
          selectedRows,
          getTableProps,
          getToolbarProps,
          getTableContainerProps
        }) => (
          <TableContainer
            {...getTableContainerProps()}
            title={__('Game levels')}
            description={__('List of game levels')}
          >
            <TableToolbar
              {...getToolbarProps()}
            >
              <TableBatchActions {...getBatchActionProps()}>
                <TableBatchAction
                  tabIndex={
                    getBatchActionProps().shouldShowBatchActions ? 0 : -1
                  }
                  renderIcon={Delete}
                  onClick={(e) => batchActionClick(e, 'delete', selectedRows)}>
                  {__('Delete')}
                </TableBatchAction>
              </TableBatchActions>
              <TableToolbarContent>
                <TableToolbarSearch
                  tabIndex={
                    getBatchActionProps().shouldShowBatchActions ? -1 : 0
                  }
                  onChange={onInputChange}
                />
                <ModalGLAdd />
              </TableToolbarContent>
            </TableToolbar>
            <Table
              {...getTableProps()}
            >
              <TableHead>
                <TableRow>
                  <TableSelectAll {...getSelectionProps()} />
                  {headers.map((header, i) => (
                    <TableHeader key={i} {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={i} {...getRowProps({ row })}>
                    <TableSelectRow {...getSelectionProps({ row })} />
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        
                        {
                          cell.id?.endsWith('actions') &&
                           
                              
                           <ModalGLEdit elid={row.id} />
                                             
                            
                        }
                        {
                          cell.value
                        }                        
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
};

export default GameLevelsTable;