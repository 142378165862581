import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch
} from 'react-router-dom';
import HeaderWrapper from '../../shared/homeHeader';
import Login from '../../components/login';
import Register from '../../components/register';
import ForgotPassword from '../../components/teacherComponents/ForgotPassword';
import ResetPassword from '../../components/teacherComponents/ResetPassword';
import NoMatch from '../../shared/noMatch';
import Landing from '../../components/landing';
import AppTeacherRoutes from './AppTeacherRoutes';


const TeacherRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} render={
        () => (<HeaderWrapper where="teacher" name="Landing"><Landing /></HeaderWrapper>)} />
      <Route path={`${path}/register`} render={
        () => (<HeaderWrapper where="teacher" name="Register"><Register /></HeaderWrapper>)} />
      <Route path={`${path}/login`} render={
        () => (<HeaderWrapper where="teacher" name="Login"><Login /></HeaderWrapper>)} />
      <Route path={`${path}/forgot-password`} render={
        () => (<HeaderWrapper where="teacher" name="Login"><ForgotPassword /></HeaderWrapper>)} />
      <Route path={`${path}/password-reset/:userId/:resetString`} render={
        () => (<HeaderWrapper where="teacher" name="Login"><ResetPassword /></HeaderWrapper>)} />

      <Route path={`${path}/app`} component={AppTeacherRoutes} />

      <Route path="*" render={
        () => (<HeaderWrapper where="teacher" name="NoMatch"><NoMatch /></HeaderWrapper>)} />
    </Switch>
  );
};
export default TeacherRouter;