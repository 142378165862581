import { createAction } from 'redux-actions';

export const types = {
  GET_RESUME_START: 'STUDENT/GET_RESUME_START',
  GET_RESUME_ERROR: 'STUDENT/GET_RESUME_ERROR',
  GET_RESUME_COMPLETE: 'STUDENT/GET_RESUME_COMPLETE',

  GET_OVERVIEW_START: 'STUDENT/GET_OVERVIEW_START',
  GET_OVERVIEW_ERROR: 'STUDENT/GET_OVERVIEW_ERROR',
  GET_OVERVIEW_COMPLETE: 'STUDENT/GET_OVERVIEW_COMPLETE',

  GET_ACHIEVEMENTS_START: 'STUDENT/GET_ACHIEVEMENTS_START',
  GET_ACHIEVEMENTS_ERROR: 'STUDENT/GET_ACHIEVEMENTS_ERROR',
  GET_ACHIEVEMENTS_COMPLETE: 'STUDENT/GET_ACHIEVEMENTS_COMPLETE',

  GET_QUIZES_START: 'STUDENT/GET_QUIZES_START',
  GET_QUIZES_ERROR: 'STUDENT/GET_QUIZES_ERROR',
  GET_QUIZES_COMPLETE: 'STUDENT/GET_QUIZES_COMPLETE',
  
  GET_MISSIONS_START: 'STUDENT/GET_MISSIONS_START',
  GET_MISSIONS_ERROR: 'STUDENT/GET_MISSIONS_ERROR',
  GET_MISSIONS_COMPLETE: 'STUDENT/GET_MISSIONS_COMPLETE'
};

export const actions = {
  getResume: {
    start: createAction(types.GET_RESUME_START),
    error: createAction(types.GET_RESUME_ERROR, error => ({ error })),
    complete: createAction(types.GET_RESUME_COMPLETE, payload => ({ payload }))
  },
  getOverview: {
    start: createAction(types.GET_OVERVIEW_START),
    error: createAction(types.GET_OVERVIEW_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_OVERVIEW_COMPLETE, payload => ({ payload })
    )
  },
  getAchievements: {
    start: createAction(types.GET_ACHIEVEMENTS_START),
    error: createAction(types.GET_ACHIEVEMENTS_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_ACHIEVEMENTS_COMPLETE, payload => ({ payload })
    )
  },
  getMissions: {
    start: createAction(types.GET_MISSIONS_START),
    error: createAction(types.GET_MISSIONS_ERROR, error => ({ error })),
    complete: createAction(
      types.GET_MISSIONS_COMPLETE, payload => ({ payload })
    )
  },
  getQuizes: {
    start: createAction(types.GET_QUIZES_START),
    error: createAction(types.GET_QUIZES_ERROR, error => ({ error })),
    complete: createAction(types.GET_QUIZES_COMPLETE, payload => ({ payload }))
  }
};