import { types } from '../actions/student';
const {
  GET_RESUME_START,
  GET_RESUME_ERROR,
  GET_RESUME_COMPLETE,
  GET_OVERVIEW_START,
  GET_OVERVIEW_ERROR,
  GET_OVERVIEW_COMPLETE,
  GET_ACHIEVEMENTS_START,
  GET_ACHIEVEMENTS_ERROR,
  GET_ACHIEVEMENTS_COMPLETE,
  GET_QUIZES_START,
  GET_QUIZES_ERROR,
  GET_QUIZES_COMPLETE,
  GET_MISSIONS_START,
  GET_MISSIONS_ERROR,
  GET_MISSIONS_COMPLETE
} = types;

const initialState = {
  resume: {
    payload: {},
    error: null,
    loading: false
  },
  overview: {
    payload: [],
    error: null,
    loading: false
  },
  achievements: {
    payload: [],
    error: null,
    loading: false
  },
  quizes: {
    payload: [],
    error: null,
    loading: false
  },
  missions: {
    payload: [],
    error: null,
    loading: false
  }
};

const generalOnStart = (state, property) => {
  return {
    ...state,
    [property]: {
      ...state[property],
      error: null,
      loading: true
    }
  };
};

const generalOnError = (state, action, property) => {
  return {
    ...state,
    [property]: {
      ...state[property],
      error: action.payload,
      loading: false
    }
  };
};

const generalOnComplete = (state, action, property) => {
  const payload = action.payload;
  return {
    ...state,
    [property]: {
      ...state[property],
      payload: payload.payload,
      loading: false,
      error: null
    }
  };
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
  case GET_RESUME_START:
    return generalOnStart(state, 'resume');
  case GET_RESUME_ERROR:
    return generalOnError(state, action, 'resume');
  case GET_RESUME_COMPLETE:
    return generalOnComplete(state, action, 'resume');
  case GET_OVERVIEW_START:
    return generalOnStart(state, 'overview');
  case GET_OVERVIEW_ERROR:
    return generalOnError(state, action, 'overview');
  case GET_OVERVIEW_COMPLETE:
    return generalOnComplete(state, action, 'overview');
  case GET_ACHIEVEMENTS_START:
    return generalOnStart(state, 'achievements');
  case GET_ACHIEVEMENTS_ERROR:
    return generalOnError(state, action, 'achievements');
  case GET_ACHIEVEMENTS_COMPLETE:
    return generalOnComplete(state, action, 'achievements');
  case GET_QUIZES_START:
    return generalOnStart(state, 'quizes');
  case GET_QUIZES_ERROR:
    return generalOnError(state, action, 'quizes');
  case GET_QUIZES_COMPLETE:
    return generalOnComplete(state, action, 'quizes');
  case GET_MISSIONS_START:
    return generalOnStart(state, 'missions');
  case GET_MISSIONS_ERROR:
    return generalOnError(state, action, 'missions');
  case GET_MISSIONS_COMPLETE:
    return generalOnComplete(state, action, 'missions');
  default:
    return state;
  }
}
